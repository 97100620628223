import {
  COMMON_INITIALIZE,
  OPEN_SAVEDCASE,
  ADD_SCENARIO_TABNAVS,
  CLICK_SCENARIO_TABNAVS,
  REMOVE_SCENARIO_TABNAVS,
  MWI_ON_CHANGE,
  TOGGLE_LOCALE,
  ADD_COVERAGE_TABNAVS,
  REMOVE_COVERAGE_TABNAVS,
} from '../core/actionTypes';
import {
  eLang,
  eDividendOption,
  ePremiumOffset,
  eReport,
  eReportType,
  eReportLayoutType,
  eProduct,
  eField,
} from '../core/configs';
import { assignDef } from '../core/utils';
import _ from 'lodash';
import { scenarioSeq } from '../core/sequenceUtils';

const initReportState = {
  isOpen: false,
  isLoading: false,
  lang: eLang.en,
  layout: eReportLayoutType.allyears,
  preparedFor: '',
  reportType: eReportType.base,
  errors: {},
};

const initReportOptions = {
  selectAll: false,
  [eReport.cd]: false,
  [eReport.irr]: false,
  [eReport.pod]: false,
  [eReport.pos]: false,
  [eReport.tcop]: false,
  [eReport.tpop]: false,
  [eReport.tpcd]: false,
  [eReport.td]: false,
  [eReport.ur]: false,
};

const initState = { activeTabId: scenarioSeq(true), [scenarioSeq(true)]: { ...initReportState, ...initReportOptions } };

const getPODValue = (showOptions, value) => (showOptions.premiumOffset ? value : false);

const getPOSValue = (showOptions, value) => (showOptions.premiumOffset ? value : false);

const getTPCDValue = (showOptions, value) => (showOptions.cashDividends ? value : false);

const getCDValue = (showOptions, value) => (showOptions.coverageDetails ? value : false);

const getNewOptions = (value, product, inforce, showOptions) => {
  let options = {
    selectAll: value,
  };

  if (product === eProduct.PAR) {
    options = {
      ...options,
      [eReport.tcop]: value,
      [eReport.tpop]: value,
      [eReport.tpcd]: getTPCDValue(showOptions, value),
      [eReport.pod]: getPODValue(showOptions, value),
    };

    if (inforce) {
      return options;
    }

    options = {
      ...options,
      [eReport.irr]: value,
      [eReport.ur]: value,
      [eReport.pos]: getPOSValue(showOptions, value),
    };
  } else if (product === eProduct.MUL) {
    options = {
      ...options,
      [eReport.irr]: value,
      [eReport.pod]: getPODValue(showOptions, value),
      [eReport.pos]: getPOSValue(showOptions, value),
      [eReport.tcop]: value,
      [eReport.tpop]: value,
      [eReport.tpcd]: getTPCDValue(showOptions, value),
      [eReport.ur]: value,
    };
  } else {
    options = {
      ...options,
      [eReport.cd]: getCDValue(showOptions, value),
      [eReport.td]: value,
      [eReport.ur]: value,
    };
  }

  return options;
};

const eReportSelectAll = (field, option) => {
  switch (field) {
    case eReport.cd:
    case eReport.irr:
    case eReport.pod:
    case eReport.pos:
    case eReport.tcop:
    case eReport.tpop:
    case eReport.tpcd:
    case eReport.td:
    case eReport.ur:
      option.selectAll = false;
      break;
    default:
      break;
  }
};

const checkScenarioPremiumOffSet = (target, field, value) =>
  target === 'scenario' && field === eField.premiumOffset && value === ePremiumOffset.no;

const reduceChange = (state, action) => {
  const { target, field, value, product, inforce, showOptions } = action.payload;

  if (target === 'report') {
    if (field === 'selectAll') {
      const newOptions = getNewOptions(value, product, inforce, showOptions);

      const options = { ...initReportOptions, ...newOptions };
      return {
        ...state,
        [state.activeTabId]: { ...state[state.activeTabId], ...options },
      };
    }

    const option = { selectAll: state[state.activeTabId].selectAll };

    eReportSelectAll(field, option);

    return {
      ...state,
      [state.activeTabId]: { ...state[state.activeTabId], ...option, [field]: value },
    };
  }

  if (target === 'coverage' && field === eField.divOption) {
    if (value === eDividendOption.pui) {
      return {
        ...state,
        [state.activeTabId]: { ...state[state.activeTabId], [eReport.tpcd]: false },
      };
    }
    if (value === eDividendOption.cash) {
      return {
        ...state,
        [state.activeTabId]: { ...state[state.activeTabId], [eReport.pod]: false, [eReport.pos]: false },
      };
    }
  }

  if (checkScenarioPremiumOffSet(target, field, value)) {
    return {
      ...state,
      [state.activeTabId]: { ...state[state.activeTabId], [eReport.pod]: false, [eReport.pos]: false },
    };
  }

  if (target === 'advisorProfile' && field === 'presentedBy') {
    return {
      ...state,
      [state.activeTabId]: { ...state[state.activeTabId], advisor: value },
    };
  }

  return state;
};

const reportReducer = (state, action) => {
  state = state === undefined ? initState : state;
  switch (action.type) {
    case MWI_ON_CHANGE: {
      return reduceChange(state, action);
    }

    case TOGGLE_LOCALE: {
      return {
        ...state,
        [state.activeTabId]: { ...state[state.activeTabId], lang: eLang[action.payload] },
      };
    }

    case ADD_COVERAGE_TABNAVS: {
      // If a new coverage is added, check if selectAll is true and set Coverage details to true
      return {
        ...state,
        [state.activeTabId]: {
          ...state[state.activeTabId],
          [eReport.cd]: !!state[state.activeTabId].selectAll,
        },
      };
    }

    case REMOVE_COVERAGE_TABNAVS: {
      const numTabs = action.payload.numTabs;
      const tabLimit = 2;
      if (numTabs <= tabLimit) {
        return {
          ...state,
          [state.activeTabId]: { ...state[state.activeTabId], [eReport.cd]: false },
        };
      }
      return state;
    }

    default:
      return reportReducer2(state, action);
  }
};

const reportReducer2 = (state, action) => {
  switch (action.type) {
    case COMMON_INITIALIZE: {
      const locale = action.payload.locale ? action.payload.locale : action.payload;
      return {
        ...initState,
        [initState.activeTabId]: {
          ...initState[initState.activeTabId],
          lang: eLang[locale],
        },
      };
    }
    case OPEN_SAVEDCASE: {
      const { scenarioTabNavs, report } = action.payload.model;
      // This logic to assign all the missing propertied from the initial/default to the saved old object
      // with default value.
      const targetState = report;
      assignDef(targetState, initState);
      scenarioTabNavs.tabNavs.forEach((Scenario) => {
        assignDef(targetState[Scenario.id], initReportState, initReportOptions);
      });
      return {
        ...targetState,
      };
    }
    case ADD_SCENARIO_TABNAVS: {
      return {
        ...state,
        activeTabId: action.payload.newTabId,
        [action.payload.newTabId]: { ...state[action.payload.currentTabId] },
      };
    }
    case CLICK_SCENARIO_TABNAVS: {
      return {
        ...state,
        activeTabId: action.payload,
      };
    }
    case REMOVE_SCENARIO_TABNAVS: {
      const newState = _.omit(state, [action.payload.id]);

      return {
        ...newState,
        activeTabId: action.payload.newActiveTabId,
      };
    }
    default:
      return state;
  }
};

export default reportReducer;
