import { eProduct } from '../configs';

const learnMore = 'https://advisor.manulife.ca/advisors/private/private-tools/inforce-illustrations.html';
const productPageURLFTVEn =
  'https://www.manulife.ca/content/dam/manulife-advisor-portal/documents/en/forms/insurance/new-business-and-underwriting/manulife-insurance-family-term-with-vitality-product-page-nn1656e.pdf';

const locale = {
  'common.lang.english': 'English',
  'common.lang.french': 'French',
  'common.save': 'Save',
  'common.saveAs': 'Save as',
  'common.shareIllustration': 'Share Illustration',
  'common.rateChange': 'This illustration may have changed since the last time you opened it.',
  'common.copy': 'Copy',
  'common.copied': 'Copied',
  'common.saved': 'Saved',
  'common.saving': 'Saving...',
  'common.newIllustration': 'New illustration',
  'common.newInforceIllustration': 'New Inforce Illustration',
  'common.policyNumber': 'Policy number: ',
  'common.save.error': 'Error while saving',
  'common.reset': 'Reset',
  'common.edit': 'Edit',
  'common.ok': 'OK',
  'common.cancel': 'Cancel',
  'common.create': 'Create',
  'common.days': 'days',
  'common.delete': 'Delete',
  'common.profile': 'Profile',
  'common.scenario': 'Scenario',
  'common.addScenario': 'Add scenario',
  'common.coverage': 'Coverage',
  'common.coverage.ellipsis': 'Cov.',
  'common.addCoverage': 'Add a coverage',
  'common.rated': 'Rated',
  'common.male': 'Male',
  'common.female': 'Female',
  'common.ratings': 'Ratings',
  'common.ratingsSmall': ' rating',
  'common.ratings.Client1': 'Client 1',
  'common.ratings.Client2': 'Client 2',
  'common.jointTypeRatings': 'Joint rating',
  'common.regularRatings': 'Rating',
  'common.amount': 'Amount',
  'common.withdrawalAmount': 'Withdrawal amount',
  'common.riders': 'Riders',
  'common.termriders': 'Term riders',
  'common.spousalriders': 'Spousal riders',
  'common.client': 'Client',
  'common.clients': 'Client(s)',
  'common.manulife.illustration': 'Manulife Illustrations',
  'common.illustrations': 'Illustrations',
  'common.illustration': 'Illustration',
  'common.illustrations.name': 'Illustrations',
  'common.forLife': 'for life',
  'common.calculator': 'Calculator',
  'common.calculator.name': 'CALCULATOR',
  'common.viewreport': 'Create PDF report',
  'common.viewpdf': 'View PDF',
  'common.customizereport': 'Customize Report',
  'common.custom.MUL': 'Custom',
  'common.and': 'and',
  'common.years': 'years',
  'common.yearss': 'year(s)',
  'common.for': 'for',
  'common.life': 'life',
  'common.for.term': 'for ',
  'common.signin': 'Sign in',
  'common.advisor': 'Advisor',
  'common.name': 'Name',
  'common.advisorname': 'Advisor',
  'common.presentedby': 'Presented by',
  'common.monthly': 'Monthly',
  'common.annually': 'Annual',
  'common.quarterly': 'Quarterly',
  'common.semiannually': 'Semi-annually',
  'common.summary.annually': 'annual',
  'common.summary.monthly': 'monthly',
  'common.vitality': 'Vitality',
  'common.superscript': 'TM',
  'common.logo': 'Manulife logo',
  'common.required': '* Required field',
  'common.system.error':
    "There is a connection issue and we can't complete the illustration at this time. Close the program and try again. If the problem continues, please ",
  'common.specialRequestForm.error':
    'The Special Request Form is not available at this time. Close the program and try again. If the problem continues, please ',
  'common.system.error.link': 'contact Manulife.',
  'common.system.errortitle': 'Error Found',
  'common.date.placeholder': 'DD/MM/YYYY',
  'common.start': 'Start Tool',
  'common.status': 'Status',
  'common.customize': 'Customize',
  'common.custom': 'Custom',
  'common.reports': 'Reports',
  'common.report': 'Report',
  'common.filldown': 'Fill down',
  'common.email': 'Email address',
  'common.open': 'Open',
  'common.refresh': 'Recalculate',
  'common.calculate': 'Recalculate to update premiums.',
  'common.yes': 'Yes',
  'common.no': 'No',
  'common.none': 'None',
  'common.fromage': 'From age',
  'common.toage': 'To age',
  'common.fromyear': 'From year',
  'common.toyear': 'To year',
  'common.duration': 'Duration',
  'common.rates': 'RATES',
  'common.type': 'Type',
  'common.typeKeywords': 'Type keywords',
  'common.remove': 'Remove',
  'common.launch': 'Launch',
  'common.startillustrations': 'Start illustration',
  'common.inforce': 'Inforce',
  'common.newbusiness': 'NewBusiness',
  'common.close': 'Close',
  'common.learnMore': 'Learn more',
  'common.more': 'More',
  'common.error': 'Error',
  'common.error.table.year': 'Year',
  'common.error.table.reqWithdrwal': 'Requested withdrawal',
  'common.error.table.maxWithdrwal': 'Maximum withdrawal',
  'common.error.maxWithdrawal.part1': 'The requested withdrawal exceeds the maximum withdrawal.',
  'common.error.maxWithdrawal.part2': 'The maximum amount will be used.',
  'common.importantInformation': 'Important Information',
  'common.information.effectiveDate':
    'The Vitality Plus feature is not available for the selected date. The effective date has been updated.',
  'common.information.pay100':
    'Pay to age 100 premium duration is not available for the selected date.  The effective date has been updated.',
  'common.information': 'Information',
  'common.warning': 'Warning',
  'common.loading': 'Loading',
  'common.progress': 'Please wait while we are processing your request.',
  'common.enabled': 'Enabled',
  'common.disabled': 'Disabled',
  'common.home': 'Home',
  'common.change': 'Change',
  'common.copytext': 'Copy',
  'common.linkcopied': 'Link copied!',
  'common.illustrations.link': 'https://www.insurance.manulife.ca/illustrations/',
  'common.combinatiosAvailable': 'Available combinations:',
  'common.select': 'Select',
  'common.selectProduct': 'Product home page',
  'common.optional': 'optional',
  'common.wrong.email': 'Wrong email',

  'error.coverageDate.outOfRange': 'Date must be between May 1, 2010 and October 4, 2013',
  'error.coverageDate.invalidDay': 'Coverage effective date cannot be 29, 30, or 31',
  'error.invalidDate': 'Invalid date',
  'error.emptyDate': 'Date field cannot be blank',

  'sronly.newtab': 'This link opens in a new tab',
  'sronly.newwindow': 'This link opens in a new window',
  'sronly.requiredOnly': 'Required',
  'sronly.pdf': 'PDF',

  //Session Timeed out Dialog
  'session.title': 'Session time out',
  'session.subtitle': 'Your Illustrations session will expire in:',
  'session.message.1': 'Please choose ',
  'session.message.2': ' to keep working or click ',
  'session.message.3': ' to end your session now (your changes will not be saved).',
  'session.continue': 'Continue',
  'session.logout': 'Sign out',
  'session.min': 'min',
  'session.secs': 'secs',
  'session.autosaved': 'Autosaved',
  'session.24active.title': 'Security time out',
  'session.24active.subtitle': 'For security reasons, you are required to log out after a 24 hour session.',
  'session.24active.message': 'Your illustration has been saved under the case name “filename”',
  'session.24active.prefix': 'Autosaved',
  //End of session Timed out

  'shareLink.suffix': ' - Shared',

  'healthstyle.1': '1',
  'healthstyle.2': '2',
  'healthstyle.3': '3',
  'healthstyle.4': '4',
  'healthstyle.5': '5',

  'smokingstatus.nonsmoker': 'Non-smoker',
  'smokingstatus.smoker': 'Smoker',

  'scenario.deletemsg': 'Are you sure you want to delete this scenario?',
  'scenario.scenarioname': 'Scenario name',

  'advisor.profile': 'Advisor profile',
  'advisor.name': 'Advisor name',
  'advisor.companyname': 'Company name',
  'advisor.residence': 'Residence',
  'advisor.phonenumber': 'Phone number',
  'advisor.extension': 'Extension',
  'advisor.dialog.delete': 'Are you sure you want to delete this profile?',
  'advisor.deleteProfile': 'Delete Profile',

  'header.editprofile': 'Edit profile',
  'header.links': 'Links',
  'header.addnewprofile': 'Add new profile',
  'header.skip': 'Skip to main content',
  'header.skipFooter': 'Skip to footer site map',
  'header.lang': 'FR',
  'header.switchlang': 'Passer au français',
  'header.help': 'Support',
  'header.accessibility': 'Accessible View',
  'header.accessibility.enable': 'Enable all accessibility features',
  'header.accessibility.disable': 'Disable all accessibility features',
  'header.faqs': 'FAQs',
  'header.repsource': 'REPSOURCE',
  'header.mycases': 'My cases',
  'header.logout': 'Logout',
  'header.repsourceLink':
    'https://repsourcepublic.manulife.com/wps/portal/Repsource/PublicHome/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8zivQx9TTwc3Q183F0NLAwcfUJdzM0DzQ0NDAz1wwkpiAJKG-AAjgb6BbmhigCiQrmY/dz/d5/L2dBISEvZ0FBIS9nQSEh/',
  'header.model.title': 'Save illustration',
  'header.model.label': 'Illustration name',
  'header.model.placeholder': 'Enter illustration name',
  'header.model.label.share':
    'This link provides a snapshot of this illustration, including all current details except the First name and Last name fields. You can share the link by selecting Copy and then sending.',
  'header.model.share.title': 'Share illustration',
  'header.model.error': 'Illustration name already exists. Enter a different name',
  'save-case.generic.error': 'Something went wrong while saving the case',
  'save-case.save-as.label': 'On behalf of the following advisor',

  'sidenav.dashboard': 'Dashboard',
  'sidenav.illustration': 'Illustration in progress',
  'sidenav.productstoillustrate': 'Products to illustrate',
  'sidenav.application': 'Application in progress',
  'utilnav.advisorportal': 'Advisor Portal',
  'utilnav.advisorportal.sit.link': 'https://advisor.sit.manulife.com/landing',
  'utilnav.advisorportal.uat.link': 'https://uat.advisor.manulife.ca/landing',
  'utilnav.advisorportal.prod.link': 'https://advisor.manulife.ca/landing',
  'utilnav.contactus': 'Contact Us',
  'utilnav.contactus.sit.link': 'https://advisor.sit.manulife.com/advisors/connect/contact-us.html',
  'utilnav.contactus.uat.link': 'https://uat.advisor.manulife.ca/advisors/connect/contact-us.html',
  'utilnav.contactus.prod.link': 'https://advisor.manulife.ca/advisors/connect/contact-us.html',
  'utilnav.profile': 'Profile',
  'utilnav.profile.manulifeid': 'Manulife ID',
  'utilnav.profile.manulifeid.link': 'https://id.manulife.ca/advisor/manageid',
  'utilnav.profile.preferences': 'Preferences',
  'utilnav.profile.preferences.link': 'https://advisor.manulife.ca/advisors/connect/contact-us.html',
  'utilnav.profile.signout': 'Sign Out',

  // dashboard
  'dashboard.title': 'Dashboard',
  'dashboard.tab.illustrations': 'Illustrations',
  'dashboard.tab.applications': 'Applications',
  'dashboard.tab.new.applications': 'New business',
  'dashboard.tab.tca.applications': 'Term conversions',
  'dashboard.startIllustration': 'Start a new illustration',
  'dashboard.startApplication': 'Start a Manulife E-Application',
  'dashboard.startTcaApplication': 'Start a Term conversion E-Application',
  'dashboard.startApplication.infoText':
    'Products available: Manulife Par & Manulife Par with <b><i>Vitality Plus</i></b>, Manulife UL & Manulife UL with <b><i>Vitality Plus</i></b>, Family Term & Family Term with <b><i>Vitality Plus</i></b> and Lifecheque',
  'dashboard.table.recordsperpage': 'records per page',
  'dashboard.table.resetFilters': 'Reset filters',
  'dashboard.table.illustrationName': 'Illustration name',
  'dashboard.table.applicationName': 'Application or policy #',
  'dashboard.table.new.applicationName': 'New application or policy #',
  'dashboard.table.origin.applicationName': 'Original policy #',
  'dashboard.table.insuredName': 'Insured name',
  'dashboard.table.select.all': 'All',
  'dashboard.table.product': 'Product',
  'dashboard.table.new.product': 'New Product',
  'dashboard.table.createdBy': 'Created by',
  'dashboard.table.lastUpdatedOn': 'Last updated on',
  'dashboard.table.lastUpdatedBy': 'Last updated by',
  'dashboard.table.nomatch.illustration': 'There are no records that match your criteria.',
  'dashboard.table.no.illustration': 'There are no saved illustrations.',
  'dashboard.table.no.application': 'There are no applications in progress.',
  'dashboard.table.footer.showing': 'Showing',
  'dashboard.table.footer.to': ' to ',
  'dashboard.table.footer.of': ' of ',
  'dashboard.table.footer.records': ' records',
  'dashboard.delete.app.tooltip':
    'You cannot delete an application if you are not the selling advisor, or after it has been submitted.',
  'dashboard.apps.submitted.desc': 'For further updates, go to your Secure Inbox.',
  'dashboard.apps.submitted.desc.new': 'Download application',
  'dashboard.apps.await.desc.pre': 'You have',
  'dashboard.apps.await.desc.post': 'from signing to submit',

  'header.model.save.title': 'Save illustration',
  'header.model.links.title': 'Links',
  'header.model.help.title': 'Help',

  'assign.model.header.label': 'Assign illustration',
  'assign.model.text1': 'You are about to assign this illustration to',
  'assign.model.text2':
    'This advisor will be able to view and edit this illustration. Are you sure you want to continue?',
  'assign.model.button.assign': 'Yes',
  'assign.model.tooltip':
    'When you assign an illustration to an advisor, the advisor will be able to view and edit it.',
  'assign.model.error.required': 'Required',
  'assign.model.myself': 'Myself',

  'feedback.title': 'Feedback',
  'feedback.link': 'https://horizons.confirmit.ca/wix/p107654193826.aspx?l=9',

  'landing.welcome': 'Welcome to Manulife Illustrations!',
  'landing.whatsnew': "What's new?",
  'landing.newinrelease': 'What is new in this October release?',
  'landing.cpr': 'Child Protection',
  'landing.bvp': 'Business Value Protector',
  'landing.vitalitystatus': 'Vitality Status{superscript}',
  'landing.importantnote': 'Important note:',
  'landing.newtext1': 'Secure login (Single sign on) using your Repsource credentials',
  'landing.newtext2': 'Illustrate Business Term',
  'landing.newtext3': 'Ability to solve for coverage by entering a premium amount',
  'landing.newtext4':
    'Ability to add Guaranteed Insurability Option Rider and Accidental Death and Dismemberment Rider',
  'landing.newtext5': 'Product Pages',
  'landing.newtext6': 'Term re-price changes effective on November 6th, 2018',
  'landing.newtext7': 'New on-screen survey and feedback capability',
  'landing.newtext8': 'New spreadsheet view has every 5th year highlighted',
  'landing.newtext9':
    'Corrections to spelling in French, capitalizations, iPad report generation, and to defect with BVP maximum amount and with adding non-rated clients for Combined Coverages',
  'landing.important1': 'IMPORTANT',
  'landing.important2': 'New Business Illustrations - What’s New!',
  'landing.important3': `Now available: Corporate Insured Retirement Program sales concept and Corporate Estate Bond sales concept!`,
  'landing.important6': '<b>Manulife UL Level COI rate decreases!</b>',
  'landing.important14': 'Effective May 29, 2023:',
  'landing.important15': 'Run your own illustrations for:',
  'landing.important16': 'New rates for Term-10 and Term-20!',
  'landing.important17': 'Important information about the dividend interest rate',
  'landing.announcement.par': '• Manulife Par',
  'landing.announcement.parLink':
    'https://advisor.manulife.ca/advisors/insurance/life-insurance/whole-life.html#manulife-par',
  'landing.announcement.parHover': 'Link opens in new window',
  'landing.announcement.parVP': '• Manulife Par with <i>Vitality Plus</i>',
  'landing.announcement.parVPLink':
    'https://advisor.manulife.ca/advisors/insurance/life-insurance/whole-life.html#manulife-par-with-vitality-plus',
  'landing.announcement.parVPHover': 'Link opens in new window',
  'landing.announcement.new': 'NEW',
  'landing.announcement.title.termPrices':
    'Effective May 11, 2024, we are pleased to offer lower rates across several ages and insurance amounts for Family Term and Family Term with <i>Vitality Plus</i><sup>TM</sup>.',
  'landing.announcement.title1.divRec2024':
    'Effective September 1, 2024, the dividend interest rate will remain at 6.35% for Manulife Par & Manulife Par with <i>Vitality Plus</i><sup>TM</sup> policies. ',
  'landing.announcement.title2.divRec2024':
    'Manulife Illustrations will be updated on August 22, 2024 to reflect the dividend interest rate applicable on September 1, 2024.',
  'landing.announcement.title1':
    'Manulife Illustrations now offers the ability to run inforce illustrations for all Manulife UL product versions except Manulife UL with <i>Vitality Plus</i><sup>TM</sup>. Select Manulife UL to begin.',
  'landing.announcement.title2': `For all other products, visit our inforce illustration <a href=${learnMore}>Learn more</a> page.`,
  'landing.announcement.title3': `<b>Inforce Illustrations</b>`,
  'landing.announcement.message4': 'Manulife UL',
  'landing.announcement.message5': 'Performax Gold ',
  'landing.announcement.message6': 'Performax',
  'landing.announcement.message7': 'Family and Business Term renewal premiums',
  'landing.announcement.message8': 'Manulife Par',
  'landing.announcement.message9': 'Manulife Par with <i>Vitality Plus<sup>TM</sup></i>',
  'landing.announcement.message2': 'Business Term has been discontinued.',
  'landing.announcement.message3': '. Diamond View illustrations will no longer be accepted for Manulife Par.',
  'landing.announcement.link2': 'https://advisor.manulife.ca/advisors/insurance/vitality.html',
  'landing.announcement.divReclink':
    'https://advisor.manulife.ca/content/dam/manulife-advisor-portal/documents/en/marketing-materials/insurance/manulife-par/insurance-manulife-par-dividend-scalee.pdf',
  'landing.announcement.rateChange':
    'Effective September 1, 2024, the dividend scale for Performax policies issued after September 24, 1999 and before 2009, has increased by 0.60%. ',
  'landing.announcement.rateChangelink':
    'https://www.manulife.ca/personal/support/insurance/manulife-participating-dividends.html',
  'landing.important.feedback': 'FEEDBACK',
  'landing.includedinrelease': 'Items included in the September release:',
  'landing.includedtext1':
    'Ability to illustrate multi-life cases: Combined, Joint first-to-die, and Joint last-to-die',
  'landing.includedtext2': 'Ability to add {childProtector} and {bvpProtector} riders to the illustration',
  'landing.includedtext3': 'Commissions Summary Report',
  'landing.includedtext4': 'New Date of Birth numeric input (dd/mm/yyyy)',
  'landing.includedtext5': 'New customizable {vitalityStatus} selection',
  'landing.product.selector.title': 'Select product',
  'landing.whysignin': 'Why Sign in',
  'landing.universallife': 'Universal Life',
  'landing.wholelife': 'Whole Life',
  'landing.term': 'Term',
  'landing.lifecheque': 'Critical Illness',
  'landing.livingbenefits': 'Living Benefits',
  'landing.inforce': 'Inforce Illustration',
  'landing.inforce.title': 'Inforce',
  'landing.newbusiness': 'New Business',
  'landing.inforce.businesstypelabel': 'New business or existing Inforce Illustration?',
  'landing.inforce.policynumberlabel': 'Enter policy number',
  'landing.note':
    '{Important} Be advised that as of October 31st, 2018, the Underwriting Requirements report launched through this tool will have the new re-price changes that take effect on November 6th, 2018. If you wish to launch an Underwriting Requirements report prior to November 6th, 2018, please use DiamondView. ',
  'landing.term.rateDecrease.title': '<b>Term insurance rate decreases!</b>',
  'landing.term.rateDecrease.message':
    'Term-10 and Term-20 for Family Term, Business Term, and Family Term with Vitality have lower insurance rates in most cases.',
  'landing.ft.rateDecrease.message':
    'Effective November 20th, 2021: Term-10 and Term-20 for Family Term have lower insurance rates in most cases.',
  'landing.bt.rateDecrease.message':
    'Effective November 20th, 2021: Term-10 and Term-20 for Business Term have lower insurance rates in most cases.',

  'landing.par.productDescription':
    'Guaranteed early cash values meets flexibility and long-term protection, all with potential tax-saving advantages.',
  'landing.par.productDescription2': 'Not only does Manulife Par offer valuable guarantees, it also comes with:',
  'landing.par.productDescription3':
    'Manulife Par with <b><i>Vitality Plus</i></b> combines these same great features with the opportunity for clients to earn rewards and discounts – all while improving their health.',
  'landing.all.learnVitality': 'Learn more about <i>Vitality</i> &reg;',
  'landing.all.vitalitySimulator': 'Manulife <i>Vitality</i> Rewards and Status Simulator',
  'landing.all.clickhere': 'click here',
  'landing.all.clickhere1': 'Click here',
  'landing.mul.productDescription':
    'Manulife UL offers your clients permanent life insurance that may also help them to increase their investments - whatever their investment style.',
  'landing.mul.productDescription2':
    'There is a level cost option and a yearly renewable term (YRT) cost option.  This product offers a broad range of investment options, including an option with a 1% minimum interest rate guarantee.',
  'landing.mul.productDescription4':
    'Manulife UL with <b><i>Vitality Plus</i></b> combines these same great features with the opportunity for clients to earn rewards and reduce the cost of their insurance – all while improving their health.',
  'landing.if.infomessage': 'To learn more about inforce illustrations, ',
  'landing.if.link1': 'https://advisor.manulife.ca/advisors/private/private-tools/manulife-illustrations.html',
  'landing.if.unauthorizedlink1':
    'https://advisor.manulife.ca/advisors/private/private-tools/inforce-illustrations.html',
  'landing.if.search': 'Search',
  'landing.inforce.error.unauthorized': 'You have entered a policy number that cannot be illustrated with this tool. ',
  'landing.inforce.error.leadTimeValidation':
    'This policy cannot be illustrated at this time due to anniversary processing activities. Please retry when the policy is within 23 days of the policy anniversary.',
  'landing.inforce.unauthorized': ' for help to request an illustration.',
  'landing.inforce.error.notillustratable': 'You have entered a policy number that is not inforce.',
  'landing.inforce.error.paidUpPolicy': 'Reduced paid-Up policies cannot be illustrated.',
  'landing.inforce.error.productmismatch':
    'You have entered a policy number that you are not authorized to access. Please check the policy number and try again.',
  'landing.inforce.error.unavailable':
    'The Inforce Illustrations tool is not available at this time. Please try again later.',
  'landing.inforce.error.vitalityplus':
    'You have entered a policy number that has Vitality Plus and cannot be illustrated.',
  'landing.inforce.error.vitalityplus.terminforce':
    'You have entered a policy number that has Vitality or Vitality Plus and cannot be illustrated.',
  'landing.inforce.error.specialquote': 'Policies on special quote cannot be illustrated.',
  'landing.inforce.error.specialquote.PAR':
    'You have entered a policy number that cannot be illustrated with this tool.',
  'landing.inforce.error.redflag': 'You have entered a policy number that cannot be illustrated with this tool.',
  'landing.inforce.error.waiver': 'Policies on Waiver cannot be illustrated.',
  'landing.inforce.error.TermOptionNotSupported':
    'You have entered a policy that has Term Option and cannot be illustrated.',
  'landing.inforce.error.UseSpecialRequestForm':
    'You have entered a policy number that cannot be illustrated with this tool.',
  'landing.inforce.error.CPRNotSupported':
    'You have entered a policy number that cannot be illustrated with this tool.',
  'landing.inforce.error.TermOptionPlusCoverageWithoutTermOptionCoverageNotSupported':
    'You have entered a policy number that cannot be illustrated with this tool.',
  'landing.inforce.error.NoPaymentsError':
    'You have entered a policy number that cannot be illustrated with this tool.',
  'landing.inforce.warning.redflag': 'You have entered a policy number that is on Redflag.',
  'landing.inforce.irfOnlyProduct1': 'You have entered a policy number for the product ',
  'landing.inforce.irfOnlyProduct2': ' that cannot be illustrated with this tool.',
  'landing.inforce.irfintro': ' Use the ',
  'landing.inforce.irflink': 'special request form',
  'landing.inforce.irfoutro': '.',
  'landing.mulinforce.contactEmail': 'Digital_desk@manulife.ca',
  'landing.mulinforce.illustrationEmail': 'inforceillustrations@manulife.ca',
  'landing.mulinforce.supportcentreEmail': 'DTSC@manulife.ca',
  'landing.mulinforce.productDescription':
    'Manulife UL offers your clients permanent life insurance that can play an important part in their financial plans. This tool can help manage your Manulife UL inforce business with illustrations for all Manulife UL product versions except those with <i>Vitality Plus<sup>TM</sup></i>.',
  'landing.mulinforce.productDescription1': 'Use this tool to illustrate Manulife UL policies. You can:',
  'landing.mulinforce.productBullet0': 'Show current state (as-is illustration)',
  'landing.mulinforce.productBullet1': 'Change policy interest rates',
  'landing.mulinforce.productBullet2':
    'Specify deposit amount and duration (quick pay, no further deposits, dump-in deposits)',
  'landing.mulinforce.productBullet3': 'Customize deposit amounts at each duration',
  'landing.mulinforce.productDescription2': 'At this time, the tool can’t illustrate the following:',
  'landing.mulinforce.toolBullet0': 'Changes in the amount of insurance',
  'landing.mulinforce.toolBullet1': 'Third party term insurance riders (TIRs)',
  'landing.mulinforce.toolBullet2': 'Changes in the amount of TIR coverage',
  'landing.mulinforce.toolBullet3': 'Withdrawals',
  'landing.mulinforce.toolBullet4': 'Switching from YRT to Level cost of insurance',
  'landing.mulinforce.productDescription3A':
    'For these, we can provide a new cost of insurance quote <u>only</u>. To find your channel’s dedicated contact number, click the ',
  'landing.mulinforce.productDescription3B': 'Contact Us', // utilnav.contactus.prod.link
  'landing.mulinforce.productDescription3C':
    ' link and then click to expand the <b>Inforce and new business inquiries</b> section.',
  'landing.mulinforce.productDescription4A': 'Contact the Inforce Illustrations Support Team at ',
  'landing.mulinforce.productDescription4B': 'inforceillustrations@manulife.ca', // landing.mulinforce.illustrationEmail
  'landing.mulinforce.productDescription4C': ' for:',
  'landing.mulinforce.contactBullet0': 'Help with completing an online illustration request',
  'landing.mulinforce.contactBullet1': 'Questions about an illustration you’ve run',
  'landing.mulinforce.productDescription5A':
    'For questions about any technical issues, please contact the Distribution Technology Support Centre at ',
  'landing.mulinforce.productDescription5B': 'DTSC@manulife.ca', // landing.mulinforce.supportcentreEmail
  'landing.mulinforce.productDescription5C': ' or 1-800-667-4266.',
  'landing.mulinforce.productDescription6':
    'The Inforce Manulife UL Illustrations service is available Monday to Friday from 6:30 AM to 11:59 PM ET and on Saturday from 7:00 AM to 5:00 PM ET.',

  'landing.performaxgold.openingParagraph':
    'Use this tool to illustrate Performax Gold policies with a performance credit (PC) option of paid-up insurance or accumulation account. You can:',
  'landing.performaxgold.productBullet0': 'Show current state (as-is illustration)',
  'landing.performaxgold.productBullet1': 'Change the PC rate',
  'landing.performaxgold.productBullet2': 'Select earliest payment offset or specify payment offset',
  'landing.performaxgold.productBullet3': 'Terminate a TIR at the next policy anniversary or later',
  'landing.performaxgold.productBullet4':
    'Change additional payments - level amount or additional current year payment',
  'landing.performaxgold.productBullet5': 'Request a withdrawal (not customized)',
  'landing.performaxgold.specialRequestParagraph1': 'At this time, the following can be illustrated using the ',
  'landing.performaxgold.specialRequestForm': 'special request form',
  'landing.performaxgold.specialRequestParagraph2': ':',
  'landing.performaxgold.specialRequestBullet0': 'Decrease a TIR amount',
  'landing.performaxgold.specialRequestBullet1': 'Terminate riders other than a TIR',
  'landing.performaxgold.specialRequestBullet2':
    'Change the additional payment allocation between deposit option and accumulation',
  'landing.performaxgold.specialRequestBullet3': 'More than 2 base insurance coverages',
  'landing.performaxgold.specialRequestBullet4': 'More than 3 TIRs',
  'landing.performaxgold.limitationsParagraph': 'At this time, we can’t illustrate the following:',
  'landing.performaxgold.limitationsBullet0': 'A policy with a PC option of term option',
  'landing.performaxgold.limitationsBullet1': 'A decrease in the base amount of insurance',
  'landing.performaxgold.limitationsBullet2': 'Terminating a base insurance coverage',
  'landing.performaxgold.limitationsBullet3': 'A change in Healthstyle',

  // Below .parinforce. text is for PMax.
  'landing.performax.openingParagraph':
    'Use this tool to illustrate Performax policies with any dividend option. You can:',
  'landing.performax.productBullet0': 'Show current state (as-is illustration)',
  'landing.performax.productBullet1': 'Change the dividend option (except for premium reduction)',
  'landing.performax.productBullet2':
    'Select earliest premium offset, specify premium offset, or specify premium holiday',
  'landing.performax.productBullet3': 'Terminate a TIR, TOR and/or SPR at next policy anniversary or later',
  'landing.performax.productBullet4': 'Change deposit option payment - level amount',
  'landing.performax.productBullet5': 'Request a withdrawal (not customized)',
  'landing.performax.specialRequestParagraph1': 'At this time, the following can be illustrated using the ',
  'landing.performax.specialRequestForm': 'special request form',
  'landing.performax.specialRequestParagraph2': ':',
  'landing.performax.specialRequestBullet0': 'Decrease a TIR, TOR and/or SPR amount',
  'landing.performax.specialRequestBullet1': 'Terminate riders other than a TIR, TOR and/or SPR',
  'landing.performax.specialRequestBullet2': 'Change to a non-smoking status',
  'landing.performax.specialRequestBullet3': 'Decrease the base amount of insurance',
  'landing.performax.specialRequestBullet4':
    'Illustrate policies that have a base amount of insurance over $10 million',
  'landing.performax.limitationsParagraph': 'At this time, we can’t illustrate the following:',
  'landing.performax.limitationsBullet0': 'A policy with a change to dividend option of premium reduction',

  // Below .parinforce. text is for Security Builder
  'landing.sb.openingParagraph':
    'Use this tool to illustrate Security Builder policies with any dividend option. You can:',
  'landing.sb.productBullet0': 'Show current state (as-is illustration)',
  'landing.sb.productBullet1': 'Change the dividend option (except for premium reduction)',
  'landing.sb.productBullet2': 'Select earliest premium offset, specify premium offset, or specify premium holiday',
  'landing.sb.productBullet3': 'Terminate a TIR, TOR and/or SPR at next policy anniversary or later',
  'landing.sb.productBullet4': 'Change deposit option payment - level amount',
  'landing.sb.productBullet5': 'Request a withdrawal (not customized)',
  'landing.sb.specialRequestParagraph1': 'At this time, the following can be illustrated using the ',
  'landing.sb.specialRequestForm': 'special request form',
  'landing.sb.specialRequestParagraph2': ':',
  'landing.sb.specialRequestBullet0': 'Decrease a TIR, TOR and/or SPR amount',
  'landing.sb.specialRequestBullet1': 'Terminate riders other than a TIR, TOR and/or SPR',
  'landing.sb.specialRequestBullet2': 'Change to a non-smoking status',
  'landing.sb.specialRequestBullet3': 'Decrease the base amount of insurance',
  'landing.sb.specialRequestBullet4': 'Illustrate policies that have a base amount of insurance over $10 million',
  'landing.sb.limitationsParagraph': 'At this time, we can’t illustrate the following:',
  'landing.sb.limitationsBullet0': 'A policy with a change to dividend option of premium reduction',

  'landing.parInforce.openingParagraph': 'Use this tool to illustrate Manulife Par policies. You can:',
  'landing.parInforce.productBullet0': 'Show current state (as-is illustration)',
  'landing.parInforce.productBullet1': 'Terminate a TIR at the next policy anniversary or later',
  'landing.parInforce.productBullet2': 'Decrease a TIR amount',
  'landing.parInforce.productBullet3': 'Select earliest premium offset or specify premium offset',
  'landing.parInforce.productBullet4': 'Change or add deposit option payments (level, custom, one-time)',
  'landing.parInforce.productBullet5': 'Request a withdrawal (not customized)',
  'landing.parInforce.productBullet6': 'Switch to cash dividend option',
  'landing.parInforce.productBullet7': 'Change alternative dividend scale',
  'landing.parInforce.specialRequestParagraph1': 'At this time, the following can be illustrated using the ',
  'landing.parInforce.specialRequestForm': 'special request form',
  'landing.parInforce.specialRequestParagraph2': ':',
  'landing.parInforce.specialRequestBullet0': 'Terminate riders other than a TIR',
  'landing.parInforce.specialRequestBullet1': 'A decrease in the base amount of insurance',
  'landing.parInforce.specialRequestBullet2': 'More than 3 TIRs',
  'landing.parInforce.limitationsParagraph': 'At this time, we can’t illustrate the following:',
  'landing.parInforce.limitationsBullet0': 'A change in smoking status',
  'landing.parInforce.limitationsBullet1': 'Change from cash to paid up insurance dividend option',
  'landing.parInforce.limitationsBullet2': 'Addition of TIR or other rider(s)',
  'landing.parInforce.limitationsBullet3': 'Upgrade to Vitality Plus (if applicable)',

  'landing.parInforce.vp.openingParagraph':
    'Use this tool to illustrate Manulife Par with <i>Vitality Plus</i><sup>TM</sup> policies. You can:',
  'landing.parInforce.vp.productBullet0': 'Show current state (as-is illustration)',
  'landing.parInforce.vp.productBullet1': 'Terminate a TIR at the next policy anniversary or later',
  'landing.parInforce.vp.productBullet2': 'Decrease a TIR amount',
  'landing.parInforce.vp.productBullet3': 'Select earliest premium offset or specify premium offset',
  'landing.parInforce.vp.productBullet4': 'Change or add deposit option payments (level, custom, one-time)',
  'landing.parInforce.vp.productBullet5': 'Request a withdrawal (not customized)',
  'landing.parInforce.vp.productBullet6': 'Switch to cash dividend option',
  'landing.parInforce.vp.productBullet7': 'Change alternative dividend scale',
  'landing.parInforce.vp.productBullet8': 'Change the Vitality Status',
  'landing.parInforce.vp.specialRequestParagraph1':
    'At this time, the following can be illustrated using the special request form: ',
  'landing.parInforce.vp.specialRequestForm': 'special request form',
  'landing.parInforce.vp.specialRequestParagraph2': ':',
  'landing.parInforce.vp.specialRequestBullet0': 'Terminate riders other than a TIR',
  'landing.parInforce.vp.specialRequestBullet1': 'A decrease in the base amount of insurance',
  'landing.parInforce.vp.specialRequestBullet2': 'More than 3 TIRs',
  'landing.parInforce.vp.limitationsParagraph': 'At this time, we can’t illustrate the following:',
  'landing.parInforce.vp.limitationsBullet0': 'A change in smoking status',
  'landing.parInforce.vp.limitationsBullet1': 'Change from cash to paid up insurance dividend option',
  'landing.parInforce.vp.limitationsBullet2': 'Addition of TIR or other rider(s)',

  'landing.bt.productDescription':
    'We offer the most comprehensive and flexible term insurance coverage on the market. When you add its wide range of customizable features, strong sales support and reliable claims service – all backed by the strength of Manulife Financial, you’ll agree that our term insurance is the perfect solution.',
  'landing.bt.productDescription2':
    'Business Term offers flexible term insurance designed to meet the long and short-term needs of businesses and business owners.',
  'landing.ft.productDescription':
    'We offer the most comprehensive and flexible term insurance coverage on the market. When you add its wide range of customizable features, strong sales support and reliable claims service – all backed by the strength of Manulife Financial, you’ll agree that our term insurance is the perfect solution.',
  'landing.ft.productDescription2':
    'Family Term is designed to meet the protection needs of families in one highly flexible, affordable policy.',
  'landing.ft.productDescription3':
    'Family Term with <b><i>Vitality Plus</i></b> combines a term life insurance product with the opportunity for clients to earn rewards and save on their premiums – all while improving their health.',
  'landing.lc.productDescription':
    'Lifecheque is one of the most comprehensive critical illness insurance products available today. It provides a cash benefit if your client is diagnosed with one of the covered conditions. This gives your client the financial freedom to focus on what really matters: getting better. They can use the money in any way they see fit, including:',
  'landing.lc.productDescription2':
    'With 24 covered conditions, coverage for early-stage conditions, built-in long term care protection, a broad range of plan types, and optional riders to choose from, Lifecheque is an excellent choice for your clients.',
  'landing.lc.productBullet0': 'Replacing lost income',
  'landing.lc.productBullet1': 'Making mortgage payments',
  'landing.lc.productBullet2': 'Reducing workload to help with recovery',
  'landing.lc.productBullet3': 'Hiring a nurse or caregiver',
  'landing.lc.productBullet4': 'Finding the best health care available',
  'landing.ftrc.warning': 'Select functionality only',
  'landing.ftrc.productDescription':
    'The renewal calculator lets you perform quick look-ups for your clients’ next renewal costs. You can use this tool for Family Term policies with:',
  'landing.ftrc.productDescription2': 'Use the renewal calculator for one coverage at a time.',
  'landing.ftrc.productDescription3':
    'The calculator can estimate costs for current or decreased coverage amounts. You can’t use it to estimate the costs for coverage increases.',
  'landing.ftrc.productDescription4':
    'Check back for updates and enhancements to the renewal calculator, including increased coverage date ranges and PDF reports with all renewal dates.',
  'landing.ftrc.with.newtext1':
    'a Term-10 coverage that has a coverage effective date between May 1, 2010 and October 4, 2013 (does not support a coverage added to a policy with a Rainy-day account)',
  'landing.ftrc.with.newtext2': 'single-life, ',
  'landing.ftrc.with.newtext.notcombined': 'not combined',
  'landing.ftrc.with.newtext3': 'single coverage',
  'landing.ftrc.with.newtext4': 'standard risk (not rated)',
  'landing.ftrc.title': 'NEW! Family Term renewal calculator: Term-10',
  'landing.par.newtext1':
    'Pre-approved lifetime deposit option limit – even if you didn’t ask for it – and also for policies converted from term',
  'landing.par.newtext2': 'Ratings are never applied to paid-up and deposit options insurance',
  'landing.par.newtext3': 'Contracts written in plain, client-friendly language',
  'landing.par.newtext4': 'No policy fees',
  'landing.par.pirpannouncement':
    'Exciting news! The Insured Retirement Program (Personal IRP) sales concept is now available with Manulife PAR.',
  'landing-whats-new1':
    'Solve for coverage. Enter a premium amount and the illustration tools deducts the cost of any riders and calculates the amount of insurance that can be purchased.',
  'landing-whats-new2': 'Ability to specify year for switch to cash dividends.',
  'landing.contactwholesaler': 'https://www.manulife.ca/advisors/connect/contact-us.html',
  'landing.googlechrome.link':
    'https://www.google.ca/chrome/?brand=CHBD&gclid=CjwKCAjwwab7BRBAEiwAapqpTHFx-G33GDPgqk8NMTb3ks6ibOQ22YgjwdQoL_eKEQxRcBA7nCWGvBoC2OEQAvD_BwE&gclsrc=aw.ds',
  'landing.msedge.link': 'https://www.microsoft.com/en-us/edge',
  'landing.ie.learnmore.link':
    'https://support.microsoft.com/en-ca/help/4028606/windows-10-change-your-default-browser',

  'landing.ftbtinforce.productDescription1':
    'Use this tool to illustrate current and renewal premiums for Family Term and Business Term policies. You can:',
  'landing.ftbtinforce.productBullet0': 'Show current and renewal premiums',
  'landing.ftbtinforce.productBullet1': 'Show all coverage options:  Term-10, Term-20, Term-65, Term-Life',
  'landing.ftbtinforce.productDescription2': 'At this time, the tool can’t illustrate the following:',
  'landing.ftbtinforce.toolBullet0': 'A change in the amount of insurance',
  'landing.ftbtinforce.toolBullet1': 'A termination of a base insurance coverage',
  'landing.ftbtinforce.toolBullet2': 'A policy with more than 6 base insurance coverages',
  'landing.ftbtinforce.toolBullet3': 'A policy with more than 4 CPR coverages',
  'landing.ftbtinforce.toolBullet4': 'A change in Healthstyle or smoking status',
  'landing.ftbtinforce.toolBullet5': 'Family Term with <i>Vitality</i><sup>TM</sup>',
  'landing.ftbtinforce.toolBullet6': 'Family Term with <i>Vitality Plus</i><sup>TM</sup>',
  'landing.ftbtinforce.productDescription3A': 'For these, find your channel’s dedicated contact number, click the ',
  'landing.ftbtinforce.productDescription3B': 'Contact Us', // utilnav.contactus.prod.link
  'landing.ftbtinforce.productDescription3C':
    ' link and then click to expand the <b>Inforce and new business inquiries</b> section.',
  'landing.calcserver': 'Calc Server',

  'logout.modal.heading1': 'Your session is about to expire',
  'logout.modal.content1': "You're being timed out due to inactivity. Please save your data.",
  'logout.modal.content2': 'Do you want to stay signed in?',
  'logout.modal.stay.signed.in': 'Stay signed in',
  'logout.modal.sign.out': 'Sign out now',

  'toolbar.customizereport': 'Customize Report',
  'topbar.effectivedate': 'Effective Date',

  // Lifecheque
  'lc.rop.death': 'Return of premium at death (ROPD)',
  'lc.rop.expiry': 'Return of premium at expiry (ROPX)',
  'lc.rop.surrender': 'Return of premium with early surrender option (ROPS)',

  'lc.rop.death.coverage': 'ROPD rider',
  'lc.rop.expiry.coverage': 'ROPX rider',
  'lc.rop.surrender.coverage': 'ROPS rider',

  'lc.learn.more.coverage.options': "Available options depend on the client's age:",
  'lc.coverage.option.LifechequeRenewable10': '10-year renewable',
  'lc.coverage.option.lc10.abbr': 'lc10',
  'lc.coverage.option.LifechequeRenewable20': '20-year renewable',
  'lc.coverage.option.lc20.abbr': 'lc20',
  'lc.coverage.option.lc65': 'Primary (T65)',
  'lc.coverage.option.lc65.abbr': 'lc65',
  'lc.coverage.option.lc75': 'Level (T75)',
  'lc.coverage.option.lc75.abbr': 'lc75',
  'lc.coverage.option.lcPermanent': 'Permanent',
  'lc.coverage.option.lcPermanent.abbr': 'lcPermanent',
  'lc.premium.duration.lcPayToAge100': 'Pay to age 100',
  'lc.premium.duration.lcPayFor15': 'Pay for 15 years',

  'lc.rider.clc.abbr': "Children's Lifecheque",
  'lc.rider.clc': "Children's Lifecheque",
  'lc.rider.wpd.abbr': 'Waiver of premium',
  'lc.rider.wpd': 'Waiver of premium on disability',
  'lc.rider.prw.abbr': 'Payor waiver',
  'lc.rider.prw': 'Payor waiver',
  'lc.rider.tdw.payor': 'Payor waiver',

  'lc.riders.label': 'OTHER RIDERS',
  'lc.riders.label.common': 'Other Riders',
  // End Lifecheque

  'product.label': 'Product',
  'product.ft': 'Family Term',
  'product.ftv': 'Family Term with Vitality',
  'product.ft.title': 'Family Term and Family Term with <i>Vitality Plus</i><sup>TM</sup>',
  'product.bt': 'Business Term',
  'product.par': 'Manulife Par',
  'product.par.title': 'Manulife Par and Manulife Par with <i>Vitality Plus</i><sup>TM</sup>',
  'product.par.inforce.title': 'Manulife Par',
  'product.par.vp.inforce.title': 'Manulife Par with <i>Vitality Plus</i><sup>TM</sup>',
  'product.pg': 'Performax Gold',
  'product.performaxgold': 'Performax Gold',
  'product.performaxgold.inforce.title': 'Performax Gold',
  'product.performax.inforce.title': 'Performax',
  'product.performax': 'Performax',
  'product.securitybuilder.inforce.title': 'Security Builder',
  'product.securitybuilder': 'Security Builder',
  'product.lc': 'Lifecheque',
  'product.lc.title': 'Lifecheque®',
  'product.cilc': 'Critical Illness (Lifecheque)',
  'product.sy': 'Combination Insurance (Synergy)',
  'product.sy.link': 'https://manuliferates.com/',
  'product.sy.hover': 'Link opens in new window',
  'product.ftrc': 'Family Term renewal calculator: Term-10',
  'product.mul': 'Manulife UL',
  'product.mul.title': 'Manulife UL and Manulife UL with <i>Vitality Plus</i><sup>TM</sup>',
  'product.mul.inforce.title': 'Manulife UL',
  'product.mulinforce.title': 'Manulife UL',
  'product.gi': 'Manulife Guaranteed Issue Life',
  'product.iv': 'InnoVision',
  'product.iv.title': 'InnoVision Title Placeholder',
  'product.innovision.inforce.title': 'InnoVision',
  'product.if': 'Inforce Illustrations',
  'product.businessterm.inforce.title': 'Family Term and Business Term',
  'product.familyterm.inforce.title': 'Family Term and Business Term',
  'product.terminforce.link': 'Family and Business Term renewal premiums',
  'product.terminforce': 'Inforce Illustrations | Family and Business Term renewal premiums',

  'product.architect.inforce.title': 'Architect',
  'product.champion.inforce.title': 'Champion',
  'product.dividendterm.inforce.title': 'Dividend Term',
  'product.dlul.inforce.title': 'DLUL',
  'product.futureprotection.inforce.title': 'Future Protection',
  'product.intrepid.inforce.title': 'Intrepid',
  'product.lifeaccumulator.inforce.title': 'Life Accumulator',
  'product.lifesaver.inforce.title': 'Life Saver',
  'product.mlidlpar.inforce.title': 'MLI/DL PAR',
  'product.naltraditional.inforce.title': 'NAL Traditional',
  'product.newworldseries.inforce.title': 'New World Series',
  'product.maritimelifeproducts.inforce.title': 'Maritime Life Products',
  'product.performanceplan.inforce.title': 'Performance Plan',
  'product.securityul.inforce.title': 'Security UL',
  'product.solutions.inforce.title': 'Solutions',
  'product.securedprotection.inforce.title': 'Secured Protection',
  'product.securityul2.inforce.title': 'Security Universal Life',
  'product.ul100.inforce.title': 'UL100',

  'covType.single': 'Single-Life',
  'covType.singleMUL': 'Single-Life',
  'covType.singlePG': 'Single-Life',
  'covType.singlePMax': 'Single-Life',
  'covType.combined': 'Combined',
  'covType.jftd': 'Joint first-to-die',
  'covType.jftd.abbr': 'JFTD',
  'covType.jltd': 'Joint last-to-die',
  'covType.jltd.abbr': 'JLTD',
  'covType.jltd.costtofirstdeath': 'Joint last-to-die, costs to first death',
  'covType.jltd.costtolastdeath': 'Joint last-to-die, costs to last death',
  'covType.jltd.lastdeath': 'Joint last-to-die, payable to last death',
  'covType.jltd.lastdeath.abbr': 'JLTD',
  'covType.jltd.firstdeath.abbr': 'JLTD',

  'covOption.t10': 'Term-10',
  'covOption.t10.abbr': 'T10',
  'covOption.t20': 'Term-20',
  'covOption.t20.abbr': 'T20',
  'covOption.t65': 'Term-65',
  'covOption.t65.abbr': 'T65',
  'covOption.tlife': 'Term Life',
  'covOption.tlife.abbr': 'TLife',

  'deposit.label': 'Deposits',
  'deposit.duration.age': 'Age',
  'deposit.duration.year': 'Year',
  'deposit.mul.years': 'Years',
  'deposit.ownerresidence': 'Policy owner residence',
  'deposit.ownerresidence.error': 'Required',
  'deposit.ownerresidence.select': 'Select',
  'deposit.ownerresidence.alberta': 'Alberta',
  'deposit.ownerresidence.britishcolumbia': 'British Columbia',
  'deposit.ownerresidence.manitoba': 'Manitoba',
  'deposit.ownerresidence.newbrunswick': 'New Brunswick',
  'deposit.ownerresidence.newfoundlandandlabrador': 'Newfoundland and Labrador',
  'deposit.ownerresidence.northwestterritories': 'Northwest Territories',
  'deposit.ownerresidence.novascotia': 'Nova Scotia',
  'deposit.ownerresidence.nunavut': 'Nunavut',
  'deposit.ownerresidence.ontario': 'Ontario',
  'deposit.ownerresidence.princeedwardisland': 'Prince Edward Island',
  'deposit.ownerresidence.quebec': 'Quebec',
  'deposit.ownerresidence.saskatchewan': 'Saskatchewan',
  'deposit.ownerresidence.yukon': 'Yukon',
  'deposit.ownerresidence.notapplicable': 'Not Applicable',
  'deposit.frequency': 'Deposit frequency',
  'deposit.type': 'Deposit amount',
  'deposit.type.learnmore': 'Deposit solves:',
  'deposit.type.learnmore.header1': 'Solve name',
  'deposit.type.learnmore.header2': 'Description',
  'deposit.type.learnmore.header3': 'Key points',
  'deposit.type.learnmore.column1-1': 'Specified',
  'deposit.type.learnmore.column1-2': 'Deposit amount is known.',
  'deposit.type.learnmore.column1-3': ' ',
  'deposit.type.learnmore.column2-1': 'Level maximum',
  'deposit.type.learnmore.column2-2':
    '	For a specified amount of insurance, the solve provides the level maximum deposit amount for the specified duration.',
  'deposit.type.learnmore.column2-3-1': 'Rider costs are included in the solve.',
  'deposit.type.learnmore.column2-3-2': 'An additional 1ˢᵗ year deposit cannot be selected.',
  'deposit.type.learnmore.column2-3-3': 'Withdrawals can be selected but are not included in the solve.',
  'deposit.type.learnmore.column2-3-4': 'The side account is not used.',
  'deposit.type.learnmore.column2-3-5':
    'The solve could result in a lapse warning: “This policy will lapse. Please increase the deposit duration.“',
  'deposit.type.learnmore.column3-1': 'Level minimum',
  'deposit.type.learnmore.column3-2':
    'For a specified amount of insurance, the solve provides the level minimum deposit amount for the specified duration.',
  'deposit.type.learnmore.column3-3-1': 'Rider costs are included in the solve.',
  'deposit.type.learnmore.column3-3-2': 'An additional 1ˢᵗ year deposit can be selected and is included in the solve.',
  'deposit.type.learnmore.column3-3-3': 'Withdrawals cannot be selected.',
  'deposit.type.learnmore.column3-3-4': 'Limited side account usage to keep the policy in force.',
  'deposit.type.learnmore.column3-3-5':
    'The solve could result in a lapse warning “This policy will lapse. Please increase the deposit duration.“',
  'deposit.type.learnmore.column4-1': 'Yearly minimum',
  'deposit.type.learnmore.column4-2':
    'For a specified amount of insurance, the solve provides the yearly minimum deposit amount.',
  'deposit.type.learnmore.column4-3-1': 'Rider costs are included in the solve.',
  'deposit.type.learnmore.column4-3-2':
    'An additional 1ˢᵗ year deposit can be selected but is not included in the solve.',
  'deposit.type.learnmore.column4-3-3': 'Withdrawals cannot be selected.',
  'deposit.type.learnmore.column4-3-4': 'The side account is not used.',
  'deposit.type.specified': 'Specified',
  'deposit.type.levelmax': 'Level maximum',
  'deposit.type.levelmin': 'Level minimum',
  'deposit.type.yearlymin': 'Yearly minimum',
  'deposit.amount.monthly': 'Monthly amount',
  'deposit.amount.annual': 'Annual amount',
  'deposit.duration': 'Duration',
  'deposit.to.age': 'To age',
  'deposit.additional.deposit': 'Include additional 1st year deposit',
  'deposit.additional.deposit.inforce': 'Additional current year deposit',
  'deposit.additional.deposit.inforce.PG': 'Additional current year payment',
  'deposit.additional.deposit.inforce.par': 'Illustrate a one-time deposit option payment now',
  'deposit.mul.lvlmax': 'Level max. ',
  'deposit.mul.lvlmin': 'Level min. ',
  'summary.depositoption.MUL.yearly': 'Yearly minimum ',

  'depositoption.label': 'Deposit Option (DO) payments',
  'additionalpayments.label': 'Additional payments',
  // 'depositoption.type': 'Type',
  'depositoption.type.specified': 'Specified',
  'depositoption.type.levelmax': 'Level maximum',
  'depositoption.type.none': 'None',
  // 'depositoption.amount': 'Amount',
  'depositoption.amount.monthly': 'Amount (monthly)',
  'depositoption.amount.annually': 'Amount (annually)',
  'depositoption.amount.quarterly': 'Amount (quarterly)',
  'depositoption.amount.semi-annually': 'Amount (semi-annually)',
  'depositoption.duration': 'Duration',
  'depositoption.duration.years': 'Years',
  'depositoption.duration.sameasbase': 'Same as base',
  'depositoption.custom': 'Custom',

  'divOption.pui': 'Paid Up Insurance',
  'divOption.cash': 'Cash',
  'divOption.PaidUpInsurance': 'Paid-up insurance',
  'divOption.Performaxcash': 'Cash',
  'divOption.termOption': 'Term Option',
  'divOption.Accumulation': 'Accumulation',
  'divOption.AccumulationMaintainExistingPUI': 'Accumulation, maintain existing PUI',
  'divOption.AccumulationTransferPUIToAccumulation': 'Accumulation, transfer PUI to accumulation',
  'divOption.PremiumReduction': 'Premium reduction',

  'dividendLabel.toFaceAmount': 'To Face Amount',
  'dividendscale.label': 'Dividend scale assumption',
  'dividendscale.concept': 'Dividend scale',
  'dividendscale.primary': 'Primary dividend scale',
  'dividendscale.alternate': 'Alternate dividend scale',

  'divscale.current': 'Current',
  'divscale.lesshalf': 'Current less 0.50%',
  'divscale.lessone': 'Current less 1.0%',
  'divscale.lessonehalf': 'Current less 1.5%',
  'divscale.lesstwo': 'Current less 2.0%',
  'divscale.lesstwohalf': 'Current less 2.5%',

  'divscale.Performax.lesshalf': 'Current less 0.5%',
  'divscale.Performax.lessone': 'Current less 1.0%',
  'divscale.Performax.lessonehalf': 'Current less 1.5%',
  'divscale.Performax.lesstwo': 'Current less 2.0%',
  'divscale.Performax.lesstwohalf': 'Current less 2.5%',

  'conceptdivscale.primary': 'Primary',
  'conceptdivscale.alternate': 'Alternate',

  'premiumDuration.pay10': 'Pay for 10 years',
  'premiumDuration.pay20': 'Pay for 20 years',
  'premiumDuration.Pay90': 'Pay to age 90',
  'premiumDuration.Pay100': 'Pay to age 100',

  'costDuration.pay100': 'To age 100',
  'costDuration.pay15': 'For 15 years',

  'pcOption.pui': 'Paid-up insurance',
  'pcOption.accumulatedInterest': 'Accumulation Account',

  'vitality.na': 'N/A',
  'vitality.platinum': 'Platinum',
  'vitality.gold': 'Gold',
  'vitality.silver': 'Silver',
  'vitality.bronze': 'Bronze',
  'vitality.remove': 'Remove',
  'vitality.year': 'Year',
  'vitality.age': 'Age',
  'vitality.status': 'Vitality Status',

  'party.firstName': 'First name',
  'party.first': 'First',
  'party.lastName': 'Last name',
  'party.last': 'Last',
  'party.dob': 'Date of birth',
  'party.age': 'Age',
  'party.healthstyle': 'Healthstyle (HS)',
  'party.sex': 'Sex',
  'party.smokingstatus': 'Smoking status',

  'coverage.type': 'Coverage type',
  'coverage.planType': 'Plan type',

  'coverage.planType.Performer': 'Performer',
  'coverage.planType.PerformaxI': 'Performax I',
  'coverage.planType.PerformaxII': 'Performax II',
  'coverage.planType.Performax88': 'Performax 88',
  'coverage.planType.Performax90': 'Performax 90',
  'coverage.planType.Performax92': 'Performax 92',
  'coverage.planType.Performax93': 'Performax 93',
  'coverage.planType.Performax94': 'Performax 94',
  'coverage.planType.Performax2003': 'Performax 2003',
  'coverage.planType.SecurityBuilder': 'Security Builder',

  'coverage.par.type': 'Type',
  'coverage.mul.dateOfIllustration': 'Date of Illustration: ',
  'coverage.mul.policyDate': 'Policy Date: ',
  'coverage.mul.deathBenefitType': 'Death benefit type',
  'coverage.mul.deathTypes.facePlus': 'Face plus',
  'coverage.mul.deathTypes.facePlusSummary': 'Face plus death benefit',
  'coverage.mul.deathTypes.level': 'Level',
  'coverage.mul.deathTypes.levelSummary': 'Level death benefit',
  'coverage.mul.deathTypes.accValEachDeath': 'Account value on each death',
  'coverage.mul.AVDeathPercentage': '% AV on first death',
  'coverage.mul.coiType': 'Cost of insurance (COI) type',
  'coverage.mul.coiTypes.levelToAge100': 'Level COI to age 100',
  'coverage.mul.coiTypes.levelFor10Years': 'Level COI for 10 years',
  'coverage.mul.coiTypes.levelFor20Years': 'Level COI for 20 years',
  'coverage.mul.coiTypes.yrtToAge8520Year': 'YRT to age 85/20 years',
  'coverage.mul.coiTypes.yrtToAge85': 'YRT to age 85',
  'coverage.mul.coiTypes.yrtToAge100': 'YRT to age 100',
  'coverage.mul.coiSwitch': 'Switch to Level COI',
  'coverage.mul.coiSwitchSummary': 'Switch to level COI at year',
  'coverage.mul.coi.atYear': 'At year',
  'coverage.mul.insurTypes.specified': 'Specified',
  'coverage.mul.insurTypes.minLevel': 'Minimum level',
  'coverage.mul.insurTypes.maxLevel': 'Maximum level',
  'coverage.option': 'Coverage option',
  'coverage.option.termOptionPlus': 'Term Option Plus',
  'coverage.amount': 'Coverage Amount',
  'coverage.label': 'COVERAGE',
  'coverage.frequency': 'Premium frequency',
  'coverage.vitality': 'Vitality Status{superscript}',
  'coverage.vitality.nosuperscript': 'Vitality Status',
  'coverage.with': 'With',
  'coverage.vitalityPlus': 'Vitality Plus',
  'coverage.changeToBronze': 'Change to Bronze after age',
  'coverage.fromyear': 'From Year',
  'coverage.toyear': 'To Year',
  'coverage.coverageAmount': 'Coverage amount',
  'coverage.par.vitalityPlus.learnmore':
    'Manulife Par with <b><i>Vitality Plus</i></b> is only available for Single-life coverage.',
  'coverage.par.inforce.vitalityStatus.info': 'Vitality Status as of the last policy anniversary. You may choose a different Vitality Status for illustration purposes.',
  'coverage.mul.vitalityPlus.learnmore':
    'Manulife UL with <b><i>Vitality Plus</i></b> is only available for Single-life coverage.',
  'coverage.mul.vitalityPlus.learnmore2':
    '<b><i>Vitality Plus</i></b> is not available if coverage type is Joint last-to-die.',
  'coverage.mul.vitalityStatus.learnmore.row-1': 'Vitality savings for permanent insurance and Term insurance riders:',
  'coverage.mul.vitalityStatus.learnmore.row-2':
    'The 1st year minimum deposit reflects savings of 10%.  After the 1st year, as the insured person earns their Vitality Status each year, the savings may change.',
  'coverage.mul.vitalityStatus.learnmore.row-3':
    'The illustrated default is Gold starting in the 2nd year which you can change. Starting in the 3rd year, you can illustrate a change to Bronze status.',
  'coverage.mul.vitalityStatus.learnmore.row-4': 'For guidance on which Vitality Status to illustrate, refer to ',
  'coverage.mul.vitalityStatus.learnmore.healthyLiving': 'Manulife <i>Vitality</i> Rewards and Status Simulator',
  'coverage.mul.vitalityStatus.learnmore.column1-1': 'Platinum status:',
  'coverage.mul.vitalityStatus.learnmore.column1-2': '<b>15%</b>',
  'coverage.mul.vitalityStatus.learnmore.column2-1': 'Gold status:',
  'coverage.mul.vitalityStatus.learnmore.column2-2': '<b>10%</b>',
  'coverage.mul.vitalityStatus.learnmore.column3-1': 'Silver status:',
  'coverage.mul.vitalityStatus.learnmore.column3-2': '<b>2%</b>',
  'coverage.mul.vitalityStatus.learnmore.column4-1': 'Bronze status:',
  'coverage.mul.vitalityStatus.learnmore.column4-2': '<b>0%</b>',
  'coverage.ft.vitalityPlus.learnmore':
    'Family Term with <b><i>Vitality Plus</i></b> is only available for Single-life coverage.',
  'coverage.ft.vitalityPlus.learnmore2':
    '<b><i>Vitality Plus</i></b> is not available if coverage is Joint or if illustrating coverage on more than one client.',
  'coverage.ft.vitalityStatus.learnmore.row-1':
    'Vitality savings for insurance coverage during the premium adjustment period:',
  'coverage.ft.vitalityStatus.learnmore.row-2': 'The initial premium reflects savings of 10% in the 1st year.',
  'coverage.ft.vitalityStatus.learnmore.row-2-2':
    'Premiums can change every year – higher or lower – during the premium adjustment period depending on the insured person’s Vitality Status.',
  'coverage.ft.vitalityStatus.learnmore.row-3': 'The illustrated default is Gold which you can customize.',
  'coverage.par.coverageAmount': 'Amount of insurance',

  'coverage.par.coverageAmount.learnmore': 'Coverage solves',
  'coverage.par.coverageAmount.learnmore.header1': 'Solve name',
  'coverage.par.coverageAmount.learnmore.header2': 'Description',
  'coverage.par.coverageAmount.learnmore.header3': 'Key points',
  'coverage.par.coverageAmount.learnmore.column1-1': 'Specified',
  'coverage.par.coverageAmount.learnmore.column1-2': 'Amount of insurance is known.',
  'coverage.par.coverageAmount.learnmore.column1-3': '',
  'coverage.par.coverageAmount.learnmore.column2-1': 'Maximum level',
  'coverage.par.coverageAmount.learnmore.column2-2':
    'For a specified deposit amount and duration, the solve provides the maximum level amount of insurance.',
  'coverage.par.coverageAmount.learnmore.column2-3-1': 'Rider costs are included in the solve.',
  'coverage.par.coverageAmount.learnmore.column2-3-2': 'An additional 1ˢᵗ year deposit cannot be selected.',
  'coverage.par.coverageAmount.learnmore.column2-3-3': 'Withdrawals cannot be selected.',
  'coverage.par.coverageAmount.learnmore.column2-3-4': 'Limited side account usage to keep the policy in force.',
  'coverage.par.coverageAmount.learnmore.column2-3-5':
    'An error will display if the specified deposit results in an amount of insurance outside the product limits.',
  'coverage.par.coverageAmount.learnmore.column3-1': 'Minimum level',
  'coverage.par.coverageAmount.learnmore.column3-2':
    'For a specified deposit amount and duration, the solve provides the minimum level amount of insurance.',
  'coverage.par.coverageAmount.learnmore.column3-3-1': 'Rider costs are included in the solve.',
  'coverage.par.coverageAmount.learnmore.column3-3-2': 'An additional 1ˢᵗ year deposit cannot be selected.',
  'coverage.par.coverageAmount.learnmore.column3-3-3': 'Withdrawals cannot be selected.',
  'coverage.par.coverageAmount.learnmore.column3-3-4': 'The side account is used.',
  'coverage.par.coverageAmount.learnmore.column3-3-5':
    'An error will display if the specified deposit results in an amount of insurance outside the product limits.',
  'coverage.solveForCoverage': 'Solve by entering a premium',
  'coverage.includeMaxDepositOption': 'Include deposit option?',
  'coverage.solveForCoverage.warning':
    'Coverage solve is only available for single coverage. The solve amount has been changed to a specified coverage amount.',
  'coverage.premiumAmount': 'Premium amount',
  'coverage.premiumduration': 'Premium duration',
  'coverage.costduration': 'Cost duration',
  'coverage.divoption': 'Dividend option',
  'coverage.pcoption': 'Performance credit option',
  'coverage.toFaceAmount': 'TO Face Amount',
  'coverage.topFaceAmount': 'TOP Face Amount',
  'coverage.blank': ' ',
  'coverage.switchtocash': 'Switch to Cash Dividends',
  'coverage.switchtocashyear': 'Year',
  'coverage.marginaltaxrate': 'Marginal tax rate',
  'coverage.policyInterestRate': 'Policy interest rate',
  'coverage.sideAccountInterestRate': 'Side account interest rate',
  'coverage.performanceCreditRate': 'Performance credit rate',
  'coverage.altPerformanceCreditRate': 'Alternate performance credit rate',
  'coverage.performanceCreditRateURL':
    'https://advisor.manulife.ca/content/dam/manulife-advisor-portal/documents/en/marketing-materials/insurance/performax-gold/insurance-performax-gold-performance-credit-ratee.pdf',
  'coverage.specialQuote': 'Special quote',

  'coverage.pMax.costDuration': 'Costs to age 99',
  'coverage.pMax.bandSix': 'Band 6',

  'specialQuote.opt.none': 'None',
  'specialQuote.opt.S1': 'Option 1 (S1)',
  'specialQuote.opt.H1': 'Head Office (H1)',
  'specialQuote.opt.S2': 'Option 2 (S2)',

  'client.insured': 'Insured',

  'rider.child': 'Child',
  'rider.payor': 'Payor',
  'rider.tdw': 'Total disability waiver (TDW)',
  'rider.tdw.payor': 'Total disability waiver on the payor (TDW)',
  'rider.bvp': 'Business value protector (BVP)',
  'rider.gio': 'Guaranteed insurability option (GIO)',
  'rider.gir': 'GIR',
  'rider.adnd': 'Accidental death and dismemberment (AD&D)',
  'rider.adb': 'Accidental death benefit (ADB)',
  'rider.cpr': 'Child protection rider (CPR)',
  'rider.pwdd': 'Payor waiver on death or disability (PWDD)',
  'rider.rop': 'Return of premium (ROP)',

  'rider.tdw.abbr': 'TDW',
  'rider.tdw.payor.abbr': 'TDW on the payor',
  'rider.bvp.abbr': 'BVP',
  'rider.gio.abbr': 'GIO',
  'rider.gir.abbr': 'GIR',
  'rider.adnd.abbr': 'AD&D',
  'rider.adb.abbr': 'ADB',
  'rider.cpr.abbr': 'CPR',
  'rider.addchild': 'Add a child',
  'rider.remove': 'Remove child',
  'rider.unselect.all': 'Unselect all',
  'rider.addtermrider': 'Add a term rider',
  'rider.title': 'Term rider',
  'rider.tor.title': 'Term insurance rider - other (TOR)',
  'rider.spr.title': 'Spousal protection rider (SPR)',
  'rider.t05': '5 year',
  'rider.t05.abbr': 'T05',
  'rider.t10': '10 year',
  'rider.t10.abbr': 'T10',
  'rider.t20': '20 year',
  'rider.t20.abbr': 'T20',
  'rider.t65': 'Level (T65)',
  'rider.t65.abbr': 'T65',
  'pmax.rider.t05.abbr': 'TIR-05',
  'pmax.rider.t10.abbr': 'TIR-10',
  'pmax.rider.t65.abbr': 'TIR-65',
  'pmax.rider.tor.t05.abbr': 'TOR-05',
  'pmax.rider.tor.t10.abbr': 'TOR-10',
  'pmax.rider.tor.t65.abbr': 'TOR-65',
  'pmax.rider.spr.t05.abbr': 'SPR-05',
  'pmax.rider.spr.t10.abbr': 'SPR-10',
  'pmax.rider.spr.t65.abbr': 'SPR-65',
  'rider.duration': 'Duration (years)',
  'riders.label': 'RIDERS',
  'rider.pwdd.abbr': 'PWDD',
  'rider.rop.abbr': 'ROP',

  'topbar.clientcoverage': 'Client & Coverage',
  'topbar.spreadsheet': 'Spreadsheet',
  'topbar.inforceRequestForm': 'Special request form',
  'topbar.help': 'Need help?',
  'topbar.help.link': 'https://advisor.manulife.ca/advisors/private/private-tools/manulife-illustrations.html',
  'topbar.help.link.par':
    'https://advisor.manulife.ca/advisors/private/private-tools/manulife-illustrations.html#inforce-illustrations',
  'topbar.concepts': 'Concepts',
  'topbar.print': 'Print page',

  'profile.businessCard.select': 'Select business card',
  'profile.businessCard': 'Business Card',
  'profile.businessCard.tooltip.text1': 'Business cards are used in the Report footer.',
  'profile.businessCard.tooltip.text2': 'To select a business card:',
  'profile.businessCard.tooltip.text3': '○ Pick an advisor from the drop-down list; or',
  'profile.businessCard.tooltip.text4': '○ Type an advisor name in the Search field. ',
  'profile.businessCard.tooltip.text5': 'To add, edit, or delete business cards:',
  'profile.businessCard.tooltip.text6': '1. Click the <b>Reports</b> button.',
  'profile.businessCard.tooltip.text7': '2. Select <b>Customize</b>.',
  'profile.businessCard.tooltip.text8': '3. Make any changes in the Manage business cards section.',

  'report.addNew': 'Add new',
  'report.back': 'Back to Illustrations',
  'report.options': 'Report Options',
  'report.language': 'Report language',
  'report.layout': 'Report type',
  'report.layout.allyears': 'All years',
  'report.layout.condensed': 'Condensed',
  'report.preparedfor': 'Prepared for',
  'report.label.pages': 'Report pages',
  'report.label.footer': 'Manage business cards',
  'report.businessCard': 'Display business card information in the footer',
  'report.businessCard.required': 'Required',
  'report.businessCard.delete.title': 'Delete business card',
  'report.businessCard.delete.message': 'Are you sure you want to delete this business card?',
  'report.businessCard.tooltip1': 'When selected, this information is used for the illustration reports only.',
  'report.businessCard.tooltip2': 'To select a business card when “Display business card information” is checked:',
  'report.businessCard.tooltip3': '○ Pick an advisor from the drop-down list; or',
  'report.businessCard.tooltip4': '○ Type an advisor name in the Search field.',
  'report.selectall': 'Select all',
  'report.underwriting': 'Underwriting requirements',
  'report.productpage': 'Product page',
  'report.commission': 'Commission summary',
  'report.standalone': 'Standalone Advisor Reports',
  'report.option.cpd': 'Coverage and premium details',
  'report.option.cd': 'Coverage details',
  'report.option.irr': 'Internal Rate of Return (IRR) values',
  'report.option.pd': 'Policy details',
  'report.option.pod': 'Premium offset details',
  'report.option.pos': 'Premium offset summary',
  'report.option.ps': 'Product summary and important illustration notes',
  'report.option.rp': 'Rider premiums',
  'report.option.td': 'Tax values',
  'report.option.tcop': 'Tax values for corporate owned policies',
  'report.option.td.PG': 'Tax values',
  'report.option.tpop': 'Tax values for personally owned policies',
  'report.option.tpcd': 'Tax values for policies with Cash Dividends',
  'report.option.ur': 'Underwriting requirements',
  'report.option.vpgnb': 'Premiums based on Gold and Bronze Vitality Status{superscript}',
  'report.option.vp': 'Vitality points{superscript}',
  'report.option.vpc': 'Vitality Status{superscript} premium comparison',
  [`report.link${eProduct.FT}`]:
    'https://advisor.manulife.ca/content/dam/manulife-advisor-portal/documents/en/forms/insurance/new-business-and-underwriting/insurance-family-term-product-page-nn0799e.pdf',
  [`report.link${eProduct.BT}`]:
    'https://www.manulife.ca/content/dam/manulife-advisor-portal/documents/en/forms/insurance/new-business-and-underwriting/manulife-insurance-family-and-business-term-product-page-nn0799e.pdf',
  [`report.link${eProduct.LC}`]:
    'https://advisor.manulife.ca/content/dam/manulife-advisor-portal/documents/en/forms/insurance/new-business-and-underwriting/manulife-insurance-lifecheque-product-page-nn0949e.pdf',
  [`report.link${eProduct.FTV}`]: productPageURLFTVEn,
  [`report.link${eProduct.PAR}`]:
    'https://advisor.manulife.ca/content/dam/manulife-advisor-portal/documents/en/forms/insurance/new-business-and-underwriting/manulife-insurance-manulife-par-product-page-nn1675e.pdf',
  [`report.link${eProduct.MUL}`]:
    'https://advisor.manulife.ca/content/dam/manulife-advisor-portal/documents/en/forms/insurance/new-business-and-underwriting/manulife-insurance-manulife-ul-product-page-nn1636e.pdf',
  [`report.underwrite.link${eProduct.LC}`]:
    'https://advisor.manulife.ca/content/dam/manulife-advisor-portal/documents/en/marketing-materials/insurance/new-business-and-underwriting/private/manulife-insurance-critical-illness-insurance-evidence-of-insurability-requirements-mp1033146e.pdf',
  [`report.link${eProduct.MUL + 'VP'}`]:
    'https://advisor.manulife.ca/content/dam/manulife-advisor-portal/documents/en/forms/insurance/new-business-and-underwriting/insurance-manulife-ul-with-vitality-plus-product-page-nn1707e.pdf',
  [`report.link${eProduct.FT + 'VP'}`]:
    'https://advisor.manulife.ca/content/dam/manulife-advisor-portal/documents/en/forms/insurance/new-business-and-underwriting/insurance-family-term-with-vitality-plus-product-page-nn1656e.pdf',
  [`report.link${eProduct.PAR + 'VP'}`]:
    'https://advisor.manulife.ca/content/dam/manulife-advisor-portal/documents/en/forms/insurance/new-business-and-underwriting/insurance-manulife-par-with-vitality-plus-product-page-nn1706e.pdf',
  [`report.link${eProduct.PG}`]:
    'https://advisor.manulife.ca/content/dam/manulife-advisor-portal/documents/en/forms/insurance/new-business-and-underwriting/manulife-insurance-performax-gold-product-page-nn1547e.pdf',
  'report.viewreportmessage': 'Click open to view the PDF report in a new tab.',
  'report.dialog.startNewAppTitle': 'Start new E-Application',
  'report.dialog.startNewAppMsg': 'Click “open” to start a new application and import your illustration data',
  'report.dialog.error.dob': 'To start a new E-Application, add date of birth for clients and any child riders.',
  'report.dialog.error.name': 'To start a new E-Application, add client names.',
  'report.dialog.businessCard.title.add': 'Add a new business card',
  'report.dialog.businessCard.title.edit': 'Edit business card',

  'advisor.title': 'Advisor Profile',

  'scenarioPanel.dda.startNewApp': 'Start an E-Application',

  'summary.title': 'Summary',
  'summary.solve': 'Solve',
  'summary.esla': 'Joint age:',
  'summary.esla.mul': 'Joint age:',
  'summary.cost': 'Cost',
  'summary.coverage': 'COVERAGE',
  'summary.premium': 'Premium',
  'summary.totalpremium': 'Total premium',
  'summary.refresh': 'Click recalculate',
  'summary.residence': 'Residence',
  'summary.residence.error.notselected': "Select the policy owner's residence",
  'summary.firstYearMinDeposit': '1st year minimum deposit',
  'summary.firstYearMaxDeposit': '1st year maximum deposit',
  'summary.currentYearMinDeposit': 'Current year minimum deposit',
  'summary.currentYearMaxDeposit': 'Current year maximum deposit',
  'summary.bronzeMinDeposit': 'Bronze minimum deposit',
  'summary.depositoption': 'Deposit option',
  'summary.depositoption.monthly': 'Monthly',
  'summary.depositoption.annually': 'Annually',
  'summary.depositoption.MUL.monthly': 'Monthly',
  'summary.depositoption.MUL.annually': 'Annually',
  'summary.depositoption.MUL.annually.small': 'annually ',
  'summary.depositoption.MUL.monthly.small': 'monthly ',
  'summary.depositoption.for': ' for ',
  'summary.depositoption.sameasbase': 'Same as policy cost duration',
  'summary.mul.depositoption': 'Deposits',
  'summary.ridersummary': 'RIDERS',
  'summary.policyfee': 'Policy fee',
  'summary.vitalitycharge': 'Vitality charge',
  'summary.vitalitypremium': 'premium',
  'summary.vitalitypremium2': 'Vitality premium',
  'summary.error.vitality':
    'Only Single life is available on Family Term with {vitality}. Change the coverage type to Single life.',
  'summary.additionalcost': 'ADDITIONAL COSTS',
  'summary.additionalDepositFirstYear': 'Additional 1st year deposit',
  'summary.illustrationrates': 'Illustration rates',
  'summary.dividendscale': 'Dividend scale',
  'summary.primaryrate': 'Primary',
  'summary.payments': 'PAYMENTS',
  'summary.premiumoffset': 'Premium offset',
  'summary.premiumoffsetyear.primary': 'Primary: Starting at year XX',
  'summary.premiumoffsetyear.alternate': 'Alternate: Starting at year XX',
  'summary.alternaterate': 'Alternate: ',
  'summary.alternate': 'Alternate',
  'summary.withdrawal': 'Withdrawals',
  'summary.withdrawal.for': 'for ',
  'summary.status': 'The summary region has been updated.',
  'summary.coveragecost': 'Current coverage cost*',
  'summary.coverageeffective': 'Coverage effective date',
  'summary.ageoncoverageeffective': 'Age on coverage effective date',
  'summary.coveragedisclaimer':
    '*Coverage cost only. Does not include policy fee, ratings, rider cost, or additional coverage.',
  'summary.nextrenewaldate': 'Next renewal date',
  'summary.nextrenewalage': 'Age on next renewal',
  'summary.coveragecostnext': 'Coverage cost* on next renewal',
  'summary.defaultdate': 'Mmm dd, yyyy',
  'summary.bronzeMinimum.tooltip.text':
    'If your client doesn’t achieve a Vitality Status above Bronze, this is their minimum annual deposit in year 2. For Level COI only.',
  'summary.with': 'with',
  'summary.currentYearBasePayment': 'Current year base payment',
  'summary.currentYearMaxPayment': 'Current year maximum payment',
  'summary.pgold.plannedBasePayment': 'Planned base payment',
  'summary.pgold.additionalPayments': 'Additional payments',
  'summary.premiumfrequency.sameasbase': 'same as policy cost duration',
  'summary.premiumfrequency.earliest': 'for XX years',
  'summary.premiumfrequency.startingat': 'for XX years',
  'summary.performanceCreditRate.primary': 'Primary',
  'summary.performanceCreditRate.alternate': 'Alternate',
  'summary.earlycashvalueenhancer': 'Early cash value enhancer',
  'summary.pgold.costs': 'Costs ',
  'summary.premiumoffset.PG': 'Payment offset',
  'summary.premiumoffset.PMax': 'Premium offset',
  'summary.inforce.currentyear': 'Current policy year',
  'summary.status.calculationDelay': 'Your scenario is taking longer than expected to calculate, please wait.',

  'coveragecompare.compare': 'Coverage compare',
  'coveragecompare.frequency.Monthly': 'Per month',
  'coveragecompare.frequency.Annually': 'Per year',
  'coveragecompare.term10': 'T10',
  'coveragecompare.term20': 'T20',
  'coveragecompare.disclaimer': 'Assumes Gold status',

  'basedOnAmount.label': 'Based on amount',
  'payments.label': 'PAYMENTS',
  'payments.title': 'Payments',
  'payments.premium': 'Premium frequency',
  'payments.premiumoffset': 'Premium offset',
  'payments.premiumoffsetyear': 'Year',
  'payments.frequency': 'Frequency',
  'payments.plannedbaselabel': 'Planned base payment:',
  'payments.fromyear': 'From year',
  'payments.toyear': 'To year',

  'premiumoffset.no': 'No',
  'premiumoffset.earliest': 'Earliest offset',
  'premiumoffset.startingatyear': 'Starting at',
  'premiumoffset.paymentstartingat': 'Payment offset starting at',
  'premiumoffset.sameaspolicy': 'Same as policy cost duration',
  'premiumoffset.inforce': 'On payment offset',
  'premiumoffset.inforce.yes': 'Yes - On premium offset',
  'premiumoffset.inforce.no': 'No - Premium paying',
  'premiumoffset.paymentcoverpolicycosts': 'Payment to cover policy costs',
  'premiumoffset.premiumstartingat': 'Premium offset starting at',
  'premiumoffset.onpremiumoffsetindicator': 'On premium offset',
  'premiumoffset.premiumholiday': 'Premium holiday',

  'rating.title': 'Insurance Ratings',
  'rating.perm.rate': 'Permanent rating',
  'rating.temp.rate': 'Temporary rating',

  'spreadsheet.exportBtn': 'Export to Excel',
  'spreadsheet.PolicyYear': 'Year',
  'spreadsheet.PrimaryAttainedAge': 'Age',
  'spreadsheet.yearAge': 'Year | Age',
  'spreadsheet.FaceAmount': 'Total coverage',
  'spreadsheet.Monthly.BasePremium': 'Total monthly insurance premium',
  'spreadsheet.Annually.BasePremium': 'Total annual insurance premium',
  'spreadsheet.Monthly.RiderPremium': 'Total monthly rider premium',
  'spreadsheet.Annually.RiderPremium': 'Total annual rider premium',
  'spreadsheet.Monthly.ModalScheduledPremium': 'Total monthly plan premium',
  'spreadsheet.Annually.ModalScheduledPremium': 'Total annual plan premium',
  'spreadsheet.ScheduledPremium': 'Total annual plan premium',
  'spreadsheet.Monthly.TdwDeduction': 'Total monthly TDW premium',
  'spreadsheet.Annually.TdwDeduction': 'Total annual TDW premium',
  'spreadsheet.Monthly.BvpDeduction': 'Total monthly BVP premium',
  'spreadsheet.Annually.BvpDeduction': 'Total annual BVP premium',
  'spreadsheet.Monthly.CprDeduction': 'Total monthly CPR premium',
  'spreadsheet.Annually.CprDeduction': 'Total annual CPR premium',
  'spreadsheet.Monthly.AdbDeduction': 'Total monthly AD&D premium',
  'spreadsheet.Annually.AdbDeduction': 'Total annual AD&D premium',
  'spreadsheet.Monthly.GioDeduction': 'Total monthly GIO premium',
  'spreadsheet.Annually.GioDeduction': 'Total annual GIO premium',
  'spreadsheet.TotalAnnualPolicyCost': 'Total annual plan premium',
  'spreadsheet.ExpenseDeduction': 'Policy fee',
  'spreadsheet.VitalityModalFee': 'Vitality fee',
  'spreadsheet.AdjustedCostBasis': 'Adjusted cost basis',
  'spreadsheet.NetCostOfPureInsurance': 'Net cost of pure insurance',
  'spreadsheet.CdaCredit': 'Capital dividend account credit',
  'spreadsheet.VitalityStatus': 'Vitality status',
  'spreadsheet.TotalT10FaceAmount': 'Total Term-10 coverage',
  'spreadsheet.Monthly.TotalT10ModalPremium': 'Total monthly premium for Term-10',
  'spreadsheet.Annually.TotalT10ModalPremium': 'Total annual premium for Term-10',
  'spreadsheet.TotalT20FaceAmount': 'Total Term-20 coverage',
  'spreadsheet.Monthly.TotalT20ModalPremium': 'Total monthly premium for Term-20',
  'spreadsheet.Annually.TotalT20ModalPremium': 'Total annual premium for Term-20',
  'spreadsheet.TotalT65FaceAmount': 'Total Term-65 coverage',
  'spreadsheet.Monthly.TotalT65ModalPremium': 'Total monthly premium for Term-65',
  'spreadsheet.Annually.TotalT65ModalPremium': 'Total annual premium for Term-65',
  'spreadsheet.TotalTermLifeFaceAmount': 'Total Term-Life coverage',
  'spreadsheet.Monthly.TotalTermLifeModalPremium': 'Total monthly premium for Term-Life',
  'spreadsheet.Annually.TotalTermLifeModalPremium': 'Total annual premium for Term-Life',
  'spreadsheet.TotalPlannedPremium': 'Total annual planned premium',

  'spreadsheet.Monthly.GuaranteedPremium': 'Guaranteed annualized premium',
  'spreadsheet.Annual.GuaranteedPremium': 'Guaranteed annual premium',
  'spreadsheet.GuaranteedDeathBenefit': 'Guaranteed death benefit',
  'spreadsheet.GuaranteedInsuranceAmount': 'Guaranteed amount of insurance',
  'spreadsheet.GuaranteedCashValue': 'Guaranteed cash value',
  'spreadsheet.IllustatedDepositPayment': 'Illustrated deposit option payment',
  'spreadsheet.MaxAnnualDepositPayment': 'Maximum annual deposit option limit',
  'spreadsheet.AnnualDividend': 'Annual dividend (Primary)',
  'spreadsheet.PaidUpInsurance': 'Paid up insurance (Primary)',
  'spreadsheet.DepositOptionInsurance': 'Deposit option insurance (Primary)',
  'spreadsheet.DividendCash': 'Dividend paid as cash (Primary)',
  'spreadsheet.IRRDeathBenefit': 'IRR on death benefit % (Primary)',
  'spreadsheet.IRRTotalCashValue': 'IRR on total cash value % (Primary)',
  'spreadsheet.AnnualDividendAlternate': 'Annual dividend (Alternate)',
  'spreadsheet.DividendCashAlternate': 'Dividend paid as cash (Alternate)',
  'spreadsheet.PaidUpInsuranceAlternate': 'Paid up insurance (Alternate)',
  'spreadsheet.DepositOptionInsuranceAlternate': 'Deposit option insurance (Alternate)',
  'spreadsheet.IRRDeathBenefitAlternate': 'IRR on death benefit % (Alternate)',
  'spreadsheet.IRRTotalCashValueAlternate': 'IRR on total cash value % (Alternate)',
  'spreadsheet.AdjustedCostBasisAlternate': 'Adjusted cost basis (Alternate)',
  'spreadsheet.NetCostOfPureInsuranceAlternate': 'Net cost of pure insurance (Alternate)',
  'spreadsheet.CdaCreditAlternate': 'Capital dividend account credit (Alternate)',
  'spreadsheet.TermRiderInsurance': 'Term rider amount of insurance - 1 -',
  'spreadsheet.TDWRiderPremium': 'Term rider premium - 1 -',
  'spreadsheet.GIORiderPremium': 'GIO rider premium for [Client Name]',
  'spreadsheet.PayorWaverRider': 'Payor waiver rider cost',
  'spreadsheet.ChildProtectionRider': 'Child protection rider premium',
  'spreadsheet.RequestedBeforeCash': 'Requested before-tax withdrawals',
  'spreadsheet.Withdrawal': 'Withdrawal',
  'spreadsheet.TaxableWithdrawal': 'Taxable withdrawal',
  'spreadsheet.NetWithdrawal': 'Net withdrawal',
  'spreadsheet.TaxableCancellationValue': 'Taxable portion of cancellation cash value (Primary)',
  'spreadsheet.TaxableCashDividend': 'Taxable portion of cash dividend (Primary)',
  'spreadsheet.TaxOnCancellationValue': 'Tax on cancellation cash value (Primary)',
  'spreadsheet.CancellationCashValue': 'Cancellation cash value after tax (Primary)',
  'spreadsheet.TaxableCancellationValueAlternate': 'Taxable portion of cancellation cash value (Alternate)',
  'spreadsheet.TaxableCashDividendAlternate': 'Taxable portion of cash dividend (Alternate)',
  'spreadsheet.TaxOnCancellationValueAlternate': 'Tax on cancellation cash value (Alternate)',
  'spreadsheet.CancellationCashValueAlternate': 'Cancellation cash value after tax (Alternate) ',
  'spreadsheet.MarginalTaxRate': 'Marginal tax rate (%)',
  'spreadsheet.Refund': 'Refund to policy owner',
  'spreadsheet.AdjustedCostBasisPrimary': 'Adjusted cost basis (Primary)',
  'spreadsheet.NetCostOfPureInsurancePrimary': 'Net cost of pure insurance (Primary)',
  'spreadsheet.CdaCreditPrimary': 'Capital dividend account credit (Primary)',
  'spreadsheet.TaxOnWithdrawal': 'Tax on withdrawal',
  'spreadsheet.recalculate': 'Recalculate Spreadsheet',
  'spreadsheet.recalculate.sr': 'Click Enter',
  'spreadsheet.error': 'Requested DO payment exceeds the annual limit',
  'spreadsheet.filldown.sr': 'Click Shift + Enter',
  'spreadsheet.fullscreen': 'Full screen',
  'spreadsheet.exitfullscreen': 'Exit Full screen',

  'mycases.illustration.btn': 'New Illustration',
  'mycases.table.header.Illustration': 'Illustration',
  'mycases.table.header.Illustration.formfield': 'Type illustration name to search',
  'mycases.table.header.Illustration.sortbutton': 'Sort illustration by alphabetical order',
  'mycases.table.header.sortAscending': 'Sorted in ascending order',
  'mycases.table.header.sortDescending': 'Sorted in descending order',
  'mycases.table.header.clientname': 'Client Name',
  'mycases.table.header.product': 'Product',
  'mycases.table.header.concept': 'Concept',
  'mycases.table.header.datesaved': 'Date Saved',
  'mycases.table.header.datesaved.formfield': 'Type date saved to search',
  'mycases.table.header.datesaved.sortbutton': 'Sort illustration by date saved',
  'mycases.table.header.action': 'Action',
  'mycases.table.header.action.opencase': 'Open illustration',
  'mycases.table.header.action.deletecase': 'Delete illustration',
  'mycases.dialog.delete.illustrations': 'Are you sure you want to delete the selected illustration(s)?',
  'mycases.dialog.delete.applications': 'Are you sure you want to delete the selected application(s)?',
  'mycases.deleteIllustration': 'Delete illustration',
  'mycases.deleteApplication': 'Delete application',
  'mycases.deleteIllustrationNotification': 'Illustration(s) have been deleted successfully.',
  'mycases.deleteApplicationNotification': 'Application(s) have been deleted successfully.',
  'mycases.dialog.status.updatedToFT': 'Your illustration has been updated to Family Term',
  'mycases.dialog.status.andFT': ' and Family Term',
  'mycases.dialog.status.withFTV': ' with Vitality Plus',

  'footer.quicklinks': 'Quick Links',
  'footer.advisorGuide': 'Advisor Guide',
  'footer.copyright': 'The Manufacturers Life Insurance Company',
  'footer.termms': 'Terms & Conditions',
  'footer.termsLink': 'http://mmanulife.ca',
  'footer.legal': 'Legal',
  'footer.legalLink': 'https://www.manulife.com/en/legal.html',
  'footer.privacy': 'Privacy Policy',
  'footer.privacyLink': 'https://www.manulife.ca/privacy-policies.html',
  'footer.accessibility': 'Accessibility',
  'footer.accessibilityLink': 'https://www.manulife.ca/about-us/accessibility.html',
  'footer.manulife': 'Manulife.ca',
  'footer.manulife.caLink': 'https://www.manulife.ca',
  'footer.career': 'Careers',
  'footer.career.hyperlink': 'https://www.manulife.com/en/careers/why-join-us.html',
  'help.coverageSolve': 'Coverage solve',
  'help.comingSoon': 'Coming soon:',
  'help.solveForCoverage':
    'The {coverageSolve} option is available when you illustrate one coverage. Enter a premium amount and the illustration tool deducts the cost of any riders and fees and calculates the amount of insurance the client can purchase with the remaining amount. Minimum coverage rules apply.',
  'help.solveForCoverage.Par':
    'Enter a premium amount and the illustration tool deducts the cost of any riders and calculates the amount of insurance that can be purchased. Product minimum applies.',
  'help.customizeVitality1': 'To customize a Vitality Status',
  'help.customizeVitality2': ', click ',
  'help.customizeVitality3': 'Customize',
  'help.customizeVitality4': 'You can modify the Vitality Status by year beginning in year 2.  Choosing ',
  'help.customizeVitality5': 'Fill down',
  'help.customizeVitality6': ' will update all proceeding fields with the selected option.',
  'help.customizeVitality7': 'Coverage option',
  'help.customizeVitality8': " or client's ",
  'help.customizeVitality9': 'Age',
  'help.customizeVitality10': ' is changed.',
  'help.healthstyle':
    'If your client is a non-smoker we recommend that you illustrate,  present, and collect payment for Healthstyle 3. We only offer Healthstyles 1 and 2 for specific ages and amounts. We must still complete underwriting to determine eligibility.',
  'help.healthstyle1': 'You can add a rating if your client(s) are Healthstyles 3, 4, or 5.',
  'help.healthstyle2': 'Healthstyle 1:  Best non-smoker risk',
  'help.healthstyle3': 'Healthstyle 2: Better than standard non-smoker risk',
  'help.healthstyle4': 'Healthstyle 3: Standard non-smoker risk',
  'help.healthstyle5': 'Healthstyle 4: User of tobacco or nicotine products other than cigarette or e-cigarettes',
  'help.healthstyle6': 'Healthstyle 5: Cigarette or e-cigarette smoker',
  'help.levelmax':
    "Level maximum DO solve for the Payable for 10 years premium duration only works if Term Rider is selected. Otherwise, select 'Specified' and use the Custom button. Deposit Option room may not be available in the first 10 years. DO payments can be made after the end of the premium duration.",
  'help.smokingstatus': 'Smoking status help.',
  'help.summary':
    "The summary section gives you an interactive view of the premium.   If you see  an error message icon (x), there is an error. You can’t run an illustration report or access the spreadsheet, and premiums won't update, until you fix the error.",

  'ftrc.calculator.header.label': 'Before you begin:',
  'ftrc.calculator.header.details1A': 'Have the information about the insured ready. You can find this in ',
  'ftrc.calculator.header.details1.linkText': 'My Clients',
  'ftrc.calculator.header.details1.link': 'https://advisor.manulife.ca/myclients',
  'ftrc.calculator.header.details1B': ' or other policy documents.',
  'ftrc.calculator.header.details2':
    'Make sure that the policy has a Term-10 coverage with a coverage effective date between May 1, 2010 and October 4, 2013.',
  'ftrc.calculator.footer.message.1':
    'The Family Term renewal calculator determines the current and renewal costs based on the information you provide. You can enter the same coverage amount the insured has now or a decreased coverage amount. The Current coverage cost in the Summary is based on the coverage amount that you enter in the calculator.',
  'ftrc.calculator.footer.message.2':
    'If you enter a coverage amount that is the same as the contract and if there is a discrepancy between the costs shown in the calculator and the costs in the contract, the values in the contract always apply.  If you enter a decreased coverage amount, the new coverage amount is subject to our current administrative rules.',
  'ftrc.calculator.footer.message.3': 'The amounts shown are not a complete illustration or an offer to insure. ',
  'ftrc.calculator.footer.message.4': 'For Advisor use only.',

  'ftrc.calculatorInput.product': 'Product: Family Term',
  'ftrc.calculatorInput.coverageType': 'Coverage type: Single-life',
  'ftrc.calculatorInput.coverageOption': 'Coverage option: Term-10',
  'ftrc.calculatorInput.coverageEffectiveDate.label': 'Coverage effective date',
  'ftrc.calculatorInput.coverageAmount.label': 'Coverage amount',
  'ftrc.calculatorInput.insuredInformation': 'Insured information',
  'ftrc.calculatorInput.sex.Label': 'Sex',
  'ftrc.calculatorInput.dob.label': 'Date of birth',
  'ftrc.calculatorInput.help.healthstyle': 'This is the insured’s most recent underwritten Healthstyle.',
  'ftrc.calculatorInput.help.coverage.amount':
    'You can enter the current coverage amount or a decreased coverage amount.',
  'ftrc.calculatorInput.help.coverage.effective.date.1':
    'You need the coverage effective date to calculate accurate renewal costs. Check My Clients or policy documents to find this date. The coverage effective date might be different from the policy date.',
  'ftrc.calculatorInput.help.coverage.effective.date.2': 'Example from My Clients:',

  'corporate.IRP': 'Insured Retirement Program - Corporate',
  'corporate.EB': 'Estate Bond - Corporate',
  'corporate.NONE': 'None',
  'concept.personal.IRP': 'Insured Retirement Program - Personal',
  'concept.personal.EB': 'Estate Bond - Personal',

  'concept.title': 'Concepts',
  'concept.tabTitle': 'CONCEPTS',
  'concept.selectConcept': 'Select concept',
  'concept.taxInformation.label': 'Tax information',
  'concept.alternateInvestment.label': 'Alternative investment',
  'concept.corporateIRPStructure': 'Corporate IRP structure',
  'concept.lifeExpectancyAssumption.label': 'Life expectancy assumptions',
  'concept.loaninformation.label': 'Loan information',
  'concept.corporateTaxRate': 'Corporate tax rate',
  'concept.personalDividendTaxRate': 'Personal dividend tax rate',
  'concept.altIntOptInterest': 'Interest',
  'concept.altIntOptDividends': 'Dividends',
  'concept.altIntOptDefCapGains': 'Deferred capital gains',
  'concept.altIntOptRelCapGains': 'Realized capital gains ',
  'concept.altIntBtnGrowth': 'Growth',
  'concept.altIntBtnBalanced': 'Balanced',
  'concept.altIntBtnIncome': 'Income',
  'concept.altIntColOptionAllocation': 'Allocation',
  'concept.altIntColOptionRateOfReturns': 'Rate of return',
  'concept.altIntColOptionAllocationStrategy': 'Allocation strategy',
  'concept.altIntColOptionAllocationError': 'Maximum allowed is 100%',
  'concept.lumpsumAmountLabel': 'Illustrate alternative investment assuming lump sum amount',
  'concept.deathAtAge': 'Death at age',
  'concept.illustrateAdditional': 'Illustrate additional (years)',
  'concept.years': 'years',
  'concept.taxRates': 'Tax Rates',
  'concept.presentdiscountrate': 'Present value discount rate',
  'concept.viewConcept': 'View Concept',
  'concept.description.None': 'Select a concept from the list to view its description',
  'concept.description.CIRP':
    'The Corporate Insured Retirement Program provides life insurance protection and the potential to access accumulating values in the policy on a tax-free basis in the future. The accumulating value of an exempt life insurance policy serves as collateral for a bank loan and provides cash flow in the future. The loan amounts can be used for paying dividends to shareholders, investments, purchasing machinery or equipment, and more.',
  'concept.description.PIRP':
    'The Insured Retirement Program provides life insurance protection and the potential to access accumulating values in the policy on a tax-free basis in the future. The accumulating value of an exempt life insurance policy serves as collateral for a bank loan and provides cash flow in the future. The loan amounts can be used to supplement your retirement income, for investments, for gifts, and more.',
  'concept.description.EstateBond.1':
    'Businesses often invest retained profits or surplus cash in taxable investments. In many cases, the retained profits or surplus cash is invested this way because the business owner either:',
  'concept.description.EstateBond.2': 'doesn’t need more income, or',
  'concept.description.EstateBond.3': 'has a higher marginal tax rate than the business',
  'concept.description.EstateBond.4':
    'The Corporate Estate Bond takes advantage of exempt life insurance and shows how corporate investment dollars can be moved into a tax-sheltered environment. This can reduce the current taxation and maximize the amount available to the estate when the owner dies.',
  'concept.description.PEB.1':
    'Many people invest personal savings in GICs or other taxable options. If you don’t need the investments for your retirement income, you can leave the savings to your heirs.',
  'concept.description.PEB.2':
    'The Estate Bond moves the savings within an exempt life insurance policy. This can reduce the current taxation and maximize the amount available to your heirs when you die.',
  'concept.corporateIRPStructure.borrowingType': 'Borrowing type',
  'concept.corporateIRPStructure.corporateBorrowing': 'Corporate borrowing',
  'concept.corporateIRPStructure.investmentTax': 'Corporate tax rate on investment income',
  'concept.corporateIRPStructure.companyType': 'Company type',
  'corporateIRPStructure.holdingCompany': 'Holding company',
  'corporateIRPStructure.operatingCompany': 'Operating company',
  'concept.corporateIRPStructure.operatingIncomeTax': 'Corporate tax rate on operating income',
  'concept.CSVLoanPercentage': 'CSV loan percentage',
  'concept.bankLoanRate': 'Bank loan rate',
  'concept.baseLoanAmountDuration': 'Base loan amount and duration on',
  'concept.youngestLife': 'Youngest life',
  'concept.loanAmount': 'Loan amount',
  'concept.loanAmount.specified': 'Specified',
  'concept.loanAmount.solveMaximum': 'Solve - Maximum',
  'concept.lifeExpectancyAge': 'Life expectancy age',
  'concept.taxInformationRates.label': 'Tax information and rates',
  'concept.dividendScaleAssumptions.label': 'Dividend scale assumptions',
  'concept.assumeInterestDeductibility': 'Assume interest deductibility',
  'concept.taxRatesUrl':
    'https://www.manulife.ca/advisors/insurance/tax-retirement-and-estate-planning/personal-and-corporate-tax-rates.html',
  'concept.collateralInsuranceDeduction': 'Collateral insurance deduction',
  'concept.mul.details': 'For Manulife UL concepts contact: ',
  'concept.parvp.details': 'For Manulife Par with <b><i>Vitality Plus</i></b> concepts contact: ',
  'concept.parpay100.details': 'For Manulife Par Pay to age 100 premium duration concepts contact: ',
  'concept.all.email': 'ConceptQuote@manulife.ca',
  'concept.all.details2': 'Please include the product illustration along with the concept assumptions.',
  'concept.all.details3': 'Some concepts we support are:',
  'concept.all.details3A': 'Insured Retirement Program',
  'concept.all.details3B': 'Corporate Insured Retirement Program',
  'concept.all.details3C': 'Estate Bond',
  'concept.all.details3D': 'Corporate Estate Bond',

  'withdrawal.title': 'Withdrawals',
  'withdrawal.beforeTax': 'Before tax',
  'withdrawal.afterTax': 'After tax',
  'withdrawal.amount.specified': 'Specified',
  'withdrawal.client': 'Based on client',

  'quicklinks.reference': 'Reference:',
  'quicklinks.term': 'Term:',
  'quicklinks.wholelife': 'Whole Life:',
  'quicklinks.concepts': 'Concepts:',
  'quicklinks.link.guidetopar':
    'https://www.manulife.ca/content/dam/manulife-advisor-portal/documents/en/marketing-materials/insurance/manulife-par/manulife-insurance-manulife-par-advisor-guide-mp1158345e.pdf',
  'quicklinks.label.guidetopar': `Advisor's Guide to Manulife Par`,
  'quicklinks.link.concepts': 'https://www.manulife.ca/advisors/insurance/advanced-sales-concepts.html',
  'quicklinks.label.concepts': `Insurance Sales Concepts`,
  'quicklinks.FTVGuide': 'Manulife Family Term with {vitality} - Advisor Guide',
  'quicklinks.healthstyleGuide': `Advisor's guide to Healthstyles`,
  'quicklinks.handbook': 'Individual Insurance - underwriting handbook for advisors',
  'quicklinks.requirements': 'Underwriting Requirements',
  'quicklinks.eApplication': 'Insurance E-Application',
  'quicklinks.sampleContracts': 'Individual Insurance sample contracts',
  'quicklinks.advisorGuideLink':
    'https://repsourcepublic.manulife.com/wps/wcm/connect/repsourceFormsMktg_en/MarketingMaterials/Insurance/Family+and+Business+Term/ins_ftbt_mk1464termadvisorbro?presentationtemplate=repsourceDesign2/showPDF',
  'quicklinks.FTVGuideLink':
    'https://repsourcepublic.manulife.com/wps/wcm/connect/repsourceFormsMktg_en/MarketingMaterials/Insurance/Manulife+Vitality/ins_vitality_ftbtadvguidemk3343?presentationtemplate=repsourceDesign2/showPDF',
  'quicklinks.healthstyleGuideLink':
    'https://www.manulife.ca/content/dam/manulife-advisor-portal/documents/en/marketing-materials/insurance/underwriting/manulife-insurance-healthstyles-advisor-guide-mk0954e.pdf',
  'quicklinks.handbookLink':
    'https://www.manulife.ca/content/dam/manulife-advisor-portal/documents/en/marketing-materials/insurance/new-business-and-underwriting/manulife-insurance-life-insurance-underwriting-handbook-for-advisors-mk2061e.pdf ',
  'quicklinks.requirementsLink':
    'https://www.manulife.ca/advisors/administration/insurance/new-business-and-underwriting/underwriting.html',
  'quicklinks.eApplicationLink': 'https://www.manulife.ca/advisors/private/private-tools/e-application.html',
  'quicklinks.sampleContractsLink': 'https://www.manulife.ca/advisors/my-business/resource-panel.html',

  'help.livechat': 'DTSC Live Chat',
  'help.livechat.link':
    'https://repsource.manulife.com/ds001ap/wps/myportal/Repsource/Repsource/ContactUsPrivate/general_private/dtsc_esupportlogmein/',
  'help.email': 'Email us: DTSC@Manulife.com',
  'help.email.link': 'mailto:DTSC@Manulife.com',
  'help.callus1': 'Call us: 1-800-667-4266',
  'help.callus2': '(At the prompt, enter option 3)',
  'help.forproductsupport': 'For product support:',
  'help.fortechnicalsupport': 'For technical support:',
  'help.contactwholesaler': 'Contact your Wholesaler',
  'help.contactus': 'Contact us',

  'status.started': 'Application started',
  'status.locked': 'Locked-ready to e-Sign',
  'status.await-client-e-sign': 'Await client e-Signature',
  'status.await-advisor-e-sign': 'Await advisor e-Signature',
  'status.declined-by-client': 'Client declined to e-Sign',
  'status.esign-link-expired': 'e-Signature link expired',
  'status.expired': 'Expired due to inactivity',
  'status.cleared': 'Cleared due to inactivity',
  'status.submitted': 'e-Submitted',
  'status.await-submit': 'Await advisor submit',

  'coverage.EffectiveDate.tooltip.text':
    'The Effective date impacts: product version, eligibility, calculation results.',
  'change-product-effectivedate-backdated':
    'This product was not available on web illustrations for the date you specified.',
};

export default locale;
