import * as actionTypes from '../../core/actionTypes';
import {
  configs,
  eDefault,
  eDefaultChild,
  eDefaultPayor,
  eDefaultRatings,
  eDefaultRatingsPayor,
  eField,
  eRider,
  eProduct,
  eHS,
  eBusinessType,
} from '../../core/configs';
import { assignDef, getValidStatusForInforce, scenarioIndexHelper } from '../../core/utils';
import _ from 'lodash';
import { childSeq, payorSeq, riderSeq, scenarioSeq } from '../../core/sequenceUtils';
import * as reducerHelper from '../../helper/reducer-helper';
import { isPmaxOrSB } from '../summary/utils';

const defaultChild = { ...eDefaultChild, ...eDefaultRatings };
const defaultPayor = { ...eDefaultPayor };

const dataState = function (riderName) {
  const data = { isSelected: false };
  const { isAmount, isRating } = _.find(configs.riders, { id: riderName });

  if (isRating) {
    data.rating = eDefault.rider.rating;
  }
  if (isAmount) {
    data.amount = eDefault.rider[`${riderName}Amount`];
  }

  return data;
};

const defaultState = {
  [eRider.tdw]: { isSelected: false },
  [eRider.bvp]: { isSelected: false },
  [eRider.gio]: { isSelected: false },
  [eRider.adnd]: { isSelected: false },
  [eRider.adb]: { isSelected: false },
  [eRider.cpr]: { isSelected: false, allChildren: [] },
  [eRider.tdwpayor]: { isSelected: false, payor: {} },
  [eRider.clc]: { isSelected: false },
  [eRider.wpd]: { isSelected: false },
  isFolded: false,
};

// TODO refactor to support for multiple scenarios
//	"Scenario.1": ...defaultState
const initialState = {
  activeTabId: scenarioSeq(true),
  [scenarioSeq(true)]: { ...defaultState },
};

const loadedUdmResponse = function (action, state) {
  const { response } = action.payload;
  const { illustration } = response;
  const newCPR = _.cloneDeep(state[state.activeTabId][eRider.cpr]);
  const newTDW = _.cloneDeep(state[state.activeTabId][eRider.tdwpayor]);

  // Update each client age from UDM response
  if (
    illustration &&
    illustration.party &&
    illustration.policy &&
    (illustration.policy.businessType !== eBusinessType.IF || illustration.policy.product === eProduct.FT)
  ) {
    _.forEach(newCPR.allChildren, (childId) => {
      newCPR[childId].age = illustration.party.find((party) => party.partyId === childId).age;
    });
    if (newTDW.payor.id) {
      newTDW[newTDW.payor.id].age = illustration.party.find((party) => party.partyId === newTDW.payor.id).age;
    }
  }
  return { newCPR, newTDW };
};

const riderReducer = function (state, action) {
  state = state === undefined ? initialState : state;
  switch (action.type) {
    case actionTypes.COMMON_INITIALIZE: {
      return { ...initialState };
    }
    case actionTypes.OPEN_SAVEDCASE: {
      const { scenarioTabNavs, riders } = action.payload.model;
      // This logic assigns all the missing properties from the initial state to the old saved object
      // with default value.
      const targetState = riders;
      assignDef(targetState, initialState);
      scenarioTabNavs.tabNavs.forEach((Scenario) => {
        assignDef(targetState[Scenario.id], defaultState);
      });
      return {
        ...targetState,
      };
    }
    case actionTypes.ADD_SCENARIO_TABNAVS: {
      return reducerHelper.getAddScenarioTabNavsState(state, action);
    }
    case actionTypes.CLICK_SCENARIO_TABNAVS: {
      return reducerHelper.getClickScenarioTabNavsState(state, action);
    }
    case actionTypes.REMOVE_SCENARIO_TABNAVS: {
      return reducerHelper.getRemoveScenarioTabNavsState(state, action);
    }

    case actionTypes.UPDATE_CHILD_RATINGS: {
      const { childId, ratings } = action.payload;
      const newState = _.cloneDeep(state[state.activeTabId]);

      newState[eRider.cpr][childId] = { ...newState[eRider.cpr][childId], ...ratings };

      return {
        ...state,
        [state.activeTabId]: newState,
      };
    }
    default:
      return riderReducer2(state, action);
  }
};

const toggleRiderClient = function (action, state) {
  const { riderName, clientId, isSelected } = action.payload;
  const newRider = _.cloneDeep(state[state.activeTabId][riderName]);

  if (isSelected) {
    newRider[clientId] = { ...dataState(riderName), isSelected: true, riderId: riderSeq() };
  } else {
    newRider[clientId] = { ...dataState(riderName) };
  }
  return { riderName, newRider };
};

const addRiderClient = function (action, state) {
  const newClientId = action.payload.clientId;
  const newState = _.cloneDeep(state[state.activeTabId]);

  _.forOwn(newState, (rider, key) => {
    if (key !== eRider.cpr && key !== eRider.tdwpayor && rider.isSelected) {
      rider[newClientId] = dataState(key);
    }
  });
  return newState;
};

const riderReducer2 = function (state, action) {
  switch (action.type) {
    // except CPR
    case actionTypes.TOGGLE_RIDER_CLIENT: {
      const { riderName, newRider } = toggleRiderClient(action, state);

      return {
        ...state,
        [state.activeTabId]: { ...state[state.activeTabId], [riderName]: newRider },
      };
    }

    case actionTypes.ADD_RIDER_CLIENT: {
      const newState = addRiderClient(action, state);

      return {
        ...state,
        [state.activeTabId]: newState,
      };
    }

    // if coverage type is changed to Single from Joint or combined
    // then remove the second client from the rider clients
    case actionTypes.REMOVE_RIDER_CLIENT: {
      const removedClientId = action.payload.clientId;
      const totalClients = action.payload.curClientIds.length;

      const newState = _.cloneDeep(state[state.activeTabId]);

      _.forOwn(newState, (rider, key) => {
        if (rider.isSelected && key !== eRider.cpr && key !== eRider.tdwpayor) {
          newState[key] = _.omit(rider, [removedClientId]);

          if (totalClients === 1) {
            newState[key][action.payload.curClientIds[0]].isSelected = true;
            newState[key][action.payload.curClientIds[0]].riderId = riderSeq();
          }
        }
      });

      return {
        ...state,
        [state.activeTabId]: newState,
      };
    }

    case actionTypes.MWI_UPDATE_ERROR: {
      const { target, riderName, childId, payorId, field, value } = action.payload;

      if (target === 'rider') {
        const newState = _.cloneDeep(state[state.activeTabId]);
        // if CPR then childId is passed, if PTDW then payorId is passed
        newState[riderName][childId || payorId].errors[field] = value;
        return {
          ...state,
          [state.activeTabId]: newState,
        };
      }

      return state;
    }

    case actionTypes.ADD_CPR_CHILD: {
      const id = childSeq();
      const newCPR = _.cloneDeep(state[state.activeTabId][eRider.cpr]);

      newCPR.allChildren = [...newCPR.allChildren, id];
      newCPR[id] = { ...defaultChild, id, riderId: riderSeq() };

      return {
        ...state,
        [state.activeTabId]: { ...state[state.activeTabId], [eRider.cpr]: newCPR },
      };
    }

    case actionTypes.REMOVE_CPR_CHILD: {
      let cpr = state[state.activeTabId][eRider.cpr];
      const deletingChildId = action.payload;

      cpr.allChildren = _.filter(cpr.allChildren, (childId) => childId !== deletingChildId);
      cpr = _.omit(cpr, [deletingChildId]);

      return {
        ...state,
        [state.activeTabId]: { ...state[state.activeTabId], [eRider.cpr]: cpr },
      };
    }

    default:
      return riderReducer3(state, action);
  }
};

const nonCprTdwRider = function (riderName, isSelected, newRider, state, allClients) {
  if (riderName !== eRider.cpr && riderName !== eRider.tdwpayor) {
    if (isSelected) {
      newRider = _.cloneDeep(state[state.activeTabId][riderName]);

      if (allClients.length === 1) {
        newRider[allClients[0]] = { ...dataState(riderName), isSelected: true, riderId: riderSeq() };
      } else {
        allClients.map((clientId) => (newRider[clientId] = dataState(riderName)));
      }
    } else {
      newRider = _.pick(state[state.activeTabId][riderName], ['isSelected']);
    }
  }
  return newRider;
};

const cprRider = function (riderName, isSelected, newRider, state) {
  if (riderName === eRider.cpr) {
    if (isSelected) {
      const id = childSeq();

      newRider = _.cloneDeep(state[state.activeTabId][riderName]);
      newRider.allChildren = [id];
      newRider[id] = { ...defaultChild, id, riderId: riderSeq() };
    } else {
      newRider = _.pick(state[state.activeTabId][riderName], ['isSelected', 'allChildren']);
      newRider.allChildren = [];
    }
  }
  return newRider;
};

const toggleRider = function (action, state) {
  const { riderName, allClients, isSelected, product } = action.payload;
  let newRider;
  newRider = nonCprTdwRider(riderName, isSelected, newRider, state, allClients);

  // add a default child
  newRider = cprRider(riderName, isSelected, newRider, state);

  // add a default payor
  if (riderName === eRider.tdwpayor) {
    if (isSelected) {
      if (product === eProduct.LC) {
        defaultPayor.age = 40;
      } else {
        defaultPayor.age = 45;
      }

      const id = payorSeq();
      newRider = _.cloneDeep(state[state.activeTabId][riderName]);
      newRider.payor = { id };
      newRider[id] = { ...defaultPayor, id, riderId: riderSeq() };
      newRider.ratings = {
        [id]: { ...eDefaultRatingsPayor },
      };
    } else {
      newRider = _.pick(state[state.activeTabId][riderName], ['isSelected', 'payor']);
      newRider.payor = {};
    }
  }
  return { riderName, isSelected, newRider };
};

const riderReducer3 = function (state, action) {
  switch (action.type) {
    case actionTypes.TOGGLE_RIDER: {
      const { riderName, isSelected, newRider } = toggleRider(action, state);

      newRider.isSelected = isSelected;
      return {
        ...state,
        [state.activeTabId]: { ...state[state.activeTabId], [riderName]: newRider },
      };
    }

    //just return default state to unselect all
    case actionTypes.UNSELECT_ALL_RIDERS: {
      return {
        ...state,
        [state.activeTabId]: { ...defaultState },
      };
    }
    case actionTypes.LOADED_UDM_RESPONSE: {
      const { newCPR, newTDW } = loadedUdmResponse(action, state);

      return {
        ...state,
        [state.activeTabId]: { ...state[state.activeTabId], [eRider.cpr]: newCPR, [eRider.tdwpayor]: newTDW },
      };
    }
    default:
      return riderReducer4(state, action);
  }
};

const setChildOrPayorId = function (childOrPayorId) {
  let targetRider =
    childOrPayorId.newState[childOrPayorId.riderName][
      childOrPayorId.clientId || childOrPayorId.childId || childOrPayorId.payorId
    ];
  if (childOrPayorId.dataGroup) {
    targetRider = { ...targetRider, ...childOrPayorId.dataGroup };
  } else {
    targetRider[childOrPayorId.field] = childOrPayorId.value;
  }

  childOrPayorId.newState[childOrPayorId.riderName][
    childOrPayorId.clientId || childOrPayorId.childId || childOrPayorId.payorId
  ] = targetRider;
};

const riderReducer4 = function (state, action) {
  switch (action.type) {
    case actionTypes.MWI_ON_CHANGE: {
      const { target, riderName, clientId, childId, payorId, field, value, dataGroup } = action.payload;

      if (target === 'rider') {
        if (field === eField.isFolded) {
          return {
            ...state,
            [state.activeTabId]: {
              ...state[state.activeTabId],
              isFolded: value,
            },
          };
        }
        const newState = _.cloneDeep(state[state.activeTabId]);

        // if CPR then childId is passed, if PTDW then payorId is passed
        const childOrPayorId = {
          newState,
          riderName,
          clientId,
          childId,
          payorId,
          dataGroup,
          field,
          value,
        };
        setChildOrPayorId(childOrPayorId);

        return {
          ...state,
          [state.activeTabId]: newState,
        };
      } else {
        if (target === 'scenario' && field === 'product') {
          // if product is changed, reset riders
          return {
            ...state,
            [state.activeTabId]: defaultState,
          };
        }
      }
      return state;
    }
    case actionTypes.UPDATE_PAYOR_RATINGS: {
      const { payorId, ratings } = action.payload;
      return {
        ...state,
        [state.activeTabId]: {
          ...state[state.activeTabId],
          [eRider.tdwpayor]: {
            ...state[state.activeTabId][eRider.tdwpayor],
            [payorId]: {
              ...state[state.activeTabId][eRider.tdwpayor][payorId],
            },
            ratings: { ...[eRider.tdwpayor].ratings, [payorId]: { ...ratings } },
          },
        },
      };
    }

    case actionTypes.LOADED_INFORCE_POLICY: {
      const coverages = action.payload.policyData.illustration.policy.coverage;
      const product = action.payload.policyData.illustration.policy.product;
      const parties = action.payload.policyData.illustration.party;
      const newState = loadInforceRiders(coverages, product, parties);
      return {
        ...newState,
      };
    }

    default:
      return state;
  }
};

const loadInforceRiders = (coverages, product, parties) => {
  const firstScenarioTabId = scenarioIndexHelper(1);
  const state = {
    activeTabId: firstScenarioTabId,
    [firstScenarioTabId]: {
      [eRider.tdw]: { isSelected: false },
      [eRider.bvp]: { isSelected: false },
      [eRider.gio]: { isSelected: false },
      [eRider.adnd]: { isSelected: false },
      [eRider.adb]: { isSelected: false },
      [eRider.cpr]: { isSelected: false, allChildren: [] },
      [eRider.tdwpayor]: { isSelected: false, payor: {} },
      [eRider.clc]: { isSelected: false },
      [eRider.wpd]: { isSelected: false },
      [eRider.pwdd]: { isSelected: false },
      [eRider.rop]: { isSelected: false },
      [eRider.ppr]: { isSelected: false },
      isFolded: false,
    },
  };
  const newState = { ...state };
  const allChildren = [];
  const validStatuses = getValidStatusForInforce(product);
  coverages.forEach((coverage) => {
    if (
      coverage.indicatorCode === eField.indicatorCode.rider &&
      validStatuses.includes(coverage.status) &&
      coverage.product !== eRider.ppr
    ) {
      let newRider = coverage.product === eRider.cpr ? { ...defaultChild } : { ...defaultPayor };
      const ratings = { ...eDefaultRatings };
      const id = coverage.life && coverage.life[0].partyId;
      const partyIndex = parties.findIndex((pty) => pty.partyId === id);
      newRider.id = id;
      newRider.age = coverage.equivalentAge || 0;
      if (partyIndex !== -1) {
        newRider.partyId = id;
        newRider.firstName = parties[partyIndex].firstName;
        newRider.lastName = parties[partyIndex].lastName;
        newRider.sex = parties[partyIndex].gender;
      }
      newRider.riderId = coverage.coverageId;
      newRider.indicatorCode = coverage.indicatorCode;
      newRider.coverageType = coverage.coverageType;
      newRider.coverageAmount = coverage.coverageAmount;
      newRider.healthstyle = getHealthStyleFromCoverage(coverage);
      newRider = setCoverageLifeRatings(newRider, coverage, ratings, product);
      newState[newState.activeTabId][coverage.product][id] = newRider;
      newState[newState.activeTabId][coverage.product].isSelected = true;
      newRider.isSelected = true;

      if (coverage.product === eRider.cpr) {
        if (isPmaxOrSB(product)) {
          newState[newState.activeTabId][coverage.product].childCount = coverage.childCount;
        } else {
          allChildren.push(id);
          newState[newState.activeTabId][coverage.product]['allChildren'] = allChildren;
        }
      }

      if (coverage.product === eRider.tdwpayor) {
        newState[newState.activeTabId][coverage.product]['payor'] = { id };
        newState[newState.activeTabId][coverage.product]['ratings'] = {
          [id]: { permRatingPercent: newRider.permRatingPercent },
        };
      }

      if (coverage.status === 'Rejected') {
        newState[newState.activeTabId][coverage.product].isSelected = false;
        newRider.isSelected = false;
      }
    }
  });
  return newState;
};

const getHealthStyleFromCoverage = (coverage) => {
  return eHS[coverage.healthstyle ? coverage.healthstyle.toLowerCase() : eHS.hs3];
};

const setCoverageLifeRatings = (newRider, coverage, ratings, product) => {
  if (coverage.lifeRating) {
    ratings = reducerHelper.getInforceRatingsFromCoverage(ratings, coverage, product);
    return { ...newRider, ...ratings };
  } else {
    const ratingAlt = {
      tempRatingYear: 1,
      tempRatingAmount: 0,
      permRatingAmount: 0,
      permRatingPercent: 100,
    };

    return { ...newRider, ...ratingAlt };
  }
};

export default riderReducer;
