import React from 'react';
import {
  eField,
  eDepositOptionType,
  eDurationType,
  eDepositOptionDurationType,
  eWithdrawalType,
  ePremiumOffset,
  eUserType,
  ePremiumOffsetMinYear,
} from '../../../core/configs';
import { getFormattedValue, ageToYear, getDuration } from '../../../core/utils';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const renderDepositOption = (props) => {
  const { premiumFrequency, depositOption, isSwitchToCashDividends, isUDMLoading, locale, isCoverageSolve } = props;

  const isRegularDepositOption = () =>
    (depositOption.durationType === eDepositOptionDurationType.year ||
      depositOption.durationType === eDepositOptionDurationType.sameasbase) &&
    !depositOption.isCustom &&
    depositOption.type !== eDepositOptionType.none;

  const showRegularDepositOption = isRegularDepositOption();

  return (
    <span>
      <div className="p-grid p-grid-no-margin no-margin mwi-padding-bottom8" data-testid="container-depositoption">
        <div className="p-col-8 label">
          <FormattedMessage id={`summary.depositoption.${premiumFrequency.toLowerCase()}`} />
          {showRegularDepositOption && !isSwitchToCashDividends && (
            <span role="depositoptionfor">
              <FormattedMessage id="summary.depositoption.for" />
              {isCoverageSolve ? depositOption.solveDuration : depositOption.duration}{' '}
              <span role="years">
                <FormattedMessage id="common.years" />
              </span>
            </span>
          )}
        </div>
        {!isUDMLoading && (
          <div className="p-col-4 amount">
            {depositOption.isCustom ? (
              <FormattedMessage id="depositoption.custom" />
            ) : (
              <span role="amount">
                {getFormattedValue(locale, depositOption.type === eDepositOptionType.none ? '0' : depositOption.amount)}
              </span>
            )}
          </div>
        )}
      </div>
    </span>
  );
};

export const ParInforcePaymentSection = (props) => {
  const {
    showManualRefresh,
    premiumOffset,
    locale,
    premiumOffsetAltYear,
    premiumOffsetYear,
    intl,
    withdrawals,
    clients,
    userType,
    depositOption,
  } = props;

  let minYear = 1;

  minYear =
    userType === eUserType.advisor
      ? ePremiumOffsetMinYear.advisorStartingAtMinYear
      : ePremiumOffsetMinYear.headOfficeStartingAtMinYear;

  const premiumoffsetYearValue =
    (premiumOffset !== ePremiumOffset.earliest && premiumOffsetYear < minYear) || premiumOffsetYear === 0
      ? minYear
      : premiumOffsetYear;

  return (
    <>
      <div className="p-grid p-grid-no-margin info " role="heading" aria-level="3">
        <span role="depositoption">
          <FormattedMessage id="summary.depositoption" />
        </span>
      </div>
      {!showManualRefresh && renderDepositOption(props)}
      {showManualRefresh && (
        <div className="p-grid p-grid-no-margin label no-margin refresh mwi-padding-bottom12">
          <span role="refresh">
            <FormattedMessage id="summary.refresh" />
          </span>
        </div>
      )}

      {depositOption.isAdditionalDepositSelected && (
        <div className="p-grid p-grid-no-margin label no-margin refresh mwi-padding-bottom12">
          <div className="p-col-8" data-testid="container-additionalDepositSelected">
            <div id="additionalDeposit">
              <FormattedMessage id="deposit.additional.deposit.inforce.PG" />
            </div>
          </div>
          <div className="p-col-4 amount">
            <div id="depositFrequencyAmount">{getFormattedValue(locale, depositOption.additionalDeposit || '0')}</div>
          </div>
        </div>
      )}

      {premiumOffset !== ePremiumOffset.sameaspolicy &&
        premiumOffset !== ePremiumOffset.no &&
        premiumOffsetAltYear !== undefined && (
          <span id="summary_payment_premiumoffset">
            <div className="p-grid p-grid-no-margin info" role="heading" aria-level="3">
              <span role="premiumoffset">
                <FormattedMessage id="summary.premiumoffset" />
              </span>
            </div>
            <div className="p-grid p-grid-no-margin mwi-padding-bottom12">
              <div className="p-col-12 label">
                <span role="primary">
                  {intl
                    .formatMessage({ id: 'summary.premiumoffsetyear.primary' })
                    .replace('XX', premiumoffsetYearValue)}
                </span>
              </div>
              <div className="p-col-12 label">
                <span role="alternate">
                  {intl
                    .formatMessage({ id: 'summary.premiumoffsetyear.alternate' })
                    .replace('XX', premiumOffsetAltYear)}
                </span>
              </div>
            </div>
          </span>
        )}
      {withdrawals[eField.withdrawalTypeOption] !== eWithdrawalType.none && (
        <span id="summary_payment_withdrawal">
          <div className="p-grid p-grid-no-margin info" role="heading" aria-level="3">
            <span role="withdrawal">
              <FormattedMessage id="summary.withdrawal" />
            </span>
          </div>
          <div className="p-grid p-grid-no-margin mwi-padding-bottom12">
            <div className="p-col-8 label">
              <span role="withdrawalamount">
                {getFormattedValue(locale, withdrawals[eField.withdrawalAmountOption], 0)}{' '}
              </span>
              <span role="withdrawalfor">
                <FormattedMessage id="summary.withdrawal.for" />
              </span>
              {withdrawals[eField.duration] === eDurationType.age
                ? getDuration(
                    ageToYear(withdrawals[eField.durationFrom], clients[withdrawals[eField.basedonClient]].age),
                    ageToYear(withdrawals[eField.durationTo], clients[withdrawals[eField.basedonClient]].age)
                  )
                : getDuration(withdrawals[eField.durationFrom], withdrawals[eField.durationTo])}{' '}
              <span role="withdrawalyears">
                <FormattedMessage id="common.years" />
              </span>
            </div>
          </div>
        </span>
      )}
    </>
  );
};

ParInforcePaymentSection.propTypes = {
  isIllustrationRatesFolded: PropTypes.bool,
  dispatchAction: PropTypes.func,
  showManualRefresh: PropTypes.bool,
  premiumFrequency: PropTypes.string,
  depositOption: PropTypes.object,
  isSwitchToCashDividends: PropTypes.bool,
  premiumOffset: PropTypes.string,
  isUDMLoading: PropTypes.bool,
  locale: PropTypes.string,
  premiumOffsetAltYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  premiumOffsetYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  intl: PropTypes.object,
  withdrawals: PropTypes.object,
  clients: PropTypes.object,
  product: PropTypes.string,
  userType: PropTypes.string,
  onChange: PropTypes.func,
  outOfPocketDuration: PropTypes.number,
  app: PropTypes.object,
  initialModalPremium: PropTypes.string,
};

export default ParInforcePaymentSection;
