import {
  eCompanyType,
  eConcept,
  eCoverageType,
  eDefault,
  eDepositOptionType,
  eDepositOwnerResidence,
  eDepositType,
  eDividendOption,
  eDurationType,
  eField,
  eLang,
  eLoanAmountSelection,
  ePremiumDuration,
  ePremiumOffset,
  eProduct,
  eReport,
  eRider,
  eSolveType,
  eUDMReqType,
  eUDMType,
  eVitalityStatus,
  eWithdrawalType,
  eLifecheque,
  eBusinessType,
  eCoverageSolveType,
  eDepositOptionDurationType,
  policyChangeRequestType,
  eEnhancerType,
  ePerformaxDividendOption,
} from '../configs';
import {
  getBusinessType,
  isIE,
  isInforce,
  isJoint,
  generatePolicyChangeRequestId,
  getReportPreparedForId,
  convertVitalityStatusToStatusString,
} from '../utils';
import { ProductConfig } from '../../custom-mode/product-config';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

export default class UDMGenerator {
  props = {
    reqType: PropTypes.string,

    app: PropTypes.object,
    scenarioTabId: PropTypes.string,
    udmResponse: PropTypes.object,
    isOutOfDate: PropTypes.bool,
    policyData: PropTypes.object,
    clients: PropTypes.object,
    coverages: PropTypes.object,
    riders: PropTypes.object,
    termRiders: PropTypes.object,
    vitalityStatus: PropTypes.object,
    report: PropTypes.object,
    spreadsheet: PropTypes.object,
    withdrawal: PropTypes.object,
    manualRefresh: PropTypes.bool,
    concepts: PropTypes.object,
    deposits: PropTypes.object,
  };

  setProps = (props) => {
    this.props = { ...props };
    return this;
  };

  isIllustrationRequest() {
    const { reqType } = this.props;

    return (
      reqType === eUDMReqType.report || reqType === eUDMReqType.spreadsheet || reqType === eUDMReqType.coverageCompare
    );
  }

  getUDMType() {
    const { manualRefresh } = this.props;
    if (this.isIllustrationRequest()) {
      return eUDMType.illustration;
    } else if (manualRefresh) {
      return eUDMType.summary;
    } else {
      return eUDMType.validation;
    }
  }

  updateInforceCashDividendSwitch = (inforcePolicy, policyData) => {
    if (policyData.product === eProduct.PAR) {
      let switchToCashDividendsYear = false;

      // iterate over coverages from state and compare against the inforce data
      const coverageState = this.props.coverages;
      for (let i = 0; i < coverageState.tabNavs.length; i++) {
        const currCoverage = coverageState[coverageState.tabNavs[i]];

        // Check if switch to cash dividends set
        if (currCoverage.switchToCashDividends) {
          switchToCashDividendsYear = currCoverage.switchToCashDividendsYear;
          break;
        }
      }

      // If a cash to dividends year is set, find the base coverage of for UDM and attach
      if (switchToCashDividendsYear) {
        // For loop to break early
        for (let i = 0; i < inforcePolicy.coverage.length; i++) {
          if (inforcePolicy.coverage[i].indicatorCode === eField.indicatorCode.base) {
            inforcePolicy.coverage[i].dividendOption.switchYear = switchToCashDividendsYear;
            break;
          }
        }
      } else {
        // If no switch year is set, remove the switch year from the UDM
        inforcePolicy.coverage.forEach((coverage) => {
          if (coverage.indicatorCode === eField.indicatorCode.base && coverage.dividendOption.switchYear) {
            delete coverage.dividendOption.switchYear;
          }
        });
      }
    }
  };

  updateInforceTermRiderDuration = (inforcePolicy, policyData) => {
    if ([eProduct.PG, eProduct.Performax, eProduct.SB, eProduct.PAR].includes(policyData.product)) {
      inforcePolicy.coverage
        ?.filter((coverage) => coverage.indicatorCode === eField.indicatorCode.termRider)
        ?.forEach((rider) => {
          let state = null;

          if (rider.product === eRider.tor) {
            state =
              this.props.termOtherRiders[rider.coverageId] || this.props.termOtherRiders[rider.associatedCoverageId];
          } else if (rider.product === eRider.spr) {
            state = this.props.spousalRiders[rider.coverageId] || this.props.spousalRiders[rider.associatedCoverageId];
          } else {
            state = this.props.termRiders[rider.coverageId] || this.props.termRiders[rider.associatedCoverageId];
          }

          if (state) {
            rider.duration = state.coverageDuration;
          }
        });
    }
  };

  updateInforcePremiumOffset = (inforcePolicy, policyData, allPolicy) => {
    if ([eProduct.PG, eProduct.Performax, eProduct.SB, eProduct.PAR].includes(policyData.product)) {
      const premiumOffset = this.getPremiumOffsetInforce(policyData, inforcePolicy);
      let inforcePremiumOffset = null;
      if (premiumOffset && premiumOffset.type !== ePremiumOffset.no) {
        inforcePremiumOffset = { ...allPolicy.premiumOffset, ...premiumOffset };
      }
      inforcePolicy.premiumOffset = inforcePremiumOffset;
    }
  };

  updateInforceWithdrawal = (inforcePolicy, policyData) => {
    if ([eProduct.PG, eProduct.Performax, eProduct.SB, eProduct.PAR].includes(policyData.product)) {
      const withdrawal = this.generateInforceWithdrawal(this.props.withdrawal);
      if (withdrawal) {
        inforcePolicy.withdrawal = withdrawal;
      }
    }
  };

  updateInforceDepositOption = (inforcePolicy, policyData, allPolicy, deposits) => {
    if ([eProduct.MUL].includes(policyData.product)) {
      inforcePolicy.depositOption.minimumDepositAmount = allPolicy.depositOption.minimumDepositAmount;
      inforcePolicy.depositOption.maximumDepositAmount = allPolicy.depositOption.maximumDepositAmount;
    }

    if ([eProduct.PG, eProduct.Performax, eProduct.SB].includes(policyData.product)) {
      inforcePolicy.depositOption.allocation = allPolicy.depositOption.allocation;
    }

    if ([eProduct.PAR].includes(policyData.product) && deposits) {
      inforcePolicy.depositOption.additionalDeposit = deposits[eField.depositAdditionalDeposit] ?? 0;
      if (
        inforcePolicy.depositOption.modalAmount &&
        inforcePolicy.depositOption.modalAmount !== allPolicy.depositOption.modalAmount
      ) {
        inforcePolicy.depositOption.customAmounts = inforcePolicy.depositOption.modalAmount;
      }
    }
  };

  createDividendOptionChangeRequest = (inforcePolicy, currCoverage, policyChangeRequest, cvgId) => {
    const inforceBaseCoverage = inforcePolicy.coverage?.find(
      (coverage) => coverage.indicatorCode === eField.indicatorCode.base && coverage.coverageId === cvgId
    );

    if (inforceBaseCoverage && currCoverage.dividendOption !== inforceBaseCoverage.dividendOption?.type) {
      const dividendOptionChange = {
        policyChangeRequestId: generatePolicyChangeRequestId(policyChangeRequest),
        type: policyChangeRequestType.dividendOptionChange,
        coverageId: inforceBaseCoverage.coverageId,
        dividendOption: currCoverage.dividendOption,
      };

      policyChangeRequest.push(dividendOptionChange);
    }
  };

  createTermOptionAmountChangeRequest = (inforcePolicy, currCoverage, policyChangeRequest, cvgId) => {
    const inforceYtiCoverage = inforcePolicy.coverage?.find(
      (coverage) => coverage.parentCoverageId === cvgId && coverage.product === eEnhancerType.YTI
    );

    if (inforceYtiCoverage && currCoverage.toFaceAmount !== currCoverage.toFaceAmountFromPS) {
      const faceAmountChange = {
        policyChangeRequestId: generatePolicyChangeRequestId(policyChangeRequest),
        type:
          currCoverage.toFaceAmount > currCoverage.toFaceAmountFromPS
            ? policyChangeRequestType.faceAmountIncrease
            : policyChangeRequestType.faceAmountDecrease,
        coverageId: inforceYtiCoverage.coverageId,
        amount: currCoverage.toFaceAmount,
      };

      policyChangeRequest.push(faceAmountChange);
    }
  };

  createTermOptionPlusAmountChangeRequest = (inforcePolicy, currCoverage, policyChangeRequest, cvgId) => {
    const inforceYtiPlusCoverage = inforcePolicy.coverage?.find(
      (coverage) => coverage.parentCoverageId === cvgId && coverage.product === eEnhancerType.YTIPlus
    );

    if (inforceYtiPlusCoverage && currCoverage.topFaceAmount !== inforceYtiPlusCoverage.coverageAmount) {
      const faceAmountChange = {
        policyChangeRequestId: generatePolicyChangeRequestId(policyChangeRequest),
        type:
          currCoverage.topFaceAmount > inforceYtiPlusCoverage.coverageAmount
            ? policyChangeRequestType.faceAmountIncrease
            : policyChangeRequestType.faceAmountDecrease,
        coverageId: inforceYtiPlusCoverage.coverageId,
        amount: currCoverage.topFaceAmount,
      };
      policyChangeRequest.push(faceAmountChange);
    }
  };

  createInforcePolicyChangeRequest = (inforcePolicy, policyData) => {
    const policyChangeRequest = [];

    if ([eProduct.Performax, eProduct.SB, eProduct.PAR].includes(policyData.product)) {
      // iterate over coverages from state and compare against the inforce data
      const coverageState = this.props.coverages;
      coverageState.tabNavs.forEach((cvgId) => {
        const currCoverage = coverageState[cvgId];

        this.createDividendOptionChangeRequest(inforcePolicy, currCoverage, policyChangeRequest, cvgId);

        if (currCoverage.dividendOption === ePerformaxDividendOption.termOption) {
          this.createTermOptionAmountChangeRequest(inforcePolicy, currCoverage, policyChangeRequest, cvgId);
          this.createTermOptionPlusAmountChangeRequest(inforcePolicy, currCoverage, policyChangeRequest, cvgId);
        }
      });
    }

    return policyChangeRequest;
  };

  // validate UDM and fix any issues
  validateUDM = (udm) => {
    // check for any duplicate data objects in the UDM
    const hasDuplicates = (array, compareAttribute) => {
      const ids = new Set(array.map((i) => i[compareAttribute]));
      return ids.size !== array.length;
    };

    const partyDuplicates = hasDuplicates([...udm.illustration.party], 'partyId');
    const coverageDuplicates = hasDuplicates([...udm.illustration.policy.coverage], 'coverageId');

    if (!partyDuplicates && !coverageDuplicates) {
      return udm;
    }

    const removeDuplicates = (array) => {
      const seen = new Set();
      return array.filter((item) => {
        const jsonString = JSON.stringify(item);
        return seen.has(jsonString) ? false : seen.add(jsonString);
      });
    };

    // remove duplicate data objects
    if (partyDuplicates) {
      udm.illustration.party = removeDuplicates(udm.illustration.party);
    }
    if (coverageDuplicates) {
      udm.illustration.policy.coverage = removeDuplicates(udm.illustration.policy.coverage);
    }

    return udm;
  };

  updateOwnerId = (inforcePolicy) => {
    const ownerId = inforcePolicy.ownerId;
    const firstPartyId = inforcePolicy.coverage[0].life[0].partyId;

    if ([eProduct.FT, eProduct.BT].includes(inforcePolicy.product) && firstPartyId !== ownerId) {
      inforcePolicy.ownerId = firstPartyId;
    }
  };

  clearEmptyVitality = (inforcePolicy) => {
    if (inforcePolicy?.vitality) {
      inforcePolicy.vitality = Object.keys(inforcePolicy.vitality).length === 0 ? null : inforcePolicy.vitality;
    }
  };

  updateInforceVitality = (inforcePolicy, allPolicy) => {
    const { vitalityStatus, coverages } = this.props;

    if ([eProduct.PAR].includes(inforcePolicy.product) && inforcePolicy.vitality && vitalityStatus.length > 0) {
      let vitalityStatuses = vitalityStatus;
      const baseCoverage = allPolicy.coverage.filter((cvg) => cvg.indicatorCode === eField.indicatorCode.base)[0];

      vitalityStatuses = this.addBronzeAfterAgeStatus(coverages, baseCoverage.life[0], [...vitalityStatuses]);
      inforcePolicy.vitality.life[0].status = [];
      vitalityStatuses.forEach((vs) =>
        inforcePolicy.vitality.life[0].status.push(convertVitalityStatusToStatusString(vs))
      );
    }
  };

  getUDMInforce = (udmType) => {
    const { illustration, user, party } = this;
    const { app, deposits, policyData } = this.props;

    const allParties = [...party()];
    const allPolicy = app.inforceData.policy;
    const depositOption = this.getDepositOption(policyData, deposits);
    const rates = this.getRates(policyData);
    const advisorId = allParties.find((pty) => pty.advisorId);
    const inforcePolicy = { advisorId: advisorId?.advisorId, ...allPolicy, depositOption, rates };

    // Clears out an empty vitality object if it exists - Par
    this.clearEmptyVitality(inforcePolicy);

    this.updateInforceVitality(inforcePolicy, allPolicy);

    // update ownerId - FT, BT
    this.updateOwnerId(inforcePolicy);

    // update DO
    this.updateInforceDepositOption(inforcePolicy, policyData, allPolicy, deposits);

    // update withdrawal - PMAX, PG, SB, PAR
    this.updateInforceWithdrawal(inforcePolicy, policyData);

    // update premium offset - PMAX, PG, SB, PAR
    this.updateInforcePremiumOffset(inforcePolicy, policyData, allPolicy);

    // update TIR duration - PMAX, PG, SB, PAR
    this.updateInforceTermRiderDuration(inforcePolicy, policyData);

    // update UDM with Cash Dividend Switch year - PAR
    this.updateInforceCashDividendSwitch(inforcePolicy, policyData);

    // add policy change request - PMAX, SB (more products in future)
    const policyChangeRequest = this.createInforcePolicyChangeRequest(inforcePolicy, policyData);

    const udm = {
      type: udmType,
      illustration: {
        ...illustration(allParties),
        user: user(),
        party: allParties,
        policy: inforcePolicy,
        ...(policyChangeRequest.length > 0 && { policyChangeRequest }),
      },
    };
    udm.illustration.illustrationDate = app.inforceData.illustrationDate;

    this.validateUDM(udm);
    return udm;
  };

  getUDM = () => {
    const { app } = this.props;
    // changes made to the UDM request types see CDTABC-732
    // If request is report - Full, if manualRefresh - Summary
    const udmType = this.getUDMType();

    if (!app.inforcePolicy) {
      const { illustration, user, party, children, payor, policy, concept } = this;
      const allParties = [...party(), ...children(), ...payor()];
      const isPAR = this.props.policyData.product === eProduct.PAR;
      const udmPolicyData = policy();

      const udm = {
        type: udmType,
        illustration: {
          ...illustration(allParties),
          user: user(),
          party: allParties,
          policy: udmPolicyData,
          ...(isPAR && this.props.policyData.concept !== eConcept.NONE && { concept: concept() }),
        },
      };

      this.validateUDM(udm);
      return udm;
    } else {
      return this.getUDMInforce(udmType);
    }
  };

  getFormattedDOB = (dob) => {
    if (isIE()) {
      const date = moment(dob);
      return date.isValid() ? (dob && moment(dob, 'DDMMYYYY').format(eDefault.udmDate)) || null : null;
    } else {
      return (dob && moment(dob, 'DDMMYYYY').format(eDefault.udmDate)) || null;
    }
  };

  getHealthStyle = (product, client) => {
    switch (product) {
      case eProduct.PAR:
      case eProduct.MUL:
      case eProduct.LC:
        return client.smokingstatus;
      default:
        return `HS${client.healthstyle}`;
    }
  };

  getInforceHealthStyle = (product, client) => {
    switch (product) {
      case eProduct.PAR:
      case eProduct.LC:
        return client.smokingstatus;
      default:
        return `HS${client.healthstyle}`;
    }
  };

  getSections = () => {
    const { reqType, report = {} } = this.props;
    const sections = {};
    if (reqType === eUDMReqType.report) {
      _.forEach(eReport.options, (option) => {
        if (report[eReport[option]]) {
          sections[eReport[option]] = {};
        }
      });
    }
    return sections;
  };

  getPreparedFor = (allParties) => {
    const { policyData = {}, report = {}, advisorProfile, app } = this.props;

    const advisorCurrentID =
      advisorProfile &&
      advisorProfile[advisorProfile.currentProfileId] &&
      advisorProfile[advisorProfile.currentProfileId].guid;
    const partyWithId = allParties.find((party) => party.fullName === report.preparedFor);

    return [eProduct.MUL, eProduct.PG, eProduct.Performax, eProduct.SB].includes(policyData.product) ||
      (app.inforcePolicy && [eProduct.FT, eProduct.BT, eProduct.PAR].includes(policyData.product))
      ? {
          preparedForId: partyWithId && partyWithId.partyId ? partyWithId.partyId : null,
          preparedById: advisorCurrentID === eDefault.advisorProfile ? null : advisorCurrentID,
        }
      : { designedFor: report.preparedFor };
  };

  illustration = (allParties) => {
    const { reqType, app, policyData = {}, report = {} } = this.props;

    const layout = policyData.product === eProduct.PAR ? { layout: report.layout } : null;
    const spreadsheetOutputType = reqType === eUDMReqType.spreadsheet ? 'Spreadsheet' : 'None';

    // to show premium offset table for par inforce policy when premiumOffset is set
    if (reqType === eUDMReqType.report && app.inforcePolicy && policyData.product === eProduct.PAR) {
      report.offsetDetailed = this.isPremiumOffsetSet(policyData);
    }

    const reportOptions = this.isIllustrationRequest()
      ? {
          type: this.isIllustrationRequest() ? report.reportType : 'None',
          outputType:
            reqType === eUDMReqType.report || reqType === eUDMReqType.coverageCompare ? 'Pdf' : spreadsheetOutputType,
          language: report.lang,
          ...(layout && { ...layout }),
          ...this.getPreparedFor(allParties),
          section: { ...this.getSections() },
        }
      : null;

    return {
      illustrationId: policyData.illustrationId,
      version: '',
      mode: 'Custom',
      system: 'Web',
      language: eLang[this.props.app.locale],
      ...(reportOptions && { reportOptions }),
    };
  };

  user = () => {
    return {
      userId: '',
      name: '',
      // it should be fixed until authentication is implemented: Advisor or HeadOffice
      // TODO it is only for test purpose
      type: this.props.app.userType,
    };
  };

  //concept UDM
  concept = () => {
    const { concepts, policyData } = this.props;
    const {
      alternateInvestment: { investmentAllocation },
    } = concepts;
    const investmentAllocations = _.map(investmentAllocation, (investment, type) => {
      return {
        type,
        percentage: investment.percentage,
        rate: investment.rate,
      };
    });
    const lifeExpectancyAssumption = {
      deathAge: concepts.lifeExpectancyAssumption.deathAtAge,
      plusDuration: concepts.lifeExpectancyAssumption.additionalYears,
    };
    const conceptDividendScale = {
      type: concepts.dividendScale.type,
    };
    let concept;
    switch (policyData.concept) {
      case eConcept.CEB:
      case eConcept.PEB:
        concept = this.ebConcept(concepts);
        break;
      case eConcept.CIRP:
        concept = this.cirpConcept(concepts);
        break;
      case eConcept.PIRP:
        concept = this.pirpConcept(concepts);
        break;
      default:
        concept = {};
    }
    return {
      type: policyData.concept,

      // if None send just the type
      ...(policyData.concept !== eConcept.NONE && {
        corporateTaxRate: concepts.taxInformation.corporateTaxRate,
        personalDividendRate: concepts.personalDividendTaxRate,
        lifeExpectancy: lifeExpectancyAssumption,
        investmentAllocation: investmentAllocations,
        dividendScale: conceptDividendScale,
        // extra fields specific to concept type, extend to other concepts as needed
        ...concept,
      }),
    };
  };

  ebConcept = (concepts) => {
    return {
      alternativeInvestmentLumpSumIndicator: concepts.alternateInvestment.isIllustrateChecked,
    };
  };

  pirpConcept = (concepts) => {
    const { coverages, policyData, withdrawal } = this.props;
    const loanType = concepts.loanInformation.loanAmount;
    //remove collateralInsuranceDeduction from loan information
    const coverage = coverages[coverages.activeTabId];
    const collateralInsuranceInd =
      policyData.product === eProduct.PAR && coverage.premiumDuration === ePremiumDuration.Pay90
        ? concepts.loanInformation.collateralInsuranceDeduction
        : false;

    const loanInformation = {
      type: loanType,
      bankLoanRate: concepts.loanInformation.bankLoanRate,
      interestDeductionInd: concepts.loanInformation.assumeInterestDeductibility,
      csvLoanPct: concepts.loanInformation.csvLoanPercentage,
      marginalTaxRate: policyData.marginalTaxRate,
      ...(loanType === eLoanAmountSelection.specified && { specifiedAmount: concepts.loanInformation.amount }),
      duration: {
        type: concepts.loanInformation[eField.duration],
        start: concepts.loanInformation[eField.durationFrom],
        end: concepts.loanInformation[eField.durationTo],
      },
    };
    return {
      //remove collateralInsuranceDeduction from loan information
      collateralInsuranceInd,
      loanInformation,
      investmentIncomeRate: concepts.corporateIRPStructure.investmentIncomeTax,
      presentValueDiscountRate: concepts.taxInformationRates.presentValueDiscountRate,
      ...(withdrawal && { withdrawalType: withdrawal[eField.withdrawalTypeOption] }),
    };
  };

  cirpConcept = (concepts) => {
    const { coverages, policyData, withdrawal } = this.props;
    const companyType = concepts.corporateIRPStructure.companyType;
    const loanType = concepts.loanInformation.loanAmount;
    //remove collateralInsuranceDeduction from loan information
    const coverage = coverages[coverages.activeTabId];
    const collateralInsuranceInd =
      policyData.product === eProduct.PAR && coverage.premiumDuration === ePremiumDuration.Pay90
        ? concepts.loanInformation.collateralInsuranceDeduction
        : false;

    const loanInformation = {
      type: loanType,
      bankLoanRate: concepts.loanInformation.bankLoanRate,
      interestDeductionInd: concepts.loanInformation.assumeInterestDeductibility,
      csvLoanPct: concepts.loanInformation.csvLoanPercentage,
      marginalTaxRate: policyData.marginalTaxRate,
      ...(loanType === eLoanAmountSelection.specified && { specifiedAmount: concepts.loanInformation.amount }),
      duration: {
        type: concepts.loanInformation[eField.duration],
        start: concepts.loanInformation[eField.durationFrom],
        end: concepts.loanInformation[eField.durationTo],
      },
    };
    return {
      borrowingType: concepts.corporateIRPStructure.borrowingType,
      //remove collateralInsuranceDeduction from loan information
      collateralInsuranceInd,
      companyType,
      loanInformation,
      investmentIncomeRate: concepts.corporateIRPStructure.investmentIncomeTax,
      presentValueDiscountRate: concepts.taxInformationRates.presentValueDiscountRate,
      ...(withdrawal && { withdrawalType: withdrawal[eField.withdrawalTypeOption] }),
      ...(companyType === eCompanyType.operatingCompany && {
        operatingIncomeRate: concepts.corporateIRPStructure.operatingIncomeTax,
      }),
    };
  };

  party = () => {
    const { clients, report = {}, advisorProfile, policyData, deposits } = this.props;
    const areaCodeLength = 3;

    let parties = _.map(clients.allClients, (clientId) => {
      const client = clients[clientId];
      const birthdate = this.getFormattedDOB(client.dob);
      let addressProvince;

      if (
        policyData.product === eProduct.MUL &&
        deposits[eField.depositOwnerResidence] !== eDepositOwnerResidence.select
      ) {
        addressProvince = deposits[eField.depositOwnerResidence];
      }

      return {
        partyId: client.id,
        ...(birthdate && { birthdate }),
        age: +client.age,
        gender: client.sex,
        firstName: client.firstName,
        lastName: client.lastName,
        ...(addressProvince && { addressProvince }),
      };
    });

    //Fix AUL-2664
    _.find(
      _.filter(parties, (party) => party.firstName === '' && party.lastName === ''),
      (client) => {
        client.fullName = clients[client.partyId].placeholder;
      }
    );

    // not implemented fully. need to talk to API team
    if (report.preparedFor && !parties.some((party) => party.fullName === report.preparedFor)) {
      parties = [...parties, { partyId: getReportPreparedForId(), fullName: report.preparedFor }];
    }

    if (advisorProfile?.currentProfileId !== eDefault.advisorProfile) {
      let profile = advisorProfile[advisorProfile?.currentProfileId];

      if (profile === null || profile === undefined) {
        profile = advisorProfile[eDefault.advisorProfile];
      }

      const numStartPosition = 4;
      parties = [
        ...parties,
        {
          partyId: advisorProfile?.currentProfileId,
          advisorId: advisorProfile?.currentProfileId,
          fullName: profile?.advisorName,
          businessName: profile?.companyName,
          email: profile?.email || null,
          phone: profile?.phoneNumber
            ? {
                type: null,
                areaCode: profile?.phoneNumber.substring(0, areaCodeLength),
                number: profile?.phoneNumber.substring(numStartPosition).replace('-', ''),
                extension: profile?.extension,
              }
            : null,
        },
      ];
    }

    return parties;
  };

  children = () => {
    const { riders, app } = this.props;
    const cpr = riders[eRider.cpr];
    let children = [];

    if (cpr.isSelected && !app.inforcePolicy) {
      children = _.map(cpr.allChildren, (childId) => {
        const child = cpr[childId];
        const birthdate = this.getFormattedDOB(child.dob);
        const offspring = {
          partyId: child.id,
          ...(birthdate && { birthdate }),
          age: +child.age,
          gender: child.sex,
          firstName: child.firstName,
          lastName: child.lastName,
        };

        return offspring;
      });
    }

    return children;
  };

  payor = () => {
    const payor = [];

    const tdw = this.props.riders[eRider.tdwpayor];
    if (tdw.isSelected) {
      const riderClient = tdw[tdw.payor.id];
      const birthdate = this.getFormattedDOB(riderClient.dob);

      payor.push({
        partyId: tdw.payor.id,
        ...(birthdate && { birthdate }),
        age: +riderClient.age,
        gender: riderClient.sex,
        firstName: riderClient.firstName,
        lastName: riderClient.lastName,
      });
    }

    return payor;
  };

  getLifeRating = (cov) => {
    const { clients, policyData } = this.props;

    let lifeRating;
    // for joint cases, blended rating is in the first client
    if (isJoint(policyData.product, cov.coverageType)) {
      const clientIdsInCov = _.keys(cov.ratings);
      const rating = cov.ratings[clientIdsInCov[0]];

      lifeRating = rating && [
        {
          permanentPercent: rating.permRatingPercent,
          permanentAmount: rating.permRatingAmount,
          temporaryAmount: rating.tempRatingAmount,
          temporaryDuration: rating.tempRatingYear,
          temporaryAmount2: rating.tempRatingAmount2,
          temporaryDuration2: rating.tempRatingYear2,
        },
      ];
    } else {
      lifeRating = _.map(cov.ratings, (rating, clientId) => {
        return (
          clients[clientId] && {
            partyId: clientId,
            permanentPercent: rating.permRatingPercent,
            permanentAmount: rating.permRatingAmount,
            temporaryAmount: rating.tempRatingAmount,
            temporaryDuration: rating.tempRatingYear,
            temporaryAmount2: rating.tempRatingAmount2,
            temporaryDuration2: rating.tempRatingYear2,
          }
        );
      });
    }
    return lifeRating;
  };

  addBronzeAfterAgeStatus = (coverages, client, statuses) => {
    if (coverages[coverages.activeTabId].isChangeToBronze) {
      const fromYear = coverages[coverages.activeTabId].changeToBronzeAge - client.age + 1;
      statuses.push({ vitalityStatus: eVitalityStatus.bronze, fromYear });
    }
    return statuses;
  };

  addVitalityStatus = (life, product, statuses) => {
    let prevStatus = {};
    _.forEach(statuses, (status) => {
      if (this.isParFirstVitalityStatus(status, prevStatus, product)) {
        life.status = [...life.status, `${status.vitalityStatus.charAt(0)}:${1}`];
      } else {
        if (this.isNextVitalityStatus(status, prevStatus)) {
          life.status = [...life.status, `${status.vitalityStatus.charAt(0)}:${status.fromYear}`];
        }
      }
      prevStatus = status;
    });
  };

  isNextVitalityStatus = (status, prevStatus) =>
    status.vitalityStatus && status.vitalityStatus !== prevStatus.vitalityStatus;

  isParFirstVitalityStatus = (status, prevStatus, product) =>
    this.isNextVitalityStatus(status, prevStatus) && product === eProduct.PAR && prevStatus.vitalityStatus == null;

  isProductMULorPar = (product) => product === eProduct.MUL || product === eProduct.PAR;

  getVitality = () => {
    const { clients, coverages, policyData } = this.props;
    const { product } = policyData;

    let vitality = null;

    // since all coverages must have either Vitality Plus checked or not,
    // only need to check one of the coverages
    if (coverages[coverages.activeTabId].isVitalityPlusSelected) {
      vitality = {
        type: 'Plus',
        life: [],
        coverage: [],
      };

      _.forEach(clients, (client) => {
        if (client.id !== undefined) {
          const life = {
            lifeId: client.id,
            status: [],
          };

          const statuses = this.isProductMULorPar(product)
            ? this.addBronzeAfterAgeStatus(coverages, client, [...this.props.vitalityStatus])
            : // the first year is "Gold" all the time
              [{ vitalityStatus: eVitalityStatus.gold, fromYear: 1 }, ...this.props.vitalityStatus];

          this.addVitalityStatus(life, product, statuses);

          vitality.life.push(life);

          // all coverages for that client need to be included
          _.forEach(client.coverageTabId, (covId) => {
            vitality.coverage.push({
              coverageId: covId,
              lifeId: client.id,
            });
          });
        }
      });
    }
    return vitality;
  };

  getLCPremiumDuration = (cov) => {
    let premiumDuration = cov.premiumDuration;
    const { policyData } = this.props;
    if (policyData.product === eProduct.LC) {
      premiumDuration = {
        type:
          cov.coverageOption !== eLifecheque.lcCoverageOptions.lcPermanent
            ? eLifecheque.lcPremiumDurations.lcExpiry
            : cov.lcPremiumDuration,
      };
    }
    return premiumDuration;
  };

  getReturnOfPremium = (cov) => {
    const returnOfPremium = [];

    if (!cov.lcReturnPremiums) {
      return returnOfPremium;
    }
    if (cov.lcReturnPremiums.ropd) {
      returnOfPremium.push({ type: eLifecheque.lcReturnPremiums.ropd });
    }

    if (cov.lcReturnPremiums.rops) {
      returnOfPremium.push({ type: eLifecheque.lcReturnPremiums.rops });
    }

    if (cov.lcReturnPremiums.ropx) {
      returnOfPremium.push({ type: eLifecheque.lcReturnPremiums.ropx });
    }

    return returnOfPremium;
  };

  getCoverageOption = (cov) => {
    const { policyData } = this.props;
    return policyData.product === eProduct.LC ? cov.lcCoverageOption : cov.coverageOption;
  };

  getDividentOption = (cov, policyData) => {
    let dividendOption = null;
    if (policyData.product === eProduct.PAR) {
      dividendOption = { type: cov.dividendOption };
      if (dividendOption.type === eDividendOption.pui && cov.switchToCashDividends) {
        dividendOption.switchYear = cov.switchToCashDividendsYear;
      }
    }
    return dividendOption;
  };

  getCoverageType = (cov, policyData) => {
    return policyData.product === eProduct.PAR && cov.coverageType === eCoverageType.jointLastPayLastDeath
      ? eCoverageType.jointLast
      : cov.coverageType;
  };

  setMulCoverageValues = (policyData, cov, mulCoverageValues) => {
    if (policyData.product === eProduct.MUL) {
      mulCoverageValues = {
        deathBenefit: {
          type: cov.deathBenefitType,
          percentage: cov.deathBenefit,
        },
        amountType: cov.amountOfInsuranceType,
        costOfInsurance: {
          type: cov.costOfInsurance,
          ...(cov.costOfInsuranceSwitchYear && { switchYear: cov.switchYear }),
        },
      };
    }
    return mulCoverageValues;
  };

  baseCoverages = () => {
    const { clients, policyData, coverages, app } = this.props;

    return _.map(coverages.tabNavs, (coverageId, index) => {
      const cov = { ...coverages[coverageId], coverageOption: this.getCoverageOption(coverages[coverageId]) };

      const lives = _.map(cov.ratings, (rating, clientId) => {
        const client = clients[clientId];

        if (app.inforcePolicy) {
          return (
            client && {
              partyId: clientId,
              ...(!isInforce(policyData.product) && { age: +client.age }),
              healthstyle: this.getInforceHealthStyle(policyData.product, client),
            }
          );
        }
        return (
          client && {
            partyId: clientId,
            ...(!isInforce(policyData.product) && { age: +client.age }),
            healthstyle: this.getHealthStyle(policyData.product, client),
          }
        );
      });

      const dividendOption = this.getDividentOption(cov, policyData);

      let mulCoverageValues = {};
      mulCoverageValues = this.setMulCoverageValues(policyData, cov, mulCoverageValues);

      const premiumDuration = this.getLCPremiumDuration(cov);
      const returnOfPremium = this.getReturnOfPremium(cov);
      return {
        coverageId,
        // it is added due to coverage id is not serial as it is added/deleted
        // so if needs to find a coverage, this index could be used.
        // the API uses this index to generate error/warning messages.
        // it is the same number as the coverage label in the tab
        index: index + 1,
        // in NewBusiness, effectiveDate is the same as policy
        // effectiveDate: policyData.effectiveDate,
        coverageAmount: cov.isCoverageSolve ? null : cov.coverageAmount,
        // for 'Joint last-to-die, premiums payable to last death' in PAR,
        // use the same as regular Joint last to die
        coverageType: this.getCoverageType(cov, policyData),
        ...(policyData.product !== eProduct.PAR && {
          amountType: cov.isCoverageSolve ? eDepositOptionType.specified : policyData.depositOptionType,
        }),

        ...(policyData.product === eProduct.LC && { premiumDuration }),
        ...(policyData.product === eProduct.LC && { returnOfPremium }),
        ...(dividendOption && { dividendOption }),
        product: this.getCoverageProduct(policyData.product, cov),
        specialQuote: cov.specialQuoteOption,
        ...(policyData.product === eProduct.MUL && { deathBenefit: cov.deathBenefitType }),
        life: lives,
        ...mulCoverageValues,
        lifeRating: this.getLifeRating(cov),
      };
    });
  };

  getCoverageProduct = (product, coverage) => {
    switch (product) {
      case eProduct.PAR:
        return coverage.premiumDuration;
      case eProduct.FT:
      case eProduct.BT:
      case eProduct.FTV:
      case eProduct.FTRC:
      case eProduct.LC:
        return coverage.coverageOption;
      case eProduct.MUL:
        return product;
      default:
        return coverage.coverageOption;
    }
  };

  getTermRider = (rider) => {
    const { policyData, app } = this.props;
    const riderCoverage = {
      coverageId: rider.id,
      coverageAmount: rider.coverageAmount,
      coverageType: rider.coverageType,
      product: rider.coverageOption,
      duration: rider.coverageDuration,
    };
    const clientIds = rider.coverageType === eCoverageType.single ? [rider.clientId] : rider.clientIds;
    const life = [];
    const lifeRating = [];

    _.forEach(clientIds, (clientId) => {
      const client = this.props.clients[clientId];
      const clientLife = {
        partyId: client.id,
        age: client.age,
        healthstyle: this.getHealthStyle(policyData.product, client),
      };

      if (app.inforcePolicy) {
        clientLife.healthstyle = this.getInforceHealthStyle(policyData.product, client);
      }

      life.push(clientLife);

      if (rider.ratings) {
        const clientLifeRating = {
          partyId: client.id,
          permanentPercent: rider.ratings[client.id].permRatingPercent,
          permanentAmount: rider.ratings[client.id].permRatingAmount,
          temporaryAmount: rider.ratings[client.id].tempRatingAmount,
          temporaryDuration: rider.ratings[client.id].tempRatingYear,
          temporaryAmount2: rider.ratings[client.id].tempRatingAmount2,
          temporaryDuration2: rider.ratings[client.id].tempRatingYear2,
        };

        lifeRating.push(clientLifeRating);
      }
    });
    riderCoverage.life = life;
    riderCoverage.lifeRating = lifeRating;
    return riderCoverage;
  };

  getCprRider = (rider, riderName, childId) => {
    const { app, policyData } = this.props;
    const child = rider[childId];
    const data = {
      coverageId: child.riderId,
      // effectiveDate: policyData.effectiveDate,
      product: riderName,
    };

    const life = {
      partyId: childId,
      age: child.age,
    };

    if (app.inforcePolicy) {
      life.healthstyle = '5';
      data.coverageType = child.coverageType;
      data.indicatorCode = child.indicatorCode;
    }

    data.life = [life];
    if (policyData.product !== eProduct.PAR) {
      data.lifeRating = [
        {
          partyId: childId,
          permanentPercent: child.permRatingPercent,
          permanentAmount: child.permRatingAmount,
          temporaryAmount: child.tempRatingAmount,
          temporaryDuration: child.tempRatingYear,
          temporaryAmount2: child.tempRatingAmount2,
          temporaryDuration2: child.tempRatingYear2,
        },
      ];
    }
    return data;
  };

  getTdwRider = (rider, riderName) => {
    const { policyData, app } = this.props;
    const riderClient = rider[rider.payor.id];

    const riderCoverage = {
      coverageId: riderClient.riderId,
      // effectiveDate: policyData.effectiveDate,
      product: riderName,
    };
    const life = {
      partyId: rider.payor.id,
      age: riderClient.age,
      healthstyle: this.getHealthStyle(policyData.product, riderClient),
    };

    if (app.inforcePolicy) {
      life.healthstyle = this.getInforceHealthStyle(policyData.product, riderClient);
    }

    const lifeRating = {
      partyId: rider.payor.id,
      permanentPercent: rider.ratings[rider.payor.id].permRatingPercent,
    };

    riderCoverage.life = [life];
    riderCoverage.lifeRating = [lifeRating];
    riderClient.rating &&
      (riderCoverage.lifeRating = [{ partyId: life.partyId, permanentPercent: riderClient.rating }]);
    return riderCoverage;
  };

  getOtherRider = (riderClient, riderName, client, clientId) => {
    const { policyData, app } = this.props;

    if (policyData.product === eProduct.LC) {
      riderName = riderName === eRider.clc ? eRider.cpr : riderName;
      riderName = riderName === eRider.wpd ? eRider.tdw : riderName;
      riderName = riderName === eRider.prw ? eRider.tdwpayor : riderName;
    }

    const riderCoverage = {
      coverageId: riderClient.riderId,
      // effectiveDate: policyData.effectiveDate,
      product: riderName,
    };

    const life = {
      partyId: clientId,
      age: client.age,
      healthstyle: this.getHealthStyle(policyData.product, client),
    };

    if (app.inforcePolicy) {
      life.healthstyle = this.getInforceHealthStyle(policyData.product, riderClient);
    }

    riderClient.amount && (riderCoverage.coverageAmount = riderClient.amount);
    riderCoverage.life = [life];
    riderClient.rating && (riderCoverage.lifeRating = [{ partyId: clientId, permanentPercent: riderClient.rating }]);
    return riderCoverage;
  };

  allRiderCoverages = () => {
    const { clients, riders, termRiders } = this.props;
    const allRiderCoverages = [];

    if (termRiders) {
      _.forEach(termRiders.allRiders, (riderId) => {
        allRiderCoverages.push(this.getTermRider(termRiders[riderId]));
      });
    }

    _.forEach(riders, (rider, riderName) => {
      if (rider.isSelected) {
        switch (riderName) {
          case eRider.cpr:
            _.forEach(rider.allChildren, (childId) => {
              allRiderCoverages.push(this.getCprRider(rider, riderName, childId));
            });
            break;
          case eRider.tdwpayor:
            allRiderCoverages.push(this.getTdwRider(rider, riderName));
            break;
          default:
            _.forEach(clients.allClients, (clientId) => {
              const riderClient = rider[clientId];

              if (riderClient && riderClient.isSelected) {
                allRiderCoverages.push(this.getOtherRider(riderClient, riderName, clients[clientId], clientId));
              }
            });
        }
      }
    });

    return allRiderCoverages;
  };

  generateInforceWithdrawal = (withdrawalData) => {
    if (withdrawalData[eField.withdrawalTypeOption] !== eWithdrawalType.none) {
      return this.generateWithdrawal(withdrawalData);
    }
    return null;
  };

  generateWithdrawal = (withdrawalData) => {
    if (!withdrawalData) {
      return {};
    }
    const { durationType, durationStart, durationEnd, type, amount, durationPartyId, isFolded, customAmount } =
      withdrawalData;
    return {
      ...withdrawalData,
      ...(customAmount && customAmount.length && { customAmount }),
      ...(!customAmount && { amount }),
      durationType,
      durationStart,
      durationEnd,
      type,
      durationPartyId,
      isFolded,
    };
  };

  generatePolicyCoverages = () => {
    const { app } = this.props;
    const policyData = {};
    if (!app.inforcePolicy) {
      policyData.coverage = [...this.baseCoverages(), ...this.allRiderCoverages()];
    } else {
      policyData.coverage = app.inforceData.policy.coverage;
      policyData.businessType = eBusinessType.IF;
      policyData.policyNumber = app.inforceData.policy.policyNumber;
    }
    return policyData;
  };

  getPolicyStateHelperFunctions = (generateIllustration) => {
    const ownerId = (prepFor) => ({ ownerId: (prepFor && getReportPreparedForId()) || null });
    const advisorId = (currentProfileId) => (currentProfileId !== eDefault.advisorProfile ? currentProfileId : null);
    const product = (prod) => (prod === eProduct.FTRC ? eProduct.FT : prod);
    const depositAmount = (cov) => (cov.isCoverageSolve ? null : 0);
    const solveType = (cov) => (cov.isCoverageSolve && !generateIllustration ? eSolveType.coverage : eSolveType.none);
    return { ownerId, advisorId, product, depositAmount, solveType };
  };

  policy = () => {
    const { policyData, coverages, reqType, report = {}, advisorProfile, withdrawal, deposits } = this.props;
    const coverage = coverages[coverages.activeTabId];
    const generateIllustration = reqType === eUDMReqType.report || reqType === eUDMReqType.spreadsheet;
    const solve = this.getSolve(policyData, coverage);

    const depositOption = this.getDepositOption(policyData, deposits, coverage);
    const depositOptionCoverageSolve = this.getDepositOptionCoverageSolve(solve);

    const premiumOffset = this.getPremiumOffset(policyData);
    const rates = this.getRates(policyData);

    // this logic used to be set in 'ans' below but is now here
    // in order to reduce the supposed complexity of this method, go figure

    const { ownerId, advisorId, product, depositAmount, solveType } =
      this.getPolicyStateHelperFunctions(generateIllustration);

    const businessType = getBusinessType(policyData.product);
    const polData = {
      ...(policyData.product !== eProduct.MUL && ownerId(report.preparedFor)),
      advisorId: advisorId(advisorProfile.currentProfileId),
      businessType,
      product: product(policyData.product),
      depositAmount: depositAmount(coverage),
      ...(coverage.isCoverageSolve
        ? depositOptionCoverageSolve && { depositOption: depositOptionCoverageSolve }
        : depositOption && { depositOption }),
      ...(policyData.product === eProduct.PAR && coverage.isCoverageSolve && { solve }),
      // It should be the current date all the time for non-HO users.
      effectiveDate: moment(policyData.effectiveDate, 'DD/MM/YYYY').format(eDefault.udmDate),
      ...(premiumOffset && { premiumOffset }),
      ...(rates && { rates }),
      specialQuote: 'None',
      ...(withdrawal &&
        withdrawal[eField.withdrawalTypeOption] !== eWithdrawalType.none && {
          withdrawal: { ...this.generateWithdrawal(withdrawal) },
        }),
      // when going to spreadsheet or reports, make sure solve for coverage is turned off
      ...(policyData.product !== eProduct.PAR && { solveType: solveType(coverage) }),
      ...this.generatePolicyCoverages(),
      depositMode: this.getDepositMode(policyData, deposits),
      vitality: this.getVitality(),
    };

    return polData;
  };

  getDepositMode = (policyData, deposits) => {
    switch (policyData.product) {
      case eProduct.MUL:
        return deposits.depositFrequency;
      default:
        return policyData.premiumFrequency;
    }
  };

  depositOptionMul = (policyData, deposits) => {
    const { depositOptionCustomAmount, isDepositOptionCustom } = policyData;
    const amount =
      deposits[eField.depositType] === eDepositType.specified && !isDepositOptionCustom ? deposits.depositAmount : null;
    return {
      type: isDepositOptionCustom && depositOptionCustomAmount ? 'Custom' : deposits[eField.depositType],
      duration: deposits[eField.durationTo],
      durationPartyId: deposits.durationPartyId,
      durationStart: deposits.durationStart,
      durationEnd: deposits.durationEnd,
      ...((amount || amount === 0) && { modalAmount: amount }),
      durationType: deposits[eField.duration],
      additionalDeposit: deposits[eField.depositAdditionalDeposit],
      ...(deposits.durationType === eDurationType.age && { depositAge: deposits[eField.durationTo] }),
      ...(depositOptionCustomAmount && isDepositOptionCustom && { customAmount: depositOptionCustomAmount }),
    };
  };

  depositOptionInforce = (policyData, deposits) => {
    const amount =
      policyData.additionOptionType === eDepositOptionType.specified ? policyData.depositOptionAmount : null;
    return {
      type: policyData.additionOptionType,
      ...((amount || amount === 0) && { modalAmount: amount }),
      durationType: policyData.depositOptionDurationType,
      duration: policyData.depositOptionDuration,
      additionalDeposit: deposits[eField.depositAdditionalDeposit] || 0,
    };
  };

  depositOption = (policyData) => {
    const { depositOptionCustomAmount, isDepositOptionCustom } = policyData;
    const amount =
      policyData.depositOptionType === eDepositOptionType.specified && !isDepositOptionCustom
        ? policyData.depositOptionAmount
        : null;
    return {
      type: isDepositOptionCustom && depositOptionCustomAmount ? 'Custom' : policyData.depositOptionType,
      ...((amount || amount === 0) && { modalAmount: amount }),
      durationType: policyData.depositOptionDurationType,
      duration: policyData.depositOptionDuration,
      ...(depositOptionCustomAmount && isDepositOptionCustom && { customAmount: depositOptionCustomAmount }),
    };
  };

  getDepositOption = (policyData, deposits) => {
    const { product } = policyData;
    if (!ProductConfig[product].hasDepositOption) {
      return null;
    }

    switch (product) {
      case eProduct.MUL:
        return this.depositOptionMul(policyData, deposits);
      case eProduct.PG:
        return this.depositOptionInforce(policyData, deposits);
      default:
        return this.depositOption(policyData);
    }
  };

  getDepositOptionCoverageSolve = (solve) => {
    if (solve && solve[0]?.type === eCoverageSolveType.minLevel) {
      return {
        type: eDepositType.specified,
        durationType: eDepositOptionDurationType.sameasbase,
      };
    }
    return null;
  };

  getSolve = (policyData, coverage) => {
    return [
      {
        type: policyData.includeMaxLevelDepositOption ? eCoverageSolveType.minLevel : eCoverageSolveType.maxLevel,
        coverageId: coverage.id,
        targetAmount: coverage.premiumAmount,
      },
    ];
  };

  getRates = (policyData) => {
    if (!ProductConfig[policyData.product].hasRates) {
      return null;
    }
    let rates = {
      marginalTaxRate: policyData.marginalTaxRate,
    };
    if (policyData.product === eProduct.MUL) {
      /*
        for MUL, primaryInterestRate and secondaryInterestRate
        are of type ModalAmount, which is a list of strings
        in the format ["startYear:value"] where startYear is an integer
        and value is an integer
        for MUL we would only have a startYear of 1 since PrimaryInterestRate is a single value
       */
      rates = {
        ...rates,
        primaryInterestRate: [this.transformModalAmount(1, policyData.policyInterestRate)],
        sideAccountInterestRate: [this.transformModalAmount(1, policyData.sideAccountInterestRate)],
      };
    } else if (policyData.product === eProduct.PG) {
      return {
        ...rates,
        primaryPerformanceCreditRate: policyData.performanceCreditRate,
        alternatePerformanceCreditRate: policyData.altPerformanceCreditRate,
        sideAccountInterestRate: [this.transformModalAmount(1, policyData.sideAccountInterestRate)],
      };
    } else {
      rates = {
        ...rates,
        alternateDividendScale: policyData.alternateDividendScale,
        primaryDividendScale: policyData.primaryDividendScale,
      };
    }
    return rates;
  };

  transformModalAmount = (startYear, value) => `${startYear}:${value}`;

  getPremiumOffset = (policyData) => {
    if (!ProductConfig[policyData.product].hasPremiumOffsets) {
      return null;
    }
    const premiumOffset = { type: policyData.premiumOffset, alternateStartYear: null };
    if (premiumOffset.type === ePremiumOffset.startingatyear) {
      premiumOffset.startYear = policyData.premiumOffsetYear;
    }

    return premiumOffset;
  };

  getPremiumOffsetInforce = (policyData, inforcePolicy) => {
    let premiumOffsetData;
    if ([ePremiumOffset.sameaspolicy, ePremiumOffset.paymentcoverpolicycosts].includes(policyData.premiumOffset)) {
      if (policyData.premiumOffsetIndicator) {
        premiumOffsetData = { type: ePremiumOffset.no };
      } else {
        return null;
      }
    } else {
      premiumOffsetData = {
        type: policyData.premiumOffset,
        ...(policyData.premiumOffset === ePremiumOffset.startingatyear && { startYear: policyData.premiumOffsetYear }),
        ...(policyData.premiumOffset === ePremiumOffset.premiumholiday && {
          startYear: policyData.premiumOffsetYearFrom,
          endYear: policyData.premiumOffsetYearTo,
        }),
      };
    }

    if (policyData.product === eProduct.PAR && inforcePolicy.premiumOffset) {
      premiumOffsetData.initialType = inforcePolicy.premiumOffset.type;
    }

    return premiumOffsetData;
  };

  isPremiumOffsetSet = (policyData) => {
    switch (policyData.premiumOffset) {
      case ePremiumOffset.sameaspolicy:
        return policyData.premiumOffsetIndicator;
      case ePremiumOffset.no:
        return false;
      default:
        return true;
    }
  };
}
