export const eAKSRegion = {
  // Should match REACT_APP_API_HOST values
  dev: 'dev',
  sit: 'sit',
  uat: 'cac-uat',
  prod: 'prod',
  uat_dr: 'cae-uat',
  prod_dr: 'prod_dr',
};

export const isGatewayLocalHost = process.env.REACT_APP_IS_GATEWAY_LOCALHOST === 'true';
export let apiHost = window?.REACT_APP_API_HOST || process.env.REACT_APP_API_HOST;
export const showApplicationsTab =
  window?.REACT_APP_SHOW_APPLICATIONS_TAB === 'true' || process.env.REACT_APP_SHOW_APPLICATIONS_TAB === 'true';

const isProd = apiHost === eAKSRegion.prod || apiHost === eAKSRegion.prod_dr;

const inactiveEndpointSit = `https://sit.id.manulife.ca/advisor?sign-out=inactive`;
const inactiveEndpointUat = `https://uat.id.manulife.ca/advisor?sign-out=inactive`;
const inactiveEndpointProd = `https://id.manulife.ca/advisor?sign-out=inactive`;

export const vitalityStatusSimulatorEnUrl =
  'https://www.manulife.ca/personal/vitality/vitality-status-savings-tool.html';
export const vitalityStatusSimulatorFrUrl =
  'https://www.manuvie.ca/particuliers/vitalite/simulateur-recompenses-statut-vitalite.html';

const getInactiveEndpoint = () => {
  let inactiveEndpointCurrent;

  if (isGatewayLocalHost === true) {
    inactiveEndpointCurrent = inactiveEndpointSit;
  } else if (apiHost === eAKSRegion.dev) {
    inactiveEndpointCurrent = inactiveEndpointSit;
  } else if (apiHost === eAKSRegion.sit) {
    inactiveEndpointCurrent = inactiveEndpointSit;
  } else if (apiHost === eAKSRegion.uat) {
    inactiveEndpointCurrent = inactiveEndpointUat;
  } else if (apiHost === eAKSRegion.uat_dr) {
    inactiveEndpointCurrent = inactiveEndpointUat;
  } else if (apiHost === eAKSRegion.prod) {
    inactiveEndpointCurrent = inactiveEndpointProd;
  } else if (apiHost === eAKSRegion.prod_dr) {
    inactiveEndpointCurrent = inactiveEndpointProd;
  } else {
    inactiveEndpointCurrent = inactiveEndpointSit;
  }

  return inactiveEndpointCurrent;
};

export const getAdvisorPortalEndpoint = (intl) => {
  let advisorPortalEndpoint;
  if (isGatewayLocalHost) {
    advisorPortalEndpoint = intl.formatMessage({ id: 'utilnav.advisorportal.sit.link' });
  }
  switch (apiHost) {
    case eAKSRegion.dev:
    case eAKSRegion.sit:
      advisorPortalEndpoint = intl.formatMessage({ id: 'utilnav.advisorportal.sit.link' });
      break;
    case eAKSRegion.uat:
    case eAKSRegion.uat_dr:
      advisorPortalEndpoint = intl.formatMessage({ id: 'utilnav.advisorportal.uat.link' });
      break;
    case eAKSRegion.prod:
    case eAKSRegion.prod_dr:
      advisorPortalEndpoint = intl.formatMessage({ id: 'utilnav.advisorportal.prod.link' });
      break;
    default:
      break;
  }
  return advisorPortalEndpoint;
};

export const getContactUsEndpoint = (intl) => {
  let advisorPortalEndpoint;
  if (isGatewayLocalHost) {
    advisorPortalEndpoint = intl.formatMessage({ id: 'utilnav.contactus.sit.link' });
  }
  switch (apiHost) {
    case eAKSRegion.dev:
    case eAKSRegion.sit:
      advisorPortalEndpoint = intl.formatMessage({ id: 'utilnav.contactus.sit.link' });
      break;
    case eAKSRegion.uat:
    case eAKSRegion.uat_dr:
      advisorPortalEndpoint = intl.formatMessage({ id: 'utilnav.contactus.uat.link' });
      break;
    case eAKSRegion.prod:
    case eAKSRegion.prod_dr:
      advisorPortalEndpoint = intl.formatMessage({ id: 'utilnav.contactus.prod.link' });
      break;
    default:
      break;
  }
  return advisorPortalEndpoint;
};

export const getAdvisorPortalRegistrationEndpointNoIntl = () => {
  let advisorPortalEndpoint;
  if (isGatewayLocalHost) {
    advisorPortalEndpoint = 'https://advisor.sit.manulife.com/registration';
  }
  switch (apiHost) {
    case eAKSRegion.dev:
    case eAKSRegion.sit:
      advisorPortalEndpoint = 'https://advisor.sit.manulife.com/registration';
      break;
    case eAKSRegion.uat:
    case eAKSRegion.uat_dr:
      advisorPortalEndpoint = 'https://uat.advisor.manulife.ca/registration';
      break;
    case eAKSRegion.prod:
    case eAKSRegion.prod_dr:
      advisorPortalEndpoint = 'https://advisor.manulife.ca/registration';
      break;
    default:
      break;
  }
  return advisorPortalEndpoint;
};

export const getAdvisorPortalLandingPageLink = (lang) => {
  let endpointLink;
  if (isGatewayLocalHost) {
    endpointLink = 'https://advisor.sit.manulife.com/landing';
  }
  if (lang === 'fr') {
    switch (apiHost) {
      case eAKSRegion.dev:
      case eAKSRegion.sit:
        endpointLink = 'https://advisor-fr.sit.manulife.com/landing';
        break;
      case eAKSRegion.uat:
      case eAKSRegion.uat_dr:
        endpointLink = 'https://uat.conseiller.manuvie.ca/landing';
        break;
      case eAKSRegion.prod:
      case eAKSRegion.prod_dr:
        endpointLink = 'https://conseiller.manuvie.ca/landing';
        break;
      default:
        break;
    }
  } else {
    switch (apiHost) {
      case eAKSRegion.dev:
      case eAKSRegion.sit:
        endpointLink = 'https://advisor.sit.manulife.com/landing';
        break;
      case eAKSRegion.uat:
      case eAKSRegion.uat_dr:
        endpointLink = 'https://uat.advisor.manulife.ca/landing';
        break;
      case eAKSRegion.prod:
      case eAKSRegion.prod_dr:
        endpointLink = 'https://advisor.manulife.ca/landing';
        break;
      default:
        break;
    }
  }

  return endpointLink;
};

const getBaseEndpointUrl = () => {
  if (isProd || apiHost === 'cac-www' || apiHost === 'cae-www') {
    return 'https://www.insurance.manulife.ca';
  } else if (isGatewayLocalHost) {
    apiHost = 'dev';
    return 'https://127.0.0.1:5443';
  } else {
    return `https://${apiHost}-insurance.mfcgd.com`;
  }
};

export const inactiveEndpoint = getInactiveEndpoint();
const calcEndpoint = 'calc';
const reportEndpoint = 'report';
const persistenceEndpoint = 'persistence';
const inforceRequestFormEndpoint = 'inforcedb'; //cdtwipo-6105
const policyLookupEndpoint = 'inforce/policydata';
const policyEntitledEndpoint = 'inforce/entitledpolicies';

const policyLookupAuthenticateEndpoint = 'authenticate';
const ddaEndpoint = 'dda/enrollment';
const tcaEndpoint = 'tca/enrollment';
const authEndpoint = 'auth';
const apiEndpoint = 'api';
const baseEndPoint = getBaseEndpointUrl();

export const redirectAuth = `${baseEndPoint}/${apiEndpoint}/${authEndpoint}/secure?redirectUrl=${window.location.href}`;
export const logoutUrl = `${baseEndPoint}/${apiEndpoint}/${authEndpoint}/logout`;

// deal with SSO
export const JUNCTION = '/illustrations';

export const isSpreadsheetView = (pathname) => pathname === configs.routes.spreadsheet;

export const eLocale = {
  en: 'en',
  fr: 'fr',
};

export const eLang = {
  en: 'English',
  fr: 'French',
};

const millisecondsInSeconds = 1000;
const minutesInHour = 60;
const secondsInMinutes = 60;
const convertMilliSecondsToHours = millisecondsInSeconds * secondsInMinutes * minutesInHour;
const sessionActiveTimeHours = 24;
const sessionIdleTimeHours = 4;

export const sessionConfig = {
  active24Time: convertMilliSecondsToHours * sessionActiveTimeHours, //24 hrs in ms
  idleTime: convertMilliSecondsToHours * sessionIdleTimeHours, // 4 hours in ms,
  active24CountDown: 1, //1 min
  idleCountDown: 5, //5 min
};

export const eSessionType = {
  idle: '0',
  active24: '1',
};

export const eProduct = {
  ALL: 'All',
  BT: 'BusinessTerm',
  FT: 'FamilyTerm',
  FTRC: 'FamilyTermRenewalCalculator',
  FTV: 'FamilyTermVitality',
  GI: 'Manulife Guaranteed Issue Life',
  IF: 'Inforce',
  // deepcode ignore HardcodedNonCryptoSecret: False Alarm
  IV: 'InnoVision',
  LC: 'Lifecheque',
  LC_DDA: 'LifeCheque', // In the future products from Applications should and Dashboard need to align to one spelling (Lifecheque or LifeCheque)
  MUL: 'Mul',
  PAR: 'Par',
  Performax: 'Performax',
  PG: 'PerformaxGold',
  SB: 'SecurityBuilder',
  SY: 'Synergy',
  TermInforce: 'TermRenewalInforce',
};

export const eEnhancerType = {
  ECV: 'ECV',
  PUI: 'PUI',
  YTI: 'YTI',
  YTIPlus: 'YTIPlus',
};

export const changeToBronzeClientAgeDifference = {
  PAR: 1,
  MUL: 2,
};

export const eBusinessType = {
  NB: 'NewBusiness',
  IF: 'Inforce',
};

export const eStatuses = {
  inforce: 'Inforce',
  waiver: 'Waiver',
};

export const eInforceErrorTypes = {
  baseCoveragesExceedMaximumSupportedNumber: 'landing.inforce.error.UseSpecialRequestForm',
  cprCoveragesExceedMaximumSupportedNumber: 'landing.inforce.error.CPRNotSupported',
  leadTimeValidation: 'landing.inforce.error.leadTimeValidation',
  notIllustratable: 'landing.inforce.error.notillustratable',
  noPaymentsError: 'landing.inforce.error.NoPaymentsError',
  paidUpPolicy: 'landing.inforce.error.paidUpPolicy',
  productMismatch: 'landing.inforce.error.productmismatch',
  productNotSupported: 'landing.inforce.error.notillustratable',
  redFlag: 'landing.inforce.error.redflag',
  redFlagUnknown: 'landing.inforce.error.unavailable',
  specialQuoteNotSupported: 'landing.inforce.error.specialquote',
  specialQuoteNotSupportedPAR: 'landing.inforce.error.specialquote.PAR',
  specialRequestFormProduct: 'landing.inforce.error.UseSpecialRequestForm',
  termOptionNotSupported: 'landing.inforce.error.TermOptionNotSupported',
  termRiderCoveragesExceedMaximumSupportedNumber: 'landing.inforce.error.UseSpecialRequestForm',
  termOptionPlusCoverageWithoutTermOptionCoverageNotSupported:
    'landing.inforce.error.TermOptionPlusCoverageWithoutTermOptionCoverageNotSupported',
  unauthorized: 'landing.inforce.error.unauthorized',
  unavailable: 'landing.inforce.error.unavailable',
  vitalityNotSupported: 'landing.inforce.error.vitalityplus',
  vitalityNotSupportedTermInforce: 'landing.inforce.error.vitalityplus.terminforce',
  waiverNotSupported: 'landing.inforce.error.waiver',
};

export const eInforceErrorStatuses = {
  specialRequestFormProduct: 'specialRequestFormProduct',
};

export const ePolicyNumberLength = {
  [eProduct.MUL]: 7,
  [eProduct.IF]: 15,
  [eProduct.TermInforce]: 15,
};

export const eTermRenewalClient = {
  defaultId: 'Client.1',
};

export const coverageConfig = {
  minAmount: 0,
  maxAmount: 999999999,
};

export const performaxCoverageConfig = {
  toFaceAmount: {
    minAmount: 1000,
  },
  termOptionPlus: {
    minAmount: (maxTop) => 0.25 * maxTop,
    maxAmount: (maxTop) => 2.0 * maxTop,
  },
};

export const switchYearConfig = {
  default: 5,
  minAmount: 4,
  maxAmount: 100, // ! has to be calulated by validation service
};

export const AccValuePerDeath = {
  minAmount: 1,
  maxAmount: 100,
};

export const eCoverageType = {
  single: 'Single',
  combined: 'CombinedTir',
  jointFirst: 'JointFtd',
  jointLast: 'JointLtd',
  jointLastPayFirstDeath: 'JointLtdCtf',
  jointLastPayLastDeath: 'JointLtdCtl',
};

export const eCoverageOption = {
  t05: 'Term05',
  t10: 'Term10',
  t20: 'Term20',
  t65: 'Term65',
  tlife: 'TermLife',
};

export const eCostOfInsuranceType = {
  r05: 'Renewable05',
  r10: 'Renewable10',
  level65: 'Level65',
};

export const eSpecialQuote = {
  none: 'None',
  S1: 'OneYearRateAgeSetback',
  H1: 'OneYearRateAgeSetbackHeadOffice',
  S2: 'TwoYearRateAgeSetback',
};

export const eWithdrawalType = {
  before: 'beforeTax',
  after: 'afterTax',
  none: 'None',
};

export const eWithdrawalAmount = {
  min: 100,
  max: 999999999,
};

export const eWithdrawalAmountPMax = {
  min: 500,
  max: 999999999,
};

export const eDividendOption = {
  pui: 'Pui',
  cash: 'Cash',
};

export const ePerformaxDividendOption = {
  pui: 'Pui',
  cash: 'Cash',
  termOption: 'TermDividendOption',
  Accumulation: 'AccumulatedInterest',
  AccumulationMaintainExistingPUI: 'AccumulatedInterestMaintainPui',
  AccumulationTransferPUIToAccumulation: 'AccumulatedInterestTransferPuiToAccum',
  PremiumReduction: 'ReducedPremium',
  LoanReduction: 'ReducedLoan',
};

export const ePerformanceCreditOption = {
  pui: 'Pui',
  accumulatedInterest: 'AccumulatedInterest',
};

export const eDividendScale = {
  current: 'Current',
  lessHalf: 'CurrentLess50bp',
  lessOne: 'CurrentLess100bp',
  lessOneHalf: 'CurrentLess150bp',
  lessTwo: 'CurrentLess200bp',
  lessTwohalf: 'CurrentLess250bp',
};

export const eConceptDividendScale = {
  primary: 'Primary',
  alternate: 'Alternate',
};

export const eSolveFor = {
  premium: 'P',
  coverage: 'C',
};

export const eReqStatus = {
  success: 'SUCCESS',
  generating: 'GENERATING',
  warning: 'WARNING',
  error: 'ERROR',
  reqError: 'REQUESTERROR',
  cancelled: 'CANCEL',
};

export const persistenceErrors = {
  duplicate: 'Duplicate Name',
};

export const eVitalityType = {
  none: 'None',
  plus: 'Plus',
};

export const eVitalityStatus = {
  na: 'NA',
  platinum: 'Platinum',
  gold: 'Gold',
  silver: 'Silver',
  bronze: 'Bronze',
};

export const eVitalityStatusNum = {
  0: eVitalityStatus.na,
  1: eVitalityStatus.platinum,
  2: eVitalityStatus.gold,
  3: eVitalityStatus.silver,
  4: eVitalityStatus.bronze,
};

export const eSex = {
  male: 'Male',
  female: 'Female',
};

export const eUserType = {
  advisor: 'Advisor',
  headOffice: 'HeadOffice',
};

export const eRole = {
  advisor: 'ADV',
  headOffice: 'HO',
  backOffice: 'OFFICE',
  marketingAssistant: 'MA',
  licensedMarketingAssistant: 'AMA',
  lifeSpecialist: 'LS',
};

export const eUserTypeSAML = {
  ADV: eUserType.advisor,
  HO: 'MIHO',
};

export const ePremiumFrequency = {
  monthly: 'Monthly',
  annually: 'Annually',
  quarterly: 'Quarterly',
  semiannually: 'SemiAnnually',
};

export const ePremiumDuration = {
  pay10: 'Pay10',
  pay20: 'Pay20',
  Pay90: 'Pay90',
  Pay100: 'Pay100',
};

export const eColumnToHide = {
  VitalityStatus: 'VitalityStatus',
};

export const ePremiumOffsetMinYear = {
  advisorStartingAtMinYear: 6,
  headOfficeStartingAtMinYear: 1,
};

export const ePremiumOffset = {
  no: 'None',
  earliest: 'Earliest',
  startingatyear: 'StartingAt',
  sameaspolicy: 'SameAsPolicy',
  inforce: 'Inforce',
  paymentcoverpolicycosts: 'PaymentCoverPolicyCosts',
  premiumholiday: 'PremiumHoliday',
};

export const eDepositFrequency = {
  monthly: 'Monthly',
  annual: 'Annually',
};

export const eCostDuration = {
  pay100: 'Pay100',
  pay15: 'Pay15',
};

export const eDepositOwnerResidence = {
  select: 'Select',
  alberta: 'AB',
  britishColumbia: 'BC',
  manitoba: 'MB',
  newBrunswick: 'NB',
  newfoundlandAndLabrador: 'NL',
  northwestTerritories: 'NT',
  novaScotia: 'NS',
  nunavut: 'NU',
  ontario: 'ON',
  princeEdwardIsland: 'PE',
  quebec: 'QC',
  saskatchewan: 'SK',
  yukon: 'YT',
  notApplicable: 'NotApplicable',
};

export const eDepositType = {
  specified: 'Specified',
  levelmax: 'LevelMax',
  levelmin: 'LevelMin',
  yearlymin: 'YearlyMin',
};

export const eDepositDurationType = {
  age: 'Age',
  year: 'Year',
};

export const eDepositOptionType = {
  none: 'None',
  specified: 'Specified',
  levelmax: 'LevelMax',
  custom: 'Custom',
};

export const eAdditionOptionType = {
  specified: 'Specified',
  levelmax: 'LevelMax',
};

export const eDepositOptionDurationType = {
  year: 'Year',
  sameasbase: 'Base',
};
export const eAdditionOptionDurationType = {
  year: 'Year',
  sameasbase: 'Base',
};

export const eRider = {
  tdw: 'TDW',
  bvp: 'BVP',
  gio: 'GIO',
  adnd: 'ADD',
  adb: 'ADB',
  cpr: 'CPR',
  tdwpayor: 'PTDW',
  term: 'TERM',
  tor: 'TOR',
  spr: 'SPR',
  clc: 'CLC',
  wpd: 'WPD',
  prw: 'PRW',
  pwdd: 'PWD',
  rop: 'ROP',
  ppr: 'PPR',
};

export const eInvestmentAllocation = {
  interest: 'interest',
  dividends: 'dividends',
  deferredCapitalGains: 'deferredCapitalGains',
  realizedCapitalGains: 'realizedCapitalGains',
};

export const eRiderAbbr = {
  [eRider.tdw]: 'tdw',
  [eRider.bvp]: 'bvp',
  [eRider.gio]: 'gio',
  [eRider.adnd]: 'adnd',
  [eRider.adb]: 'adb',
  [eRider.cpr]: 'cpr',
  [eRider.tdwpayor]: 'tdw.payor',
};

export const eHS = {
  hs1: '1',
  hs2: '2',
  hs3: '3',
  hs4: '4',
  hs5: '5',
};

export const eSmokingStatus = {
  nonsmoker: 'NonSmoker',
  smoker: 'Smoker',
};

export const eSolveType = {
  none: 'Premium',
  coverage: 'Coverage',
};

export const eReport = {
  // TODO for MVP, only UR should be checked
  // options: ['cpd', 'cd', 'pd', 'ps', 'rp', 'td', 'ur', 'vpgnb', 'vp', 'vpc'],
  options: ['cd', 'irr', 'pod', 'pos', 'tcop', 'tpop', 'tpcd', 'td', 'ur'],
  cpd: 'coveragePaymentDetails',
  cd: 'coverageDetails',
  irr: 'irrValues',
  pd: 'policyDetails',
  pod: 'offsetDetailed',
  pos: 'offsetSummary',
  ps: 'productSummary',
  rp: 'riderPremiums',
  td: 'taxDetails',
  tcop: 'corporateTaxValues',
  tpop: 'personalTaxValues',
  tpcd: 'cashTaxValues',
  ur: 'underwritingRequirements',
  vpgnb: 'vitalityPremiumsGoldAndBronze',
  vp: 'vitalityPoints',
  vpc: 'vitalityComparison',
};

export const eReportType = {
  base: 'Base',
  uw: 'Underwriting',
  comm: 'Commissions',
  pp: 'ProductPage',
  cc: 'CoverageCompare',
  concept: 'Concept',
};

export const eDeathType = {
  facePlus: 'FacePlus',
  level: 'Level',
  accValEachDeath: 'EachDeath',
};

export const eCOIType = {
  levelToAge100: 'Level100',
  levelFor10Years: 'Level10',
  levelFor20Years: 'Level20',
  yrtToAge85: 'Yrt8520',
  yrt85: 'Yrt85',
  yrt100: 'Yrt100',
};

export const eInsuranceType = {
  specified: 'Specified',
  minLevel: 'LevelMinimum',
  maxLevel: 'LevelMaximum',
};

export const eCoverageSolveType = {
  minLevel: 'CoverageLevelMinimum',
  maxLevel: 'CoverageLevelMaximum',
};

export const eReportLayoutType = {
  allyears: 'AllYears',
  condensed: 'Condensed',
};

export const eUDMReqType = {
  report: 'report',
  spreadsheet: 'spreadsheet',
  coverageCompare: 'coverageCompare',
};

export const eUDMType = {
  validation: 'Validation',
  illustration: 'Full',
  coverageCompare: 'CoverageCompare',
  summary: 'Summary',
};

// For Analytics --------------------
export const ePageId = {
  spreadsheet: 'Spreadsheet',
  clientcoverage: 'ClientCoverage',
  termRenewalCalc: 'TermRenewalCalc',
  report: 'CustomizeReport',
  concepts: 'Concepts',
  mycases: 'MyCases',
  scenarios: 'Scenarios',
  profile: 'Profile',
  landingPage: 'LandingPage',
  designReport: 'DesignReport',
};

export const eActionEvent = {
  illustration: 'illustration',
  download: 'download',
  signin: 'sign-in',
  selection: 'selection',
  error: 'error',
};

export const eTabName = {
  0: 'Coverage',
  1: 'Payments',
  2: 'Riders',
  3: 'Rates',
  4: 'Concepts',
};
//-----------------------------------

export const eField = {
  selectClicked: 'selectOwnersResidenceClicked',
  product: 'product',
  scenario: 'scenario',
  coverage: 'coverage',
  effectiveDate: 'effectiveDate',
  prodError: 'productValidationError',
  fname: 'firstName',
  lname: 'lastName',
  fullName: 'fullName',
  age: 'age',
  sex: 'sex',
  healthstyle: 'healthstyle',
  smokingStatus: 'smokingstatus',
  dob: 'dob',
  cov: 'coverage',
  covType: 'coverageType',
  szInsurType: 'sizeOfInsuranceType',
  coverageDate: 'coverageDate',
  covOption: 'coverageOption',
  covOptions: 'coverageOptions',
  quoteOption: 'specialQuoteOption',
  divOption: 'dividendOption',
  divScale: { primary: 'primaryDividendScale', alternate: 'alternateDividendScale' },
  frequency: 'premiumFrequency',
  coverageAmount: 'coverageAmount',
  coverageDuration: 'coverageDuration',
  premiumAmount: 'premiumAmount',
  premiumFrequency: 'premiumFrequency',
  premiumDuration: 'premiumDuration',
  premiumOffset: 'premiumOffset',
  premiumOffsetYear: 'premiumOffsetYear',
  premiumOffsetYearFrom: 'premiumOffsetYearFrom',
  premiumOffsetYearTo: 'premiumOffsetYearTo',
  depositOwnerResidence: 'depositOwnerResidence',
  depositFrequency: 'depositFrequency',
  depositType: 'depositType',
  depositAmount: 'depositAmount',
  depositDurationType: 'depositDurationType',
  depositDuration: 'depositDuration',
  depositAdditionalDeposit: 'additionalDeposit',
  depositOptionType: 'depositOptionType',
  additionOptionType: 'additionOptionType',
  depositOptionAmount: 'depositOptionAmount',
  depositOptionDurationType: 'depositOptionDurationType',
  additionOptionDurationType: 'additionOptionDurationType',
  depositOptionDuration: 'depositOptionDuration',
  additionOptionDuration: 'additionOptionDuration',
  isCoverageSolve: 'isCoverageSolve',
  solve: 'solveFor',
  switchToCashDividends: 'switchToCashDividends',
  switchToCashDividendsYear: 'switchToCashDividendsYear',
  marginalTaxRate: 'marginalTaxRate',
  policyInterestRate: 'policyInterestRate',
  performanceCreditRate: 'performanceCreditRate',
  altPerformanceCreditRate: 'altPerformanceCreditRate',
  sideAccountInterestRate: 'sideAccountInterestRate',
  advisorName: 'advisorName',
  companyName: 'companyName',
  phoneNumber: 'phoneNumber',
  extension: 'extension',
  email: 'email',
  residence: 'residence',
  advisorCodes: 'advisorCodes',
  advisorPartyMap: 'advisorPartyMap',
  shareList: 'shareList',
  entitlementRole: 'entitlementRole',
  partyId: 'partyId',
  corporateTaxRate: 'corporateTaxRate',
  personalDividendTaxRate: 'personalDividendTaxRate',
  interestRate: 'interestRate',
  interest: 'interest',
  dividendRate: 'dividendRate',
  deferredCapitalGainsRate: 'deferredCapitalGainsRate',
  realizedCapitalGainsRate: 'realizedCapitalGainsRate',
  dividend: 'dividend',
  dividends: 'dividends',
  deferredCapitalGains: 'deferredCapitalGains',
  realizedCapitalGains: 'realizedCapitalGains',
  deathAtAge: 'deathAtAge',
  additionalYears: 'additionalYears',
  concept: 'concept',
  illustrateChecked: 'isIllustrateChecked',
  alternateInvestment: 'alternateInvestment',
  allocation: 'allocation',
  rateOfReturns: 'rateOfReturns',
  taxInformation: 'taxInformation',
  taxInformationRates: 'taxInformationRates',
  dividendScale: 'dividendScale',
  dividendScaleType: 'type',
  lifeExpectancyAssumption: 'lifeExpectancyAssumption',
  borrowingType: 'borrowingType',
  investmentIncomeTax: 'investmentIncomeTax',
  companyType: 'companyType',
  operatingIncomeTax: 'operatingIncomeTax',
  presentValueDiscountRate: 'presentValueDiscountRate',
  corporateIRPStructure: 'corporateIRPStructure',
  csvLoanPercentage: 'csvLoanPercentage',
  percentage: 'percentage',
  rate: 'rate',
  bankLoanRate: 'bankLoanRate',
  loanInformation: 'loanInformation',
  loanAmount: 'amount',
  loanAmountSelection: 'loanAmount',
  youngestLife: 'youngestLife',
  assumeInterestDeductibility: 'assumeInterestDeductibility',
  collateralInsuranceDeduction: 'collateralInsuranceDeduction',
  scenarioName: 'scenarioName',
  advisorProfile: 'advisorProfile',
  termRiderDuration: 'termRiderDuration',
  termOtherRiderDuration: 'termOtherRiderDuration',
  spousalRiderDuration: 'spousalRiderDuration',
  isFolded: 'isFolded',
  isDepositOptionFolded: 'isDepositOptionFolded',
  isDepositFolded: 'isDepositFolded',
  isCoverageSummaryFolded: 'isCoverageSummaryFolded',
  isRiderSummaryFolded: 'isRiderSummaryFolded',
  isIllustrationRatesFolded: 'isIllustrationRatesFolded',
  isAdditionalCostsFolded: 'isAdditionalCostsFolded',
  isAdditionalDepositSelected: 'isAdditionalDepositSelected',
  isPremiumOffsetFolded: 'isPremiumOffsetFolded',
  depositOptionCustomAmount: 'depositOptionCustomAmount',
  isDepositOptionCustom: 'isDepositOptionCustom',
  includeMaxLevelDepositOption: 'includeMaxLevelDepositOption',
  depositOptionPaymentCache: 'depositOptionPaymentCache',
  minClientAge: 'minClientAge',

  myCase: { guid: 'guid', name: 'name', dateTime: 'dateTime', updatedAt: 'updatedAt' },
  performaxPlanType: 'performaxPlanType',
  rptCPD: 'coveragePaymentDetails',
  rptCD: 'coverageDetails',
  rptPD: 'policyDetails',
  rptPS: 'productSummary',
  rptRP: 'riderPremiums',
  rptTD: 'texDetails',
  rptUR: 'underwritingRequirements',
  rptVPGNB: 'vitalityPremiumsGoldAndBronze',
  rptVP: 'vitalityPoints',
  rptVPC: 'vitalityComparison',

  //withdrawal
  withdrawalTypeOption: 'type',
  withdrawalAmountOption: 'amount',
  withdrawalCustomAmounts: 'customAmount',
  basedonClient: 'durationPartyId',
  duration: 'durationType',
  durationFrom: 'durationStart',
  durationTo: 'durationEnd',
  agefrom: 'common.fromage',
  ageto: 'common.toage',
  yearfrom: 'common.fromyear',
  yearto: 'common.toyear',

  // MUL fields
  deathBenefitType: 'deathBenefitType',
  amountOfInsuranceType: 'amountOfInsuranceType',
  costOfInsurance: 'costOfInsurance',
  deathBenefit: 'deathBenefit',
  costOfInsuranceSwitchYear: 'costOfInsuranceSwitchYear',
  switchYear: 'switchYear',
  coverageVitalityPlus: 'coverageVitalityPlus',
  isVitalityPlusSelected: 'isVitalityPlusSelected',
  vitalityStatus: 'vitalityStatus',
  changeToBronze: 'changeToBronze',
  isChangeToBronze: 'isChangeToBronze',
  changeToBronzeAge: 'changeToBronzeAge',
  isDefaultChangeToBronzeAge: 'isDefaultChangeToBronzeAge',

  // PGold fields
  costDuration: 'costDuration',
  pcOption: 'performanceCreditOption',

  // Inforce Fields
  indicatorCode: {
    base: 'Base',
    termRider: 'TermRider',
    rider: 'Rider',
    termOtherRider: 'TermOtherRider',
    spousalRider: 'SpousalRider',
    enhancer: 'Enhancer',
  },
  policyNumber: 'policyNumber',
  businessType: 'businessType',
  toFaceAmount: 'toFaceAmount',
  topFaceAmount: 'topFaceAmount',
  bandSix: 'bandSix'
};

export const eLifecheque = {
  lcCoverageOption: 'lcCoverageOption',
  lcCoverageOptions: {
    lc10: 'LifechequeRenewable10',
    lc20: 'LifechequeRenewable20',
    lc65: 'LifechequePrimary65',
    lc75: 'LifechequeLevel75',
    lcPermanent: 'LifechequePermanent',
  },
  lcPremiumDuration: 'lcPremiumDuration',
  lcPremiumDurations: {
    lcPayFor15: 'Year15',
    lcPayToAge100: 'Age100',
    lcExpiry: 'Expiry', // Added to match data enumeration in Data library
  },
  lcReturnPremium: 'lcReturnPremiums',
  lcReturnPremiums: {
    ropd: 'Ropd',
    ropx: 'Ropx',
    rops: 'Rops',
  },
  lcChildLifeCheque: 'lcChildLifeCheque',
};

export const eCalcField = {
  esla: 'equivalentAge',
  premium: 'minimumModalPremium',
  covPremium: 'minimumModalPremium',
  covAmount: 'coverageAmount',
  policyFee: 'policyFee',
  initialPremium: 'initialModalPremium',
  vitalityFee: 'fee',
  covNextPremium: 'nextRenewalModalPremium',
  covNextDate: 'nextRenewalModalPremiumDate',
  covNextAge: 'nextRenewalModalPremiumAge',
  maximumAdditionalDepositAmt: 'maximumAdditionalDepositAmt',
  annualizedInitialModalPremium: 'annualizedInitialModalPremium',
};

export const ePrefix = {
  scenario: 'Scenario',
  coverage: 'Coverage',
  rider: 'Rider',
  client: 'Client',
  child: 'Child',
  payor: 'Payor',
  owner: 'Owner',
  advisor: 'Advisor',
  vitality: 'VitalityStatus',
  preparedFor: 'PreparedFor',
};

export const eCompanyType = {
  holdingCompany: 'Holding',
  operatingCompany: 'Operating',
};

export const eCSVLoanPercentage = {
  // There would be loan percentage max for multiple products
  default: 90,
  max: 90,
};

export const eBorrowingType = {
  corporate: 'Corporate',
  shareholder: 'Shareholder',
};

export const newIllustrationAutoSaved = 'New illustration [Auto-saved]';

export const urlDataToken = '{data}';

export const configs = {
  businessCardMaxCount: 200,
  pollingPeriod: 1000,
  pollingCounts: 20,
  endPoints: {
    validation: `${baseEndPoint}/${apiEndpoint}/${calcEndpoint}/validate`,
    summary: `${baseEndPoint}/${apiEndpoint}/${calcEndpoint}/summary`,
    baseReport: `${baseEndPoint}/${apiEndpoint}/${reportEndpoint}/base`,
    spreadsheet: `${baseEndPoint}/${apiEndpoint}/${reportEndpoint}/spreadsheet`,
    commissionsReport: `${baseEndPoint}/${apiEndpoint}/${reportEndpoint}/commissions`,
    underwritingReport: `${baseEndPoint}/${apiEndpoint}/${reportEndpoint}/underwriting`,
    conceptReport: `${baseEndPoint}/${apiEndpoint}/${reportEndpoint}/concept`,
    requestDocument: `${baseEndPoint}/${calcEndpoint}/docgenerate`,
    retrieveDocument: `${baseEndPoint}/${calcEndpoint}/docretrieve`,
    retrieveCookieInfo: `${baseEndPoint}/${apiEndpoint}/${authEndpoint}/cookieInfo`,
    authorize: `${baseEndPoint}/${apiEndpoint}/${authEndpoint}/login`,
    logout: `${baseEndPoint}/${apiEndpoint}/${authEndpoint}/logout`,
    heartbeat: `${baseEndPoint}/${apiEndpoint}/heartbeat`,
    getDdmIllustration: `${baseEndPoint}/${apiEndpoint}/${reportEndpoint}/cache/ddm/{data}`,
    healthyLiving: `${baseEndPoint}/${apiEndpoint}/${reportEndpoint}/resource`,
    persistence: {
      createShareCase: `${baseEndPoint}/${apiEndpoint}/${persistenceEndpoint}/cases/share`,
      createCase: `${baseEndPoint}/${apiEndpoint}/${persistenceEndpoint}/cases`,
      updateCase: `${baseEndPoint}/${apiEndpoint}/${persistenceEndpoint}/cases/{data}`,
      openCase: `${baseEndPoint}/${apiEndpoint}/${persistenceEndpoint}/cases/{data}`,
      getCases: `${baseEndPoint}/${apiEndpoint}/${persistenceEndpoint}/cases`,
      deleteCases: `${baseEndPoint}/${apiEndpoint}/${persistenceEndpoint}/cases/delete`,
      getProfiles: `${baseEndPoint}/${apiEndpoint}/${persistenceEndpoint}/profiles`,
      createProfile: `${baseEndPoint}/${apiEndpoint}/${persistenceEndpoint}/profiles`,
      updateProfile: `${baseEndPoint}/${apiEndpoint}/${persistenceEndpoint}/profiles/{data}`,
      deleteProfile: `${baseEndPoint}/${apiEndpoint}/${persistenceEndpoint}/profiles/delete`,
      saveDdmIllustration: `${baseEndPoint}/${apiEndpoint}/${persistenceEndpoint}/rdm`,
    },
    policyLookup: {
      authenticate: `${baseEndPoint}/${apiEndpoint}/${policyLookupAuthenticateEndpoint}`,
      retrievePolicy: `${baseEndPoint}/${apiEndpoint}/${policyLookupEndpoint}`,
      entitledPolicy: `${baseEndPoint}/${apiEndpoint}/${policyEntitledEndpoint}`,
    },
    inforceRequestForm: {
      create: `${baseEndPoint}/${apiEndpoint}/${inforceRequestFormEndpoint}/create`,
    },
    dda: {
      getApps: `${baseEndPoint}/${apiEndpoint}/${ddaEndpoint}/applications`,
      tcaGetApps: `${baseEndPoint}/${apiEndpoint}/${tcaEndpoint}/applications`,
      deleteApps: `${baseEndPoint}/${apiEndpoint}/${ddaEndpoint}/applications/delete`,
      tcaDeleteApps: `${baseEndPoint}/${apiEndpoint}/${tcaEndpoint}/applications/delete`,
      getDocuSignDoc: `${baseEndPoint}/${apiEndpoint}/${ddaEndpoint}/applications/getDocuSignDoc`,
      tcaGetDocuSignDoc: `${baseEndPoint}/${apiEndpoint}/${tcaEndpoint}/applications/getDocuSignDoc`,
    },
  },
  routes: {
    home: '/',
    customMode: '/custom-mode/scenarios',
    termRenewalCalc: '/term-renewal-calc',
    clientCoverage: '/custom-mode/scenarios/clientcoverage',
    spreadsheet: '/custom-mode/scenarios/spreadsheet',
    designReport: '/design-report',
    profile: '/profile',
    quickMode: '/quick-mode',
    myCases: '/dashboard/',
    login: '/callback',
    shareLink: '/share/:guid',
    ddaHome: '/dda',
    ddaImportIllustration: '/dda/importIllustration',
  },
  languages: [
    { label: eLang.en, value: eLang.en },
    { label: eLang.fr, value: eLang.fr },
  ],
  coverageOptions: [
    { value: eCoverageOption.t10, coverageTypes: [eCoverageType.single, eCoverageType.combined] },
    { value: eCoverageOption.t20, coverageTypes: [eCoverageType.single, eCoverageType.combined] },
    { value: eCoverageOption.t65, coverageTypes: [eCoverageType.single, eCoverageType.combined] },
    {
      value: eCoverageOption.tlife,
      coverageTypes: [eCoverageType.single, eCoverageType.jointFirst, eCoverageType.jointLast],
    },
  ],
  lcCoverageOptions: [
    { value: eLifecheque.lcCoverageOptions.lc10, coverageTypes: [eCoverageType.single] },
    { value: eLifecheque.lcCoverageOptions.lc20, coverageTypes: [eCoverageType.single] },
    { value: eLifecheque.lcCoverageOptions.lc65, coverageTypes: [eCoverageType.single] },
    { value: eLifecheque.lcCoverageOptions.lc75, coverageTypes: [eCoverageType.single] },
    { value: eLifecheque.lcCoverageOptions.lcPermanent, coverageTypes: [eCoverageType.single] },
  ],
  costReductions: [{ label: 'None', value: 'X' }],
  months: [
    { label: 'January', value: 'Jan' },
    { label: 'February', value: 'Feb' },
    { label: 'March', value: 'Mar' },
    { label: 'April', value: 'Apr' },
    { label: 'May', value: 'May' },
    { label: 'June', value: 'Jun' },
    { label: 'July', value: 'Jul' },
    { label: 'August', value: 'Aug' },
    { label: 'September', value: 'Sep' },
    { label: 'October', value: 'Oct' },
    { label: 'November', value: 'Nov' },
    { label: 'December', value: 'Dec' },
  ],
  province: [
    { label: 'Alberta', value: 'AB' },
    { label: 'British Columbia', value: 'BC' },
    { label: 'New Brunswick', value: 'NB' },
    { label: 'Newfoundland and Labrador', value: 'NL' },
    { label: 'Northwest Territories', value: 'NT' },
    { label: 'Nova Scotia', value: 'NS' },
    { label: 'Nunavut', value: 'NU' },
    { label: 'Manitoba', value: 'MB' },
    { label: 'Ontario', value: 'ON' },
    { label: 'Prince Edward Island', value: 'PE' },
    { label: 'Quebec', value: 'QC' },
    { label: 'Saskatchewan', value: 'SK' },
    { label: 'Yukon', value: 'YT' },
  ],
  solveFors: [
    { label: 'Premium', value: eSolveFor.premium },
    { label: 'Coverage', value: eSolveFor.coverage },
  ],
  riders: [
    {
      id: eRider.tdw,
      isAmount: false,
      isRating: true,
    },
    {
      id: eRider.bvp,
      isAmount: true,
      isRating: false,
    },
    {
      id: eRider.gio,
      isAmount: true,
      isRating: false,
    },
    {
      id: eRider.prw,
      isAmount: false,
      isRating: false,
    },
    {
      id: eRider.adnd,
      isAmount: true,
      isRating: true,
    },
    {
      id: eRider.adb,
      isAmount: true,
      isRating: true,
    },
    {
      id: eRider.cpr,
      isAmount: false,
      isRating: false,
    },
    {
      id: eRider.clc,
      isAmount: true,
      isRating: false,
    },
    {
      id: eRider.tdwpayor,
      isAmount: false,
      isRating: false,
    },
    {
      id: eRider.wpd,
      isAmount: false,
      isRating: true,
    },
    {
      id: eRider.ppr,
      isAmount: false,
      isRating: false,
    },
  ],
};

export const eDefault = {
  switchYear: 5,
  costOfInsuranceSwitchYear: false,
  deathBenefit: 100,
  amountOfInsuranceType: eInsuranceType.specified,
  costOfInsurance: eCOIType.levelToAge100,
  deathBenefitType: eDeathType.facePlus,
  coverageType: eCoverageType.single,
  coverageOption: eCoverageOption.t10,
  lcCoverageOption: eLifecheque.lcCoverageOptions.lc10,
  solveFor: eSolveFor.premium,
  premiumFrequency: ePremiumFrequency.monthly,
  coverageOptions: configs.coverageOptions,
  lcCoverageOptions: configs.lcCoverageOptions,
  specialQuote: eSpecialQuote.none,
  // it is string cuz used in Summary before being formatted
  coverageAmount: 100000,
  parCoverageAmount: 100000,
  mulCoverageAmount: 500000,
  termCoverageAmount: 500000,
  premiumAmount: 100.0,
  premiumDuration: ePremiumDuration.pay10,
  costDuration: eCostDuration.pay100,
  lcPremiumDuration: eLifecheque.lcPremiumDurations.lcPayToAge100,
  premiumOffset: ePremiumOffset.no,
  premiumOffsetYear: '11',
  premiumOffsetYearFrom: '8',
  premiumOffsetYearTo: '99',
  premiumOffsetAltYear: '11',
  maximumAdditionalDepositAmt: 0,
  loanBalance: 0,
  lastDepositYear: 0,
  grandfatheredStatus: 0,
  depositOwnerResidence: eDepositOwnerResidence.select,
  depositFrequency: eDepositFrequency.annual,
  depositType: eDepositType.specified,
  depositAmount: 0.0,
  depositDurationType: eDepositDurationType.age,
  depositAdditionalDeposit: 0.0,
  depositOptionType: eDepositOptionType.none,
  additionOptionType: eAdditionOptionType.specified,
  depositOptionAmount: 0.0,
  depositOptionDurationType: eDepositOptionDurationType.year,
  depositOptionDuration: 10,
  depositOptionDurationInforce: 1,
  additionOptionDurationType: eAdditionOptionDurationType.year,
  additionOptionDuration: 10,
  isCoverageSolve: false,
  amountForCoverage: 0.0,
  dividendOption: eDividendOption.pui,
  performanceCreditOption: ePerformanceCreditOption.pui,
  switchToCashDividends: false,
  switchToCashDividendsYear: 2,
  primaryDividendScale: eDividendScale.current,
  alternateDividendScale: eDividendScale.lessOne,
  marginalTaxRate: 50,
  policyInterestRate: 0,
  performanceCreditRate: 4.67,
  altPerformanceCreditRate: 3.67,
  sideAccountInterestRate: 0,
  permRatingAmount: 0.0,
  permRatingPercent: 100,
  tempRatingAmount: 0.0,
  tempRatingYear: 1,
  tempRatingYearPMax: 0,
  rider: {
    rating: 100,
    [`${eRider.bvp}Amount`]: 50000,
    [`${eRider.gio}Amount`]: 25000,
    [`${eRider.adnd}Amount`]: 10000,
    [`${eRider.adb}Amount`]: 10000,
    [`${eRider.term}Amount`]: 100000,
    [`${eRider.tor}Amount`]: 100000,
    [`${eRider.spr}Amount`]: 100000,
    [`${eRider.clc}Amount`]: 5000,
  },
  vitalityFromYear: 2,
  vitalityFromYearMULPAR: 1,
  healthstyle: eHS.hs3,
  smokingstatus: eSmokingStatus.nonsmoker,
  changeToBronzeAge: 65,
  clientAge: 45,
  childAge: 0,
  payorAge: 45,
  sex: eSex.male,
  udmDate: 'YYYY-MM-DD',
  advisorProfile: null,
  appDate: 'DD/MM/YYYY',
  casesDate: 'DD/MM/YYYY h:mm a (UTC)',
  appDateTime: 'MM-DD-YYYYTHH:mm:SS',
  personalDividendTaxRate: 35,
  taxInformation: {
    corporateTaxRate: 50,
  },
  alternateInvestment: {
    allocation: {
      interest: 100,
      dividend: 0,
      deferredCapitalGains: 0,
      realizedCapitalGains: 0,
    },
    rateOfReturns: {
      interestRate: 6,
      dividendRate: 6,
      deferredCapitalGainsRate: 6,
      realizedCapitalGainsRate: 6,
    },
  },
  lifeExpectancyAssumption: {
    // deathAtAge: 83,
    additionalYears: 5,
    // lifeExpectancy: 83
  },
  corporateIRPStructure: {
    borrowingType: eBorrowingType.corporate,
    investmentIncomeTax: 50,
    operatingIncomeTax: 40,
    companyType: eCompanyType.holdingCompany,
  },

  dividendScale: {
    type: eConceptDividendScale.primary,
  },

  [eField.withdrawalAmountOption]: 100,
  [eField.withdrawalAmountOptionPMAX]: 500,
  taxInformationRates: {
    presentValueDiscountRate: 3,
  },
  investmentAllocation: {
    [eInvestmentAllocation.interest]: {
      percentage: 100,
      rate: 6,
    },
    [eInvestmentAllocation.dividends]: {
      percentage: 0,
      rate: 6,
    },
    [eInvestmentAllocation.deferredCapitalGains]: {
      percentage: 0,
      rate: 6,
    },
    [eInvestmentAllocation.realizedCapitalGains]: {
      percentage: 0,
      rate: 6,
    },
  },

  scenarioTabNavs: {
    activeTabId: 'Scenario.1',
    isOutOfDate: false,
    tabNavs: [
      {
        id: 'Scenario.1',
      },
    ],
    topBars: {
      'Scenario.1': {
        activeTopBarIndex: 1,
        alternateDividendScale: 'CurrentLess100bp',
        concept: 'None',
        effectiveDate: '2018-10-15',
        illustrationId: 'E4GHT3S',
        isRiderOpened: false,
        marginalTaxRate: 50,
        policyInterestRate: 0,
        premiumOffset: 'None',
        primaryDividendScale: 'Current',
        product: 'Par',
        productValidationError: false,
        scenarioName: '',
        sideAccountInterestRate: 0,
      },
    },
    udmEnabled: false,
  },
};

export const eMaxVal = (product) => {
  const eMaxValPar = 9999999;
  const eMaxValNonPar = 999999;
  return {
    [eRider.bvp]: 99999999,
    [eRider.gio]: product === eProduct.PAR ? eMaxValPar : eMaxValNonPar,
    [eRider.adnd]: 9999999,
    [eRider.adb]: 9999999,
    [eRider.term]: 99999999,
    numTermRiders: 3,
    [ePremiumDuration.pay10]: { duration: 10 },
    [ePremiumDuration.pay20]: { duration: 20 },
    [ePremiumDuration.Pay90]: { duration: 90 },
    [ePremiumDuration.Pay100]: { duration: 100 },
  };
};

export const eDefaultPerson = {
  id: '',
  firstName: '',
  lastName: '',
  placeholder: '',
  sex: eDefault.sex,
  dob: '',
  errors: {},
};

export const eDefaultClient = {
  ...eDefaultPerson,
  fullName: '',
  age: eDefault.clientAge,
  healthstyle: eDefault.healthstyle,
  smokingstatus: eDefault.smokingstatus,
  coverageTabId: [],
  deceased: false,
};

export const eDefaultChild = {
  ...eDefaultPerson,
  age: eDefault.childAge,
};

export const eDefaultPayor = {
  ...eDefaultPerson,
  fullName: '',
  age: eDefault.payorAge,
  healthstyle: eDefault.healthstyle,
  smokingstatus: eDefault.smokingstatus,
};

export const eDefaultRatings = {
  permRatingAmount: eDefault.permRatingAmount,
  permRatingPercent: eDefault.permRatingPercent,
  tempRatingAmount: eDefault.tempRatingAmount,
  tempRatingYear: eDefault.tempRatingYear,
};
export const eDefaultRatingsPayor = {
  permRatingPercent: eDefault.permRatingPercent,
};

export const eDefaultCustomVitality = [{ vitalityStatus: eVitalityStatus.gold, fromYear: eDefault.vitalityFromYear }];
export const eDefaultCustomVitalityMULPAR = [
  { vitalityStatus: eVitalityStatus.gold, fromYear: eDefault.vitalityFromYearMULPAR },
];

export const eJointTypes = [eCoverageType.jointFirst, eCoverageType.jointLast];

export const eGoodRatings = [eHS.hs1, eHS.hs2];

export const eSpreadsheetFields = {
  year: 'PolicyYear',
  age: 'PrimaryAttainedAge',
  ageInforce: 'AttainedAgeInforce',
  yearAge: 'yearAge', // calculated field
  totalCoverage: 'FaceAmount',
  totalInsurancePrem: 'BasePremium',
  totalRiderPrem: 'RiderPremium',
  policyFee: 'ExpenseDeduction',
  vitalityFee: 'VitalityModalFee',
  totalPlanPrem: 'ModalScheduledPremium',
  scheduledModalPremium: 'ScheduledModalPremium',
  scheduledAnnualPremium: 'ScheduledAnnualPremium',
  totalTdwPrem: 'TdwDeduction',
  totalBvpPrem: 'BvpDeduction',
  totalCprPrem: 'CprDeduction',
  totalAdbPrem: 'AdbDeduction',
  totalGioPrem: 'GioDeduction',
  riderPremium: 'CoverageDeduction',
  totalAnnualPrem: 'ScheduledPremium',
  adjustedCostBasis: 'AdjustedCostBasis',
  netCostPure: 'NetCostOfPureInsurance',
  capitalDividend: 'CdaCredit',
  cashValueIncrease: 'CashValueIncrease',
  vitalityStatus: 'VitalityStatus',
  t10Coverage: 'TotalT10FaceAmount',
  t10ModalPremium: 'TotalT10ModalPremium',
  t20Coverage: 'TotalT20FaceAmount',
  t20ModalPremium: 'TotalT20ModalPremium',
  t65Coverage: 'TotalT65FaceAmount',
  t65ModalPremium: 'TotalT65ModalPremium',
  tLifeCoverage: 'TotalTermLifeFaceAmount',
  tLifeModalPremium: 'TotalTermLifeModalPremium',
  // PAR Spreadsheet field names
  tPlannedPremium: 'TotalPlannedPremium',
  gPremium: 'GuaranteedPremium',
  gDeathBenefit: 'TotalDeathBenefit',
  gInsuranceAmount: 'GuaranteedInsuranceAmount',
  gCashValue: 'TotalCashValue',
  gCashValuePar: 'GuaranteedCashValue',
  illustratedDepositPayment: 'IllustratedDepositOptionPayments',
  maxAnnualDepositPayment: 'MaximumAnnualDepositOptionLimit',
  annualDividend: 'TotalDividend',
  paidupInsurance: 'PaidUpInsurance',
  depositOptionInsurance: 'DepositOptionInsurance',
  dividendCash: 'DividendCash',
  irrDeathBenefit: 'IRRDeathBenefit',
  irrTotalDeathBenefit: 'IrrOnTotalDeathBenefit',
  irrTotalCashValue: 'IrrOnTotalCashValue',
  annualDividendAlt: 'AnnualDividendAlternate',
  dividendCashAlt: 'DividendCashAlternate',
  paidupInsuranceAlt: 'PaidUpInsuranceAlternate',
  depositOptionInsuranceAlt: 'DepositOptionInsuranceAlternate',
  irrDeathBenefitAlt: 'IRRDeathBenefitAlternate',
  irrTotalCashValueAlt: 'IRRTotalCashValueAlternate',
  adjustedCostBasisAlt: 'AdjustedCostBasisAlternate',
  netCostPureAlt: 'NetCostOfPureInsuranceAlternate',
  capitalDividendAlt: 'CdaCreditAlternate',
  termRiderInsurance: 'TermRiderInsurance',
  tdwRiderPremium: 'TDWRiderPremium',
  gioRiderPremium: 'GIORiderPremium',
  payorWaverRider: 'PayorWaverRider',
  childProtectionRider: 'ChildProtectionRider',
  requestedBeforeCash: 'RequestedBeforeCash',
  beforeTaxWithdrawal: 'BeforeTaxWithdrawalAmount',
  withdrawal: 'Withdrawal',
  taxableWithdrawal: 'TaxablePortionOfWithdrawal',
  taxOnWithdrawal: 'TaxableGainOnWithdrawal',
  netWithdrawal: 'NetWithdrawal',
  taxableCancellationValue: 'TaxablePortionOfCancellationCashValue',
  taxableCashDividend: 'TaxableAnnualDividend',
  taxOnCancellationValue: 'TaxPayableOnCancellationCashValue',
  cancellationCashValue: 'CancellationCashValueAfterTax',
  taxableCancellationValueAlt: 'TaxableCancellationValueAlternate',
  taxableCashDividendAlt: 'TaxableCashDividendAlternate',
  taxOnCancellationValueAlt: 'TaxOnCancellationValueAlternate',
  cancellationCashValueAlt: 'CancellationCashValueAlternate',
  marginalTaxRate: 'MarginalTaxRate',
  refund: 'Refund',
  // Mul Spreadsheet field names
  requestedAnnualDeposit: 'RequestedAnnualDeposit',
  grossAccountValue: 'GrossAccountValue',
  totalDeathBenefit: 'TotalDeathBenefit',
  sideAccountBalance: 'SideAccountBalance',
  faceAmount: 'FaceAmount',
  permanentInsuranceCost: 'PermanentInsuranceCost',
  depositLoad: 'DepositLoad',
  totalAnnualPolicyCost: 'TotalAnnualPolicyCost',
  taxGainOnPolicyCashValue: 'TaxGainOnPolicyCashValue',
  policyInterestRate: 'PolicyInterestRate',
  depositToSideAccount: 'DepositToSideAccount',
  transferToSideAccount: 'TransferToSideAccount',
  transferFromSideAccount: 'TransferFromSideAccount',
  sideAccountInterest: 'SideAccountInterest',
  taxOnSideAccount: 'TaxOnSideAccount',
  sideAccountInterestRate: 'SideAccountInterestRate',
  totalNetWithdrawal: 'TotalNetWithdrawal',
  faceAmountAdjustedWithdrawal: 'FaceAmountAdjustedWithdrawal',
  netPayment: 'NetPayment',
  puiCashValue: 'PuiCashValue',
  paidPremium: 'PaidPremium',
  sideAccountRefund: 'SideAccountRefund',
  taxableGainOnSideAccountRefund: 'TaxableGainOnSideAccountRefund',
  totalGrossWithdrawalTaxGain: 'TotalGrossWithdrawalTaxGain',
  totalAnnualPayment: 'TotalAnnualPayment',
  accumulationAccountValue: 'AccumulationAccountValue',
  premiumsPaidByPolicyValues: 'PremiumsPaidByPolicyValues',
  doiCashValue: 'DoiCashValue',
  deathBenefitAfterPremiumOffset: 'DeathBenefitAfterPremiumOffset',
  cashValueAfterPremiumOffset: 'CashValueAfterPremiumOffset',
  tirDeduction: 'TirDeduction',
  otherRiderDeduction: 'OtherRiderDeduction',
  performanceCreditOptionChange: 'PerformanceCreditOptionChange',
};

const spFields = eSpreadsheetFields;

export const eSpreadsheetModals = [
  spFields.totalInsurancePrem,
  spFields.totalRiderPrem,
  spFields.totalPlanPrem,
  spFields.totalTdwPrem,
  spFields.totalBvpPrem,
  spFields.totalCprPrem,
  spFields.totalAdbPrem,
  spFields.totalGioPrem,
  spFields.t10ModalPremium,
  spFields.t20ModalPremium,
  spFields.t65ModalPremium,
  spFields.tLifeModalPremium,
  spFields.gPremium,
];

export const eSpreadsheetDecimal = [
  spFields.adjustedCostBasis,
  spFields.adjustedCostBasisAlt,
  spFields.annualDividend,
  spFields.annualDividendAlt,
  spFields.capitalDividend,
  spFields.capitalDividendAlt,
  spFields.cashValueIncrease,
  spFields.riderPremium,
  spFields.totalInsurancePrem,
  spFields.totalRiderPrem,
  spFields.totalPlanPrem,
  spFields.totalTdwPrem,
  spFields.totalBvpPrem,
  spFields.totalCprPrem,
  spFields.totalAdbPrem,
  spFields.totalGioPrem,
  spFields.totalAnnualPrem,
  spFields.policyFee,
  spFields.vitalityFee,
  spFields.netCostPure,
  spFields.netCostPureAlt,
  spFields.t10ModalPremium,
  spFields.t20ModalPremium,
  spFields.t65ModalPremium,
  spFields.tLifeModalPremium,
  spFields.illustratedDepositPayment,
  spFields.maxAnnualDepositPayment,
  spFields.cancellationCashValue,
  spFields.cancellationCashValueAlt,
  spFields.gCashValue,
  spFields.gCashValuePar,
  spFields.scheduledModalPremium,
  spFields.scheduledAnnualPremium,
  spFields.taxOnCancellationValue,
  spFields.taxOnCancellationValueAlt,
  spFields.taxableCancellationValue,
  spFields.taxableCancellationValueAlt,
  spFields.taxableCashDividend,
  spFields.taxableCashDividendAlt,
  spFields.beforeTaxWithdrawal,
  spFields.withdrawal,
  spFields.taxableWithdrawal,
  spFields.taxOnWithdrawal,
  spFields.netWithdrawal,
  spFields.marginalTaxRate,
  spFields.irrTotalDeathBenefit,
  spFields.irrTotalCashValue,
  spFields.requestedAnnualDeposit,
  spFields.grossAccountValue,
  spFields.totalDeathBenefit,
  spFields.sideAccountBalance,
  spFields.permanentInsuranceCost,
  spFields.depositLoad,
  spFields.totalAnnualPolicyCost,
  spFields.taxGainOnPolicyCashValue,
  spFields.depositToSideAccount,
  spFields.transferToSideAccount,
  spFields.transferFromSideAccount,
  spFields.sideAccountInterest,
  spFields.taxOnSideAccount,
  spFields.policyInterestRate,
  spFields.sideAccountInterestRate,
  spFields.totalNetWithdrawal,
  spFields.faceAmountAdjustedWithdrawal,

  spFields.netPayment,
  spFields.puiCashValue,
  spFields.paidPremium,
  spFields.sideAccountRefund,
  spFields.taxableGainOnSideAccountRefund,
  spFields.totalGrossWithdrawalTaxGain,
  spFields.totalAnnualPayment,
  spFields.accumulationAccountValue,
  spFields.premiumsPaidByPolicyValues,
  spFields.doiCashValue,
  spFields.deathBenefitAfterPremiumOffset,
  spFields.cashValueAfterPremiumOffset,
  spFields.tirDeduction,
  spFields.otherRiderDeduction,
  spFields.performanceCreditOptionChange,
];

export const eSpreadsheetPercent = [
  spFields.marginalTaxRate,
  spFields.irrTotalDeathBenefit,
  spFields.irrTotalCashValue,
  spFields.policyInterestRate,
  spFields.sideAccountInterestRate,
  spFields.performanceCreditOptionChange,
];

//Concept
export const eConcept = {
  CIRP: 'CIRP',
  CEB: 'CEB',
  PIRP: 'PIRP',
  PEB: 'PEB',
  NONE: 'None',
};

export const eAlternateInvestment = {
  setGrowth: {
    interest: 10,
    dividends: 10,
    deferredCapitalGains: 40,
    realizedCapitalGains: 40,
  },
  setIncome: {
    interest: 40,
    dividends: 40,
    deferredCapitalGains: 10,
    realizedCapitalGains: 10,
  },
  setBalanced: {
    interest: 30,
    dividends: 20,
    deferredCapitalGains: 20,
    realizedCapitalGains: 30,
  },
};

//Header
export const routesHeader = {
  home: [],
  customMode: [],
  clientCoverage: [
    'saveas',
    'casename',
    'spreadsheet',
    'shareillustration',
    'viewreportdropdown',
    'inforcerequestform',
    'needhelp',
  ],
  spreadsheet: ['customizereport', 'viewreport'],
  designReport: ['report.back', 'viewreport'],
  profile: [],
  quickMode: [],
  myCases: ['back.green'],
};

//Concept
export const conceptsConfig = {
  [eConcept.CEB]: ['TaxInformation', 'ConceptDividendScales', 'AlternateInvestment', 'LifeExpectancyAssumption'],
  [eConcept.CIRP]: [
    'CorporateIRPStructure',
    'AlternateInvestment',
    'LifeExpectancyAssumption',
    'TaxInformationRates',
    'ConceptDividendScales',
    'LoanInformation',
  ],
  [eConcept.PEB]: ['TaxInformation', 'ConceptDividendScales', 'LifeExpectancyAssumption', 'AlternateInvestment'],
  [eConcept.PIRP]: [
    'TaxInformationRates',
    'AlternateInvestment',
    'ConceptDividendScales',
    'LoanInformation',
    'LifeExpectancyAssumption',
  ],
};

export const eLoanAmountSelection = {
  specified: 'Specified',
  solveMaximum: 'MaximumSolve',
};

export const eDurationType = {
  age: 'Age',
  year: 'Year',
};

export const eBankLoanRate = {
  min: 5,
  max: 100,
};

export const eLoanAmount = {
  min: 1,
  max: 50000000,
  def: 1,
};

export const eRatingTemplate = {
  payor: 'payorTemplate',
  default: '',
};

export const eJuvenileAgeLimit = 16;

export const maxPrformanceCreditRateSubstractor = 0.01;

export const ePolicyInterestRate = {
  min: 0,
  max: 8,
};

export const eSideAccountInterestRate = {
  min: 0,
  max: 5,
};

export const eMarginalTaxRate = {
  min: 0,
  max: 100,
};

export const eAppStatus = {
  ALL: 'All',
  STARTED: 'STARTED',
  CLEARED: 'CLEARED',
  SUBMITTED: 'SUBMITTED',
  AWAIT_CLIENT_ESIGN: 'AWAIT_CLIENT_ESIGN',
  AWAIT_ADVISOR_ESIGN: 'AWAIT_ADVISOR_ESIGN',
  AWAIT_SUBMIT: 'AWAIT_SUBMIT',
  DECLINED_BY_CLIENT: 'DECLINED_BY_CLIENT',
  EXPIRED: 'EXPIRED',
  ESIGN_LINK_EXPIRED: 'ESIGN_LINK_EXPIRED',
  LOCKED: 'LOCKED',
};

// Must be in date(2)/month(2)/year(4) format
const termRatechangeDate = '20/11/2021';
const mulRateChangeDate = '24/09/2022';
export const rateChangeProducts = {
  FamilyTerm: termRatechangeDate,
  FamilyTermVitality: termRatechangeDate,
  BusinessTerm: termRatechangeDate,
  Mul: mulRateChangeDate,
  // PAR: '', For future use add date
};

export const appLaunchDatePay100 = {
  Par: '27/10/2023',
  ParVitality: '27/10/2023',
};

export const appLaunchDate = {
  Par: '01/09/2019',
  ParVitality: '28/04/2023',
  FamilyTerm: '15/01/2020',
  BusinessTerm: '15/01/2020',
  FamilyTermVitality: '12/11/2022',
  Mul: '20/11/2021',
  MulVitality: '12/11/2022',
  Lifecheque: '02/03/2023',
};

export const healthStyleEffectiveDate = {
  FamilyTerm: '01/05/1998',
  BusinessTerm: '01/05/1998',
  Mul: '19/11/2021',
};

export const getConvertStatus = (productList, intl) => {
  const hasBT = productList.includes(eProduct.BT);
  const hasFTV = productList.includes(eProduct.FTV);
  return `${intl.formatMessage({ id: 'mycases.dialog.status.updatedToFT' })}${
    hasBT && hasFTV ? intl.formatMessage({ id: 'mycases.dialog.status.andFT' }) : ''
  }${hasFTV ? intl.formatMessage({ id: 'mycases.dialog.status.withFTV' }) : ''}.`;
};

export const PerformaxPlanTypeKeys = {
  Performer: 'Performer',
  PerformaxI: 'PerformaxI',
  PerformaxII: 'PerformaxII',
  Performax88: 'Performax88',
  Performax90: 'Performax90',
  Performax92: 'Performax92',
  Performax93: 'Performax93',
  Performax94: 'Performax94',
  Performax2003: 'Performax2003',
  SecurityBuilder: 'SecurityBuilder',
};

export const eGrandfatheredStatusType = {
  none: 'None',
};

export const policyChangeRequestType = {
  dividendOptionChange: 'DividendOptionChange',
  faceAmountDecrease: 'FaceAmountDecrease',
  faceAmountIncrease: 'FaceAmountIncrease',
};

export const withdrawalMaxPolicyEndYear = {
  [eProduct.PAR]: 100,
  [eProduct.Performax]: 99,
  [eProduct.PG]: 100,
  [eProduct.SB]: 99,
};

export const calcDelayNotificationTime = {
  timeout: 30000,
  interval: 20000,
};

export const inforceParChangeToBronze = {
  defaultAddition: 20,
  minAddtion: 1
};
