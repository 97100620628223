import React, { PureComponent } from 'react';
import { eField, eActionEvent, eProduct, changeToBronzeClientAgeDifference, inforceParChangeToBronze } from '../../configs';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as AppActions from '../../../core/actions';
import * as ScenarioActions from '../../../custom-mode/scenario-tab-navs/actions';
import NumberFormat from 'react-number-format';
import {
  calculateMWINumberFieldValue,
  trackUserActions,
  getProdCoverageOption,
  setChangeToBronzeYes,
  setCoverageSolveToYes,
  setIncludeDepositOptionToYes,
  getPolicyYear,
} from '../../utils';
import moment from 'moment';

export class VitalityBronzeAfterAge extends PureComponent {
  static propTypes = {
    coverage: PropTypes.object,
    isChangeToBronze: PropTypes.bool,
    isChangeToBronzeDisable: PropTypes.bool,
    sceActions: PropTypes.object,
    appActions: PropTypes.object,
    activeScenarioTabId: PropTypes.string,
    activeCoverageTabId: PropTypes.string,
    product: PropTypes.string,
    clients: PropTypes.object,
    locale: PropTypes.string,
    changeToBronzeAge: PropTypes.number,
    clientAge: PropTypes.number,
    activeVitalityStatus: PropTypes.string,
    coverageOption: PropTypes.string.isRequired,
    activeScenario: PropTypes.object,
    inforce: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    if (props.inforce && props.coverage.isDefaultChangeToBronzeAge) {
      this.loadInforceDefault();
    }
  }

  loadInforceDefault() {
    if (this.props.product === eProduct.PAR) {
      this.props.coverage.changeToBronzeAge = this.props.clientAge + getPolicyYear(moment(this.props.coverage.effectiveDate)) + inforceParChangeToBronze.defaultAddition;
    }
  }

  dispatchAction(field, value, target, refresh = true) {
    this.props.appActions['dispatchMWIOnChange']({
      scenarioTabId: this.props.activeScenarioTabId,
      coverageTabId: this.props.activeCoverageTabId,
      product: this.props.product,
      premiumDuration: this.props.coverage.premiumDuration,
      isCoverageSolve: this.props.coverage.isCoverageSolve,
      clients: this.props.clients,
      target,
      field,
      value,
    });
    if (refresh) {
      this.props.sceActions.performManualRefresh();
      this.props.sceActions.toggleOutOfDate(true);
    }
  }

  onChange(type, value, target = 'coverage') {
    const { product, coverage, activeVitalityStatus, activeScenario } = this.props;
    trackUserActions(eActionEvent.selection, 'changeToBronze', value, '', {
      product,
      coverageType: coverage.coverageType,
      coverageOption: getProdCoverageOption(product, coverage),
      vitality: 'Yes',
      vitalityStatus: activeVitalityStatus,
      changeToBronze: setChangeToBronzeYes(product, value, true, activeVitalityStatus),
      coverageSolve: setCoverageSolveToYes(coverage),
      includeDepositOption: setIncludeDepositOptionToYes(activeScenario, coverage),
    });
    this.dispatchAction(type, value, target);
    this.props.sceActions.toggleOutOfDate(true);
  }

  onBlur(type, value) {
    const {
      clientAge,
      coverage: { isDefaultChangeToBronzeAge },
      product,
      inforce
    } = this.props;
    const minAgeIncrease =
      product === eProduct.PAR ? changeToBronzeClientAgeDifference.PAR : changeToBronzeClientAgeDifference.MUL;
    let minAge = +clientAge + minAgeIncrease;
    if (inforce && product === eProduct.PAR) {
      minAge = clientAge + getPolicyYear(moment(this.props.coverage.effectiveDate)) + inforceParChangeToBronze.minAddtion;
    }
    const maxAge = 100;
    if (minAge > maxAge) {
      minAge = maxAge;
    }
    if (type === eField.changeToBronzeAge) {
      value = calculateMWINumberFieldValue(value, minAge, maxAge);
    }
    this.dispatchAction(type, value, 'coverage');
    if (isDefaultChangeToBronzeAge) {
      this.dispatchAction(eField.isDefaultChangeToBronzeAge, false, 'coverage', false);
    }
  }

  render() {
    const {
      coverage: { isChangeToBronze, changeToBronzeAge },
    } = this.props;

    return (
      <React.Fragment>
        <div className="mwi-margin-top20 space-right11" role="vitality-bronze-after-age">
          <input
            className="rider-checkbox focusOutline"
            type="checkbox"
            id={eField.changeToBronze}
            value={isChangeToBronze}
            onChange={() => {
              this.onChange(eField.isChangeToBronze, !isChangeToBronze);
            }}
            checked={isChangeToBronze}
            name={'changeToBronze'}
            aria-label={'changeToBronzeCheckbox'}
            aria-describedby="changeToBronze"
          />
          <span className="mwi-padding-left6">
            <FormattedMessage id="coverage.changeToBronze" />
          </span>
        </div>
        {isChangeToBronze && (
          <div className="mwi-margin-top24">
            <NumberFormat
              className={`mwi-widget mwi-input number-data mwi-w40`}
              value={changeToBronzeAge}
              disabled={false}
              decimalSeparator="false"
              onBlur={(e) => this.onBlur(eField.changeToBronzeAge, e.target.value)}
              decimalScale={0}
              fixedDecimalScale={false}
              id={eField.changeToBronzeAge}
              autoComplete="off"
              aria-required="true"
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = ({ app, scenarioTabNavs, coverageTabNavs, clients, vitalityStatus }) => {
  const scenarioTabId = scenarioTabNavs.activeTabId;
  const coverageState = coverageTabNavs[scenarioTabId];
  const coverage = coverageState[coverageState.activeTabId];
  const activeScenario = scenarioTabNavs.topBars[scenarioTabId];
  const product = activeScenario.product;
  const clientAge = clients[scenarioTabId][clients[scenarioTabId].allClients[0]].age;
  const activeVitalityStatus = vitalityStatus[vitalityStatus.activeTabId][0].vitalityStatus;
  return {
    activeScenarioTabId: scenarioTabId,
    activeCoverageTabId: coverageState.activeTabId,
    clients: clients[scenarioTabId],
    activeVitalityStatus,
    coverage,
    product,
    clientAge,
    activeScenario,
    inforce: app.inforcePolicy
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(VitalityBronzeAfterAge));
